import React, {useState, useRef, useEffect} from "react"
import { Helmet } from 'react-helmet'
//import useDelayRouteExit from "delay-react-route-exit";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import SEO from "../components/seo"
import TopSection from "../components/topsection"
import LogoSlider from "../components/logoslider"
import BigList from "../components/biglist"
import DraggableSlider from "../components/dragslider"
import Shapes from "../components/shapes"
import Border from "../components/borderitem"

import NumbersList from "../components/numberslist"
import CTAwithImages from "../components/ctawithimages"
import "../components/styles/aboutus.scss"
import NoMatchPage from '../pages/404';


import axios from 'axios'
import md5 from 'md5'

//import ScrollingColorBackground from "react-scrolling-color-background"

import VisibilitySensor from 'react-visibility-sensor';


export default function AbouttUs(props) {
  if(props.props){
    props = props.props; //internal route form single news component
  }
  const path = props.location.pathname;
  let api_url = '/wp-json/wp/v2/pages/';
  const [axiosArr, setAxiosArr] = useState([]);
  const [newToken, setNewToken] = useState(false);
  const [data, setdata] = useState([]);
  const [seo, setseo] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState('');
  
  useEffect(() => {
    if(path.indexOf('_preview') > -1){
      let password = prompt('Password');
      const pageID = path.substr(path.lastIndexOf('/') + 1);
      api_url += pageID+'/revisions/?per_page=1&offset=0';
      if(password){
        axios.all([axios.post('/wp-json/madeo/v1/token',{sec_key:md5(password)})])
          .then(res => {
            let token_res = res[0].data;
            if(token_res.token != undefined){
              setAxiosArr([
                axios.get(api_url,{headers: {authorization: 'Bearer '+token_res.token}})
              ]);
              setNewToken(true);
            }else{
              setNewToken(false);
              setLoad(true);
              setError('404');
            }

          })
          .catch(err => {
              setError(err.message);
              setLoad(false)
          })
    }else{
      setNewToken(false);
      setLoad(true);
      setError('404');
    }


    }else{
      api_url += '?slug='+path.substring(1);
      setAxiosArr([
        axios.get(api_url)
      ]);
      setNewToken(true);
    }

    }, [path]);


  const [beigeBg, SetBeigeBg] = useState(false)
  const [saumonBg, SetSaumonBg] = useState(false)

  function onChangeBeige(isVisible) {
    isVisible ? SetBeigeBg(true) : SetBeigeBg(false);
  }

  function onChangeSaumon(isVisible) {
    isVisible ? SetSaumonBg(true) : SetSaumonBg(false);
  }
  


  useEffect(() => {
    if(newToken){
      axios.all(axiosArr)
        .then(res => {
            setdata(res[0].data[0].acf);
            setseo(res[0].data[0]);
            setLoad(true);
        })
        .catch(err => {
            setError(err.message);
            setLoad(true)
        })

    }
  }, [newToken, axiosArr]);


  if (error) {
    return (
      <Route component={NoMatchPage} />
    )

  }

  let top_title, background_color, page_data, patterns, draggable_slider, logos_slider, big_list, numbers, cta_with_images, shapes;

  if (load) {
    top_title = data.title;
    background_color = data.background_color;
    patterns = data.page_content;

    let seo_data = seo.yoast_meta;
    let featured_image = seo.featured_image;

    // console.log(patterns)
    if(patterns){
    { patterns.map(item => {

      switch (item.acf_fc_layout) {
        case 'draggable_slider':
          draggable_slider = item.draggable_slider_pattern
          break;

        case 'logos_slider':
          logos_slider = item;
          break;

        case 'big_list':
          big_list = item;
          break;

        case 'numbers':
          numbers = item;
          break;
        case 'cta_with_images':
          cta_with_images = item;
          break;
        case 'shapes_two_rows':
          shapes = item;
          break;
      }

    })}
    }


    function isPreRendering() {
      return navigator.userAgent === 'ReactSnap';
    };

    let preRender = isPreRendering();


    return(
      <>
        { seo_data &&
              <SEO data={seo_data} image={featured_image} />
        }
        <Helmet
              bodyAttributes={{
                  class: `page-about ${background_color} fixed-header`
              }}

        />

        { /* background-beige */}

        { !preRender && 
        <>
          {top_title &&
            <TopSection title={top_title} backgroundClass={background_color} />
          }

          { draggable_slider &&
              <DraggableSlider data={draggable_slider} backgroundClass="background-beige" />
          }

          {logos_slider &&
                <LogoSlider data={logos_slider} backgroundClass="background-beige" haveTitle={true} two_rows={logos_slider.two_rows_slider} />
          }



          { big_list &&
            <BigList data={big_list} backgroundClass="background-saumon" />
          }

          { numbers &&
            <NumbersList data={numbers} backgroundClass="background-yellow" />
          }

          { cta_with_images &&
            <CTAwithImages data={cta_with_images} backgroundClass="background-yellow" />
          }

          { shapes &&
            <Shapes backgroundClass="" data={shapes} rows="2" />
          }
        </>
        }
      </>
    )
  } else {
    return (
      <div style={{height: '100vh'}}></div>
    )
  }

}


/*{big_list &&
          <Border background="background-saumon" borderBackground="background-orange"/>
        }*/
