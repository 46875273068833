import React, {Fragment, useState, useEffect, useRef} from "react";
import Slider from "react-slick";
import './styles/shapes.scss'
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

//import ReactPlayer from 'react-player'
import ReactPlayer from 'react-player'




function ShapeItemVideo(shape, video){
  const [on, setHover] = useState(true);
  const [ready, setReady] = useState(false);
  const [count, setCount] = useState(0);
  function handleHover(){
    if(!isMobile){
      if(ready){
        setHover(true);
      }
      setCount(count + 1);
    }
  }
  function handleLeave(){
    if(!isMobile){
      setHover(false);
    }
  }
  function handleReady(){
    setReady(true);
    setHover(false)

  }

  return(
    <div className="col-4 shape-item" onMouseMove={handleHover} onMouseLeave={handleLeave} style={{overflow: 'hidden', paddingLeft: "0px", paddingRight: "0px"}}>
      <div className={`poster-img ${count > 0 ? 'hide' : ''} `} style={{ backgroundImage: `url(${shape.shape})`}} />
      <div className={`poster-img__ `} style={{ backgroundImage: `url(${shape.shape})`}} />
      <div className="player-wrapper">
        <ReactPlayer className='react-player'
          width='100%'
          height='100%'
          url={shape.video} playing={on} muted onReady={handleReady} controls={false}
          config={{
              wistea: {
                playerId: 'https://fast.wistia.com/embed/medias/g9tdlp0rre.jsonp'
              },
          }}
            />
      </div>
    </div>
  )
}

export default function Shapes(shapesData) {

  // console.log(shapesData.data.shapes)

  function isPreRendering() {
    return navigator.userAgent === 'ReactSnap';
  };

    let imagesArr_ = [];
    let videosArr_ = [];
    let backgroundClass;
    let data;

    if (shapesData && !isPreRendering()) {
      data = shapesData.data.shapes;
    }

    if (data) {
      return (

        <section className={`shapes-section background-orange ${backgroundClass ? backgroundClass : ""}`} >

          <div className="container-fluid" style={{paddingLeft: "0px", paddingRight: "0px"}}>
            <div className="row" style={{marginLeft: "0px", marginRight: "0px"}}>

                {data.map((item, i) => {
                    return (
                        <ShapeItemVideo  key={i} shape={item.shape_image.url} video={item.wistia_video} />
                    )
                })}

            </div>
          </div>
        </section>
      );
    } else {
      return (
        <Fragment />
      )
    }


}
