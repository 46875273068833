import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Rimp from 'rimp'
import { isMobile } from "react-device-detect";
import axios from 'axios';
import "./styles/footer.scss";

const Footer = () => {
  const [socialLinksTitle, setsocialLinksTitle] = useState([]);
  const [instagramCaption, setinstagramCaption] = useState([]);
  const [facebook, setfacebook] = useState([]);
  const [twitter, settwitter] = useState([]);
  const [instagram, setinstagram] = useState([]);
  const [linkedin, setlinkedin] = useState([]);

  const [addressTitle, setaddressTitle] = useState([]);
  const [address, setaddress] = useState([]);
  const [googleMapUrl, setgoogleMapUrl] = useState([]);
  const [viewOnMap, setviewOnMap] = useState([]);

  const [contactHeader, setcontactHeader] = useState([]);
  const [email, setemail] = useState([]);

  const [load, setLoad] = useState(false);
  const [error, setError] = useState('');

  function handleMove(e) {
    if (!isMobile) {
      const cursor = document.querySelector('.cursor');
      /*console.log(e.target)*/
      if (e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'button' || e.target.tagName.toLowerCase() === 'input') {       cursor.classList.add("large-cirlce")
      }else{
        cursor.classList.remove("large-cirlce")
      }
    }
  }
  function handleLeave(e) {
    if (!isMobile) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce")
    }
  }

  useEffect(() => {
    axios.all([
      axios.get('/wp-json/acf/v2/options/')
    ])
        .then(res => {
            setsocialLinksTitle(res[0].data.acf.social_links_title);
            setinstagramCaption(res[0].data.acf.visit_instagram_caption);
            setfacebook(res[0].data.acf.facebook);
            settwitter(res[0].data.acf.twitter);
            setinstagram(res[0].data.acf.instagram);
            setlinkedin(res[0].data.acf.linkedin);

            setaddressTitle(res[0].data.acf.address_title);
            setaddress(res[0].data.acf.address);
            setviewOnMap(res[0].data.acf.view_on_map_caption);
            setgoogleMapUrl(res[0].data.acf.google_map_url);

            setcontactHeader(res[0].data.acf.contact_form_header);
            setemail(res[0].data.acf.email);

            setLoad(true);
        })
        .catch(err => {
            setError(err.message);
            setLoad(true)
            //console.log(err.message);
        })


}, []);


function isPreRendering() {
  return navigator.userAgent === 'ReactSnap';
};

  if (load && !isPreRendering()) {
    let address_info, contact_header;

    if (contactHeader) {
      JSON.stringify(contactHeader)
      contact_header = contactHeader.replace("<p>", "");
      contact_header = contact_header.replace("</p>", "");
      contact_header = '<h4 class="medium-title">'+contact_header+'</h4>';
    }

    if(address != null){
          address_info = address + '<br><a href="'+ googleMapUrl +'" target="_blank">'+viewOnMap+'</a>';
        }
      return (
        <footer className={`main-footer background-orange`}
                onMouseEnter={handleMove}
                onMouseMove={handleMove}
                onMouseLeave={handleLeave}>
            <Container>
                <Row className="align-items-start">
                  { instagramCaption != '' &&
                    <Col xs="12">
                        <div className="footer-insta">
                            <a href={instagram} target="_blank">{instagramCaption}</a>
                        </div>
                    </Col>
                  }
                  <Col xs="12" sm="12" md="5" className="col-subscription">
                    { contactHeader != '' &&
                      <Row className="align-items-start">
                        <Col xs="12" sm="9" md="12" dangerouslySetInnerHTML={{ __html: contact_header }}>
                        </Col>
                      </Row>
                    }

                    <Row className="align-items-start">
                        <Col xs="12" sm="7" md="12">
                            <div className="form-holder">
                                <Rimp
                                  buttonValue={`submit`}
                                  buttonStyles={`btn`}
                                  containerStyles={`subscription`}
                                  placeholder={`Email Address`}
                                  completeMessage={`Thank you for subscribing.`}
                                  helpText={''}
                                  showError= {true}
                                  mailChimpUrl="https://madeostudio.us5.list-manage.com/subscribe/post?u=3797aae678e3a4ce8764b6fc3&id=a08ae3a5f5"
                                />
                            </div>
                        </Col>
                    </Row>



                  </Col>

                  <Col xs="6" md="3" className="offset-md-1 col-location">
                    { addressTitle != '' &&
                      <h4 className="small-title">{addressTitle}</h4>
                    }
                    { address != '' &&
                      <p dangerouslySetInnerHTML={{ __html: address_info }}></p>
                    }
                  </Col>
                  <Col xs="6" md="3" className="col-social">
                    {socialLinksTitle != '' &&
                      <h4 className="small-title"> {socialLinksTitle} </h4>
                    }

                    <nav className="footer-social">
                      <ul>
                        { twitter != '' &&
                          <li><a href={ twitter } target="_blank" className="social-link twitter">Twitter</a></li>
                        }
                        { facebook != '' &&
                          <li><a href={ facebook } target="_blank" className="social-link facebook">Facebook</a></li>
                        }
                        { instagram != '' &&
                          <li><a href={ instagram } target="_blank" className="social-link instagram">Instagram</a></li>
                        }
                        { linkedin != '' &&
                          <li><a href={ linkedin } target="_blank" className="social-link linkedin">LinkedIn</a></li>
                        }
                      </ul>
                    </nav>
                  </Col>
                </Row>
            </Container>
        </footer>
      );
    } else {
      return (
        <div></div>
      )
    }
};

export default Footer
