import React from "react"
import { Helmet } from 'react-helmet'
import useDelayRouteExit from "delay-react-route-exit";

import TopSection from "../components/static/topsection"
import WorkNews from "../components/static/worknews"
import SidedCTA from "../components/static/sidedcta"
import BigList from "../components/static/biglist"
import NumbersList from "../components/static/numberslist"
import ContactInfo from "../components/static/contactinfo"
import LargeSlider from "../components/static/largeslider"
import QuoteSlider from "../components/static/quoteslider"
import LogoSlider from "../components/static/logoslider"
import Accordion from "../components/static/accordion"
import Shapes from "../components/static/shapes"

import FAQ from "../components/static/faq"
import MediaComp from "../components/mediacomp"

//import HeroSlider from "../components/largesliderAnimation"

import DraggableSlider from "../components/static/dragslider"



import SingleCaseIntro from "../components/static/singleintro"
import ImageComp from "../components/static/imagecomp"
import VideoComp from "../components/static/videocomp"
import Paragraph from "../components/static/paragraph"
import CTAwithTitle from "../components/static/ctawithtitle"


//import DragSlider from "../components/dragslider"
//import CTAwithTitle from "../components/ctawithtitle"
//import Shapes from "../components/shapes"

import fullImg1 from "./../images/eg-case-study-hero-7.jpg"

import mediumImg1 from "./../images/eji-webbys-thumbnail-winner-2.jpg"


import sidedImg1 from "./../images/drag-slider.png"
import sidedImg2 from "./../images/work2.png"

const paragraph1 = `<p>Some of the projects include the redesign of the organization’s website in 2015,
a major campaign addressing the Guilty Plea Problem in America, a 25th anniversary campaign,
a new platform for long form storytelling,
and much more.</p><p>In 2018 Madeo and Innocence Project embarked on the most comprehensive
rebrand for Innocence Project to date. The rebrand, needed to reflect the dynamic
organization that Innocence Project is today, was also intended as an opportunity to create a
consistent and scalable visual system. The new brand identity was created with the goal of
supporting the fast growing brand applications: online, in print, at events, in videos, and on merchandise.</p>
`
const cat_List1 = [
    {id: 1, category: "Naming"},
    {id: 2, category: "Copywriting"},
    {id: 3, category: "Branding"},
    {id: 4, category: "Social Media"},
    {id: 5, category: "Merchandise"},
    {id: 6, category: "Marketing"},
    {id: 7, category: "Website"},
    {id: 7, category: "Online fundraising"},
]

const paragraph2 = `<h3>Advocate for the innocent</h3>
<p>The rebrand project, which started with a comprehensive discovery phase of interviews, surveys,
and in-depth evaluation, confirmed our intent in making the innocent and wrongfully convicted take
center stage of almost every communication. There are other important elements, like DNA, other
scientific tools, processes, and justice reform issues, but they all matter, because they enable
Innocence Project to fulfill its mission of freeing and advocating for the innocent. The images above,
by photographer and Innocence Project ambassador Taryn Simon, are prime examples of photography that are
incorporated into the visual direction.</p><p>The shape of the logo, with a square in motion, almost completing the word ‘Innocence’,
was intended to make use of the square as a means of highlighting one person, one issue,
or one story worth our attention at a given time. Collectively, they help complete a new justice
system that is just for all.</p>`


const paragraph3 = `<p>The project was also an opportunity to reflect on Innocence Project’s brand,
allowing us to create a new tagline. The new tagline had two goals: a statement to invite people to get
involved and take action, but also to be used as a statement that defines the organization.
After several iterations, the words,
‘Advocate for the innocent’ were crafted into a tagline, which can be read both ways.</p>
<div className="cta-holder"><a class="button medium-btn blue-btn" href="#">Visit Website</a></div>`

const tags = [
    {id: 1, text: "Branding"},
    {id: 2, text: "Website",},
    {id: 3, text: "Nonprofit"},
];


export default function IndexPage({data}) {
  
  return(
    <>
      <Helmet

      />
      <div className="pattern-page">
          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title">Top Section</h3>
                </div>
              </div>
            </div>
          </div>
          <TopSection title="About Us" backgroundClass="background-beige" />

          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title">Work and News</h3>
                </div>
              </div>
            </div>
          </div>

          <WorkNews data={{data}} backgroundClass="background-beige" showLabel={true} />


          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title">Sided CTA</h3>
                </div>
              </div>
            </div>
          </div>

          <SidedCTA data={{data}} backgroundClass="background-beige" />


          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title">Draggable Slider</h3>
                </div>
              </div>
            </div>
          </div>

          <DraggableSlider data={{data}} id="slider_id" backgroundClass="background-beige" />



          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title">Large Slider</h3>
                </div>
              </div>
            </div>
          </div>

          <LargeSlider data={{data}} backgroundClass="background-beige" />


          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title">Quote Slider</h3>
                </div>
              </div>
            </div>
          </div>

          <QuoteSlider data={{data}} backgroundClass="background-saumon" />


          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title">Big List</h3>
                </div>
              </div>
            </div>
          </div>

          <BigList data={{data}} backgroundClass="background-saumon" />


          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title"> Accordion </h3>
                </div>
              </div>
            </div>
          </div>

          <Accordion data={{data}} backgroundClass="background-orange" />


          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title"> FAQ Section </h3>
                </div>
              </div>
            </div>
          </div>
          <FAQ data={{data}} title="F.A.Q" backgroundClass="background-saumon" />


          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title">Numbers</h3>
                </div>
              </div>
            </div>
          </div>

          <NumbersList data={{data}} backgroundClass="background-beige" />

          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title"> Logos Slider - two rows</h3>
                </div>
              </div>
            </div>
          </div>

          <LogoSlider data={{data}} backgroundClass="background-beige" two_rows="true" />

          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title"> Logos Slider</h3>
                </div>
              </div>
            </div>
          </div>

          <LogoSlider data={{data}} backgroundClass="background-beige" />


          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title">Contact Information</h3>
                </div>
              </div>
            </div>
          </div>

          <ContactInfo data={{data}} backgroundClass="background-yellow" />

          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title"> Statement With CTA </h3>
                </div>
              </div>
            </div>
          </div>
          <CTAwithTitle data={{data}} backgroundClass="background-beige" />

          <div className="pt-5 pb-5 background-white" />
          <div className="pt-5 pb-5 background-white" />

          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title"> Single Intro </h3>
                </div>
              </div>
            </div>
          </div>

          <SingleCaseIntro backgroundClass="background-white"
                clientName="Innocence Project"
                title="The First Comprehensive Rebrand for Innocence Project"
                introText="In 2018 Madeo and Innocence Project embarked on the most comprehensive rebrand for Innocence Project to date.
                The rebrand, needed to reflect the dynamic organization that Innocence Project is today,
                was also intended as an opportunity to create a consistent and scalable visual system."
                tags={tags}
          />

          <div className="pt-5 pb-5 background-white" />
          <div className="pt-5 pb-5 background-white" />
          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title"> Full Image </h3>
                </div>
              </div>
            </div>
          </div>
          <ImageComp img={fullImg1} type="full" />

          <div className="pt-5 pb-5 background-white" />
          <div className="pt-5 pb-5 background-white" />
          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title"> Paragraph </h3>
                </div>
              </div>
            </div>
          </div>
          <Paragraph data={paragraph1} categoriesList={cat_List1} backgroundClass="background-white" />

          <div className="pt-5 pb-5 background-white" />
          <div className="pt-5 pb-5 background-white" />
          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title"> Medium Image </h3>
                </div>
              </div>
            </div>
          </div>
          <ImageComp img={mediumImg1} type="medium"  backgroundClass="background-white"/>

          <div className="pt-5 pb-5 background-white" />
          <div className="pt-5 pb-5 background-white" />
          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title"> Medium Video </h3>
                </div>
              </div>
            </div>
          </div>
          <VideoComp video="https://home.wistia.com/medias/e4a27b971d" type="medium" backgroundClass="background-white" />

          <div className="pt-5 pb-5 background-white" />
          <div className="pt-5 pb-5 background-white" />
          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title"> Full Video </h3>
                </div>
              </div>
            </div>
          </div>
          <VideoComp video="https://youtu.be/ScMzIvxBSi4" type="full" backgroundClass="background-white" />

          <div className="pt-5 pb-5 background-white" />
          <div className="pt-5 pb-5 background-white" />
          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title"> Sided Images </h3>
                </div>
              </div>
            </div>
          </div>
          <ImageComp  img={sidedImg1} img2={sidedImg2} type="sided" backgroundClass="background-white" />

          <div className="pt-5 pb-5 background-white" />
          <div className="pt-5 pb-5 background-white" />
          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title"> Sided Images </h3>
                </div>
              </div>
            </div>
          </div>
          <MediaComp  
              item1={sidedImg1} 
              image1Mobile={sidedImg1} 
              item1Type="image" 
              item2="https://home.wistia.com/medias/e4a27b971d" 
              item2Type="video" 
              layout="sided" 
              backgroundClass="background-white" />

          

          <div className="pt-5 pb-5 background-white" />
          <div className="pt-5 pb-5 background-white" />

          <div className="pt-5 pb-5 background-white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="small-title"> Sided Images </h3>
                </div>
              </div>
            </div>
          </div>
          <Shapes />

      </div>


    </>
  )

}
