import React, {useState, useEffect, useRef} from "react";
import './../styles/dragslider.scss'
import  useWindowSize from "./../hooks/usewindowsize"
import Swiper from 'react-id-swiper';
import ProgressiveImage from 'react-progressive-graceful-image';


import image1 from "./../../images/drag-slider.png"
import image2 from "./../../images/eji-webbys-thumbnail-winner-2.jpg"
import image3 from "./../../images/movie-intro-image.jpg"
import image4 from "./../../images/work1.png"
import image5 from "./../../images/news1.png"


 const slides = [
  { id: 0, image: image1, caption: "Aenean sed elit suscipit, luctus sapien eget, cursus elit. Fusce vel tellus ligula. Praesent pretium vulputate urna a venenatis." },
   { id: 1, image: image2, caption: "Fusce vel tellus ligula. Praesent pretium vulputate urna a venenatis." },
  { id: 2, image: image3, caption: "Aenean sed elit suscipit, luctus sapien eget,Praesent pretium vulputate urna a venenatis." },
   { id: 3, image: image4, caption: "Luctus sapien eget, cursus elit. Fusce vel tellus ligula. Praesent pretium vulputate urna a venenatis." },
    { id: 4, image: image5, caption: "Cursus elit. Fusce vel tellus ligula. Praesent pretium vulputate urna a venenatis." },
    { id: 5, image: image1, caption: "Cursus elit. Fusce vel tellus ligula. Aenean sed elit suscipit, luctus sapien eget, Praesent pretium vulputate urna a venenatis." },
    { id: 6, image: image3, caption: "Aenean sed elit suscipit, luctus sapien eget, cursus elit. Fusce vel tellus ligula. Praesent pretium vulputate urna a venenatis." },
    { id: 7, image: image5, caption: "Elit. Fusce vel tellus ligula. Praesent pretium vulputate urna a venenatis." },
]


export default function DraggableSlider({data, backgroundClass, id}) {

  const [mouseDown, setMouseDown] = useState(false);
  const [mouseUp, setMouseUp] = useState(false);
  const [mouseMove, setMouseMove] = useState(false);
  const [mouseLeave, setMouseLeave] = useState(false);


  const [maxHeight, setMaxHeight] = useState(0);
  const refContainer = useRef(null);

  const size = useWindowSize();


  const params = {
      freeMode: true,
      slidesPerView: 'auto',
      centeredSlides: false,
      grabCursor: true,
      simulateTouch: true,
      touchStartPreventDefault: false
    }

    useEffect(() => {
        //let Carousel = document.querySelector
        const { current: container_ } = refContainer;

        //console.log('child cap', caption__.getBoundingClientRect().height)

        let x = 0, i = 0, item;
        let capHeight = 0;
        let prevcapHeight = 0;
        x = container_.querySelectorAll(".p-caption");
        item = container_.querySelectorAll(".item");
        for (i = 0; i < x.length; i++) {

          if( size.width < 992 ){

            if( i == 0 ){
              capHeight = x[i].getBoundingClientRect().height;
              setMaxHeight(capHeight)
              prevcapHeight = capHeight;

              //console.log('maxx Height', maxHeight)

            }
            else if ( x[i].getBoundingClientRect().height > prevcapHeight ){
              capHeight = x[i].getBoundingClientRect().height
              setMaxHeight(capHeight)
              prevcapHeight = capHeight;

              //console.log('maxx Height', maxHeight)

            }else if ( x[i].getBoundingClientRect().height < prevcapHeight ){
              capHeight = x[i].getBoundingClientRect().height
              setMaxHeight(prevcapHeight)
              prevcapHeight = capHeight;

              //console.log('maxx Height', maxHeight)



            }

            container_.querySelector('.swiper-container').style.paddingBottom = `${maxHeight}px`;
            container_.nextElementSibling.style.marginTop = `0px`;
            if(container_.classList.contains('no-padding')){
              container_.style.paddingBottom = `0px`;
            }

          }else if( size.width > 992 && container_.classList.contains('no-padding')){

            if( i == 0 ){
              capHeight = x[i].getBoundingClientRect().height;
              setMaxHeight(capHeight)
              prevcapHeight = capHeight;

              //console.log('maxx Height', maxHeight)

            }
            else if ( x[i].getBoundingClientRect().height > prevcapHeight && x[i].getBoundingClientRect().height < 100 ){
              capHeight = x[i].getBoundingClientRect().height
              setMaxHeight(capHeight)
              prevcapHeight = capHeight;

              //console.log('maxx Height', maxHeight)

            }else if ( x[i].getBoundingClientRect().height < prevcapHeight ){
              capHeight = x[i].getBoundingClientRect().height
              setMaxHeight(prevcapHeight)
              prevcapHeight = capHeight;

              //console.log('maxx Height', maxHeight)



            }

            container_.style.paddingBottom = `${maxHeight}px`;
            container_.nextElementSibling.style.marginTop = `-${maxHeight}px`;
            container_.querySelector('.swiper-container').style.paddingBottom = `0px`;

            //console.log(" container_.nextElementSibling",  container_.nextElementSibling)

          }


        }

    });

   function handleMove(e) {
      const cursor = document.querySelector('.cursor')
      const Carousel = document.querySelector(".drag-carousel");
      setMouseMove(true);
      setMouseLeave(false)
        let x = (e.pageX) - window.pageXOffset;;
        let y = (e.pageY) - window.pageYOffset;
        cursor.classList.add("drag-cursor");
        cursor.classList.remove("default-cursor");
        cursor.style.transform = `translate(-50%, -50%) translate(${x}px, ${y}px)`
    }

    function handleLeave(e) {
      const cursor = document.querySelector('.cursor')
      const Carousel = document.querySelector(".drag-carousel");

      cursor.classList.remove("drag-cursor");
      cursor.classList.add("default-cursor");
      setMouseLeave(true)
      setMouseDown(false)
      setMouseMove(false);

    }
    function handleMouseDown(e) {
      const cursor = document.querySelector('.cursor')
      const Carousel = document.querySelector(".drag-carousel");
            cursor.classList.add("down");
            setMouseDown(true)
            setMouseUp(false)
            setMouseLeave(false)


    }
    function handleMouseUp(e) {
      const cursor = document.querySelector('.cursor')
      const Carousel = document.querySelector(".drag-carousel");
            setMouseUp(true)
            setMouseDown(false)
            cursor.classList.remove("down");

    }

    function handleMouseOnItem(el){
      let elParent = el.target.parentElement.parentElement;

      let bounding = elParent.getBoundingClientRect();

      let right = bounding.right;

      if(
          bounding.left >= 0 &&
          (right <= (window.innerWidth) )
        ){
        elParent.classList.add('fully-in');
        //console.log('its here')
      }else{
        elParent.classList.remove('fully-in');
       // console.log('it left')
      }




    }

    return (
      <section ref={refContainer} id={id}
          
          className={`dragableslider-section ${backgroundClass ? backgroundClass : ""} ${mouseDown ? 'mouseDown' : ''} ${mouseUp ? 'mouseUp' : ''} ${mouseMove ? 'mouseMove': ''} ${mouseLeave ? 'mouseLeave': ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={`slider-cont`}

                onMouseDown={handleMouseDown}
                onTouchStart={handleMouseDown}
                onMouseUp={handleMouseUp}
                onTouchEnd={handleMouseUp}
                onMouseMove={handleMove}
                onMouseLeave={handleLeave}


              >
                  <Swiper {...params}>
                    {slides.map((item, i) => {
                      return (
                          <div className="item" id={`slide_${item.id}`}key={i}>
                            <div className="img-wrap background-yellow" onMouseMove={handleMouseOnItem}>
                                <img className="placeholder-img" src={item.image}/>
                                <div className="lazy-holder" >
                                  <ProgressiveImage
                                      className="main-image"
                                      delay={500}
                                      src={item.image}
                                      srcSetData={{
                                        srcSet: `${item.image} 320w, ${item.image} 700w, ${item.image} 2000w`,
                                        sizes: '(max-width: 2000px) 100vw, 2000px'
                                      }}
                                      placeholder={item.image}
                                  >
                                    {(src, loading, srcSetData, placeholder) => (
                                          <img style={{ opacity: loading ? 0 : 1, transition: "0.3s ease" }}
                                            src={src}
                                            srcSet={srcSetData.srcSet}
                                            sizes={srcSetData.sizes}
                                            alt="an image"
                                          />
                                      )}


                                  </ProgressiveImage>
                                </div>

                            </div>
                            {item.caption &&
                              <p className="p-caption"> {item.caption} </p>
                            }
                          </div>
                      )
                     })}
                  </Swiper>
                </div>
            </div>
          </div>
        </div>

      </section>
    );
  }
