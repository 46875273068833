import React, { useState, useEffect } from "react"
import { Helmet } from 'react-helmet'
import SEO from "../components/seo"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import TopSection from "../components/topsection"
import StickyImages from "../components/stickyimgs"
import NumbersList from "../components/numberslist"
import QuoteSlider from "../components/quoteslider"
import Accordion from "../components/accordion"
import Shapes from "../components/shapes"

import axios from 'axios'
import md5 from 'md5'
import NoMatchPage from '../pages/404';


export default function  CareersPage(props) {
  //console.log(props);
  const path = props.match.path;
  let api_url = '/wp-json/wp/v2/pages/';
  const [axiosArr, setAxiosArr] = useState([]);
  const [newToken, setNewToken] = useState(false);

  const [pageData, setPageData] = useState([]);
  const [cptData, setcptData] = useState([]);
  const [cptDepartments, setcptDepartments] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState('');


  useEffect(() => {
    if(path.indexOf('_preview') > -1){
      let password = prompt('Password');
      const pageID = path.substr(path.lastIndexOf('/') + 1);
      api_url += pageID+'/revisions/?per_page=1&offset=0';
      if(password){
        axios.all([axios.post('/wp-json/madeo/v1/token',{sec_key:md5(password)})])
          .then(res => {
            let token_res = res[0].data;
            if(token_res.token !== undefined){
              setAxiosArr([
                axios.get(api_url,{headers: {authorization: 'Bearer '+token_res.token}}),
                axios.get('/wp-json/wp/v2/careers?orderby=menu_order&order=asc&hide_password=true'),
                axios.get('/wp-json/wp/v2/department/?hide_empty=true')
              ]);
              setNewToken(true);
            }else{
              setNewToken(false);
              setLoad(true);
              setError('404');
            }
            
          })
          .catch(err => {
              setError(err.message);
              setLoad(false)
          })
      }else{
        setNewToken(false);
        setLoad(true);
        setError('404');
      } 
     
    }else{
      api_url += '?slug='+path.substring(1);
      setAxiosArr([
        axios.get(api_url),
        axios.get('/wp-json/wp/v2/careers?orderby=menu_order&order=asc&hide_password=true'),
        axios.get('/wp-json/wp/v2/department/?hide_empty=true')
      ]);
      setNewToken(true);
    }

    }, [path]);

  

  useEffect(() => {
    if(newToken){
      axios.all(axiosArr)
        .then(res => {
            setPageData(res[0].data[0]);
            setcptData(res[1].data);
            setcptDepartments(res[2].data);
            setLoad(true);
        })
        .catch(err => {
            setError(err.message);
            setLoad(true)
        })
    }

  }, [newToken, axiosArr]);

  if (error) {
    return (
      <Route component={NoMatchPage} />
    )

  }



  if (load) {

    let seo_data = pageData.yoast_meta;
    let featured_image = pageData.featured_image;

    let seo_title = pageData.yoast_meta.yoast_wpseo_title;
    let seo_head= pageData.yoast_head;
    let acf = pageData.acf;

    

      return(
          <>
              { seo_data &&
              <SEO data={seo_data} image={featured_image} />
              }
              <Helmet
                  bodyAttributes={{
                      class: `page-careers ${acf.background_color} fixed-header`
                  }}
              />

              {acf.title &&
                  <TopSection title={acf.title}
                  description={acf.description}
                  backgroundClass={acf.background_color} />
              }

              {acf.page_content && acf.page_content.map((sectionData, index) => {
                  switch(sectionData.acf_fc_layout){
                      case 'sticky_images':{
                          return(
                          <StickyImages data={sectionData} backgroundClass="background-beige" key={index} />
                          )
                          break;
                      }
                      case 'quote_slider':{
                          return(
                            <QuoteSlider data={sectionData} backgroundClass="background-saumon" key={index} />
                          )
                          break;
                      }
                      case 'numbers_list':{
                          return(
                            <NumbersList data={sectionData} backgroundClass="background-saumon" key={index} />
                          )
                          break;
                      }
                      case 'opening_jobs':{
                          return(
                            <Accordion data={sectionData} posts={cptData} departments={cptDepartments} title={sectionData.main_title} backgroundClass="background-orange" key={index} />
                          )
                          break;
                      }
                      case 'shapes_two_rows':{
                          return(
                          <Shapes data={sectionData} key={index} />
                          )
                          break;
                      }
                  }


                  }
              )}
              
  

          </>
      )


  } else {
    return (
      <div style={{height: '100vh'}}></div>
    )
  }
}
