import React, {useState, useRef, useEffect} from "react"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import useDelayRouteExit from "delay-react-route-exit";
import { Helmet } from 'react-helmet'
import SEO from "../components/seo"
import SingleIntro from "../components/singleintro"
import MediaComp from "../components/mediacomp"
import ImageComp from "../components/imagecomp"
import VideoComp from "../components/videocomp"
import Paragraph from "../components/paragraph"
import CTAwithTitle from "../components/ctawithtitle"
import DraggableSlider from "../components/dragslider"
import Shapes from "../components/shapes"
import PasswordProtectedForm from '../components/passwordprotectedform';

import axios from 'axios'
import md5 from 'md5'
import NoMatchPage from '../pages/404'

import "../components/styles/single.scss"


export default function SingleNews(props) {

  let api_url = '/wp-json/wp/v2/projects/';
  const [axiosArr, setAxiosArr] = useState([]);
  const [newToken, setNewToken] = useState(false);

  const [pageData, setPageData] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState('');
  const [ctaTitleData, setCtaTitleData] = useState([]);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if(props.match.params.id){

        let password = prompt('Password');
      api_url += props.match.params.id+'/revisions/?per_page=1&offset=0';
      if(password){
          axios.all([axios.post('/wp-json/madeo/v1/token',{sec_key:md5(password)})])
          .then(res => {
              let token_res = res[0].data;
              if(token_res.token != undefined){
              setAxiosArr([
                  axios.get(api_url,{headers: {authorization: 'Bearer '+token_res.token}}),
                  axios.get('/wp-json/acf/v2/options/')
              ]);
              setNewToken(true);
              }else{
              setNewToken(false);
              setLoad(true);
              setError('404');
              }
              
          })
          .catch(err => {
              setError(err.message);
              setLoad(false)
          })
      }else{
          setNewToken(false);
          setLoad(true);
          setError('404');
      }




    }else{
      const workSlug = props.match.params.slug;
      api_url += '?slug='+workSlug;
      setAxiosArr([
        axios.get(api_url),
        axios.get('/wp-json/acf/v2/options/')
      ]);
      setNewToken(true);
    }

    }, []);




   useEffect(() => {
      if(newToken){
        axios.all(axiosArr)
        .then(res => {
            setPageData(res[0].data[0]);
            setCtaTitleData(res[1].data.acf.details);
            setLoad(true);
        })
        .catch(err => {
            setError(err.message);
            setLoad(true)
        })
      }
   }, [newToken, axiosArr]);

let work_news = [];


if (!pageData || error) {
  return (
    <Route component={NoMatchPage} />
  )
}


  if (error) {
    return (
      <div style={{height: '100vh'}}></div>
    )

  }

  
  function setValidPassword(){
    setShowContent(true);
    sessionStorage.setItem('post_password_'+pageData.id, true);
  }
  
  function isPreRendering() {
    return navigator.userAgent === 'ReactSnap';
  }

let counter;

  if (load) {

    let validated_password = sessionStorage.getItem('post_password_'+pageData.id);
    if(pageData.content.protected && !showContent && validated_password == null && !isPreRendering()){
      
      //password form should be here and condition should check submit response
      return(
        <>
              <Helmet
                  bodyAttributes={{
                      class: `page-single page-single-news background-white fixed-header`
                  }}
              />
              <div className={`single-wrapper background-white `}>
              <PasswordProtectedForm pageData={pageData} setValidPassword={setValidPassword}/>
            </div>
          </>
      )

    }else if(showContent || !pageData.content.protected || (pageData.content.protected && validated_password == "true")){

      let seo_data = pageData.yoast_meta;
      let featured_image = pageData.featured_image;
      let acf = pageData.acf;
      let tags = pageData['project-category'];
      let source = 'project-category';
      let clientName = pageData.acf.client_name;
      let intro_text = pageData.acf.intro_text;
      let intro_title = pageData.acf.intro_title;

      function isPreRendering() {
        return navigator.userAgent === 'ReactSnap';
      };
  
      let preRender = isPreRendering();

      return(
          <>
              { seo_data &&
              <SEO data={seo_data} image={featured_image} />
              }
              <Helmet
                  bodyAttributes={{
                      class: `page-single page-single-news ${acf.background_color ? acf.background_color : 'background-white'} fixed-header`
                  }}
              />

            {  !preRender && 
                <>
              <div className="single-wrapper background-white">
                <SingleIntro
                    clientName={clientName}
                    title={intro_title}
                    introText={intro_text}
                    // tags={tags}
                    source={source}

                />

              { acf.page_content && acf.page_content.map((sectionData, index) => {

                  switch(sectionData.acf_fc_layout){
                    case 'paragraph_category':{
                        return(
                          <Paragraph data={sectionData.paragraph} categoriesList={sectionData.categories_list} backgroundClass="background-white" key={index} />
                        )
                        break;
                    }
                      case 'draggable_slider':{
                          return(
                            <DraggableSlider data={sectionData.draggable_slider_pattern} backgroundClass="background-white" key={index} />
                          )
                          break;
                      }
                      case 'cta_with_title':{
                          return(
                            <CTAwithTitle data={sectionData} backgroundClass="background-white" key={index} />
                          )
                          break;
                      }

                      case 'image_pattern':{

                        switch (sectionData.media_type) {
                          case 'full':
                            if(sectionData.full_image){
                              return(
                                <ImageComp img={sectionData.full_image} img_mobile={sectionData.mobile_medium_image} type="full" key={index} />
                              )
                            }
                            break;
                            case 'medium':
                              if(sectionData.medium_image){
                                return(
                                  <ImageComp img={sectionData.medium_image} img_mobile={sectionData.mobile_medium_image} type="medium" key={index} />
                                )
                              }
                              break;
                            case 'sided':
                              if(sectionData.sided_image_1 || sectionData.sided_image_2){
                                return(
                                  <ImageComp  img={sectionData.sided_image_1} img_mobile={sectionData.sided_image_1} img2={sectionData.sided_image_2} img2_mobile={sectionData.sided_image_2} type="sided" key={index} />
                                )
                              }
                              break;
                        }
                          break;
                      }

                      case 'video_pattern':{

                        switch (sectionData.media_type) {
                          case 'full':
                              return(
                                <VideoComp video={sectionData.full_video} type="full" key={index} />
                              )
                            break;
                            case 'medium':
                                return(
                                  <VideoComp video={sectionData.medium_video} type="medium" key={index} />
                                )
                              break;
                            case 'sided':
                                return(
                                  <VideoComp  video={sectionData.sided_video_1} video2={sectionData.sided_video_2} type="sided" key={index} />
                                )
                              break;
                        }
                          break;
                      }

                      case 'mixed_image_video':{
                        switch (sectionData.media_type) {
                          case 'image':

                            switch (sectionData.content_type) {
                              case 'full':
                                if (sectionData.desktop_image || sectionData.mobile_image.url) {
                                  let desktop = sectionData.desktop_image.url,
                                      mobile = sectionData.mobile_image.url;
                                      if (!mobile) {
                                        mobile = desktop;
                                      }
                                  return(
                                    <MediaComp item1={desktop} image1Mobile={mobile}  item1Type="image"  layout="full" key={index} />
                                  )
                                }
                                break;
                              case 'medium':
                                if (sectionData.medium_desktop || sectionData.medium_mobile.ur) {
                                  let desktop = sectionData.medium_desktop.url,
                                      mobile = sectionData.medium_mobile.ur;
                                      if (!mobile) {
                                        mobile = desktop;
                                      }
                                  return (
                                    <MediaComp item1={desktop} image1Mobile={mobile}  item1Type="image"  layout="medium" key={index} />
                                  )
                                }
                            }
                            break;
                            case 'video':
                              switch (sectionData.content_type) {
                                case 'full':
                                  if (sectionData.full_video) {
                                    return(
                                      <MediaComp item1={sectionData.full_video} item1Type="video"  layout="full" key={index} />
                                    )
                                  }
                                  break;
                                case 'medium':
                                  if (sectionData.medium_video_url) {
                                    return (
                                      <MediaComp item1={sectionData.medium_video_url} item1Type="video"  layout="medium" key={index} />
                                    )
                                  }
                              }
                              break;
                            case 'sided':
                              let first_type  = sectionData.sided_first_media_type,
                                  first_image = sectionData.first_sided_image.url,
                                  first_mobile = sectionData.first_mobile_sided_image.url,
                                  first_video = sectionData.first_sided_video,
                                  second_type = sectionData.sided_second_media_type,
                                  second_image = sectionData.second_sided_image.url,
                                  second_mobile = sectionData.second_mobile_sided_image.url,
                                  second_video = sectionData.second_sided_video,
                                  first_item,
                                  first_item_mobile,
                                  second_item,
                                  second_item_mobile;

                                  if (!first_mobile) {
                                    first_mobile = first_image;
                                  }

                                  if (!second_mobile) {
                                    second_mobile = second_image;
                                  }

                                  if (first_type == 'image') {
                                      first_item = first_image;
                                      first_item_mobile = first_mobile;
                                  } else {
                                      first_item = first_video;
                                      first_item_mobile = '';
                                  }

                                  if (second_type == 'image') {
                                      second_item = second_image;
                                      second_item_mobile = second_mobile;
                                  } else {
                                      second_item = second_video;
                                      second_item_mobile = '';
                                  }

                              return (
                                <MediaComp item1={first_item} image1Mobile={first_item_mobile}  item1Type={first_type} item2={second_item} image2Mobile={second_item_mobile} item2Type={second_type} layout="sided" key={index} />
                              )

                            break;
                        }
                          break;
                      }


                      case 'rich_text':{
                        return(
                          <Paragraph data={sectionData.rich_text} ctaButton={sectionData.cta_button} ctaUrl={sectionData.cta_url} externalLink={sectionData.external_link} key={index} />
                        )
                        break;
                      }

                  }


                  }
              )}

            </div>
            { acf.shapes &&
              <Shapes backgroundClass="" data={acf.shapes} />
            }
            { ctaTitleData &&
              <CTAwithTitle data={ctaTitleData} backgroundClass="background-white" />
            }
            </>
          }
          </>
      )
    }
  } else {
    return (
      <div style={{height: '100vh'}}></div>
    )
  }
}
