import React from "react"
//import { ScrollScene, ScrollObserver } from 'scrollscene'
//import { gsap } from 'gsap'
import { Link } from 'react-router-dom'
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import 'intersection-observer'
import ProgressiveImage from 'react-progressive-graceful-image';

import "./styles/ctawithimages.scss";

export default function CTAwithImages({ data, backgroundClass }) {
    const triggerRecImg = React.useRef(null)
    const triggerSquareImg = React.useRef(null)

    let square_image, rect_image, cta_button_text, cta_url, cta_description;


    square_image = data.square_image.url;
    rect_image = data.rectangle_image.url;
    cta_url = data.cta_url;
    cta_button_text = data.cta_button_text;
    cta_description = data.cta_description;

    if (cta_description) {
      cta_description = cta_description.replace(/(<([^>]+)>)/ig,"");
    }


    function handleMove(e) {
      if(!isMobile){
        const cursor = document.querySelector('.cursor');
        /*console.log(e.target)*/
        if (e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'button' || e.target.tagName.toLowerCase() === 'input') {       cursor.classList.add("large-cirlce")
        }else{
          cursor.classList.remove("large-cirlce")
        }
      }
    }
    function handleLeave(e) {
      if(!isMobile){
        const cursor = document.querySelector('.cursor')
        cursor.classList.remove("large-cirlce")
      }
    }


    React.useEffect(() => {
    const { current: triggerImg1 } = triggerRecImg
    const { current: triggerImg2 } = triggerSquareImg

    if (!triggerImg1 && !triggerImg2) {
      return undefined
    }
    
  }, [])

  return(
    <section className={`cta-with-images-section ${backgroundClass? backgroundClass : ""}`}
       onMouseEnter={handleMove}
       onMouseMove={handleMove}
       onMouseLeave={handleLeave}>

          	<div className="container images-container col-12">
          		<div className="row">
          			<div className="col-3">
            			<div className={`${data.square_lazy_load_background ? data.square_lazy_load_background : ""} image-item square-img`} ref={triggerSquareImg} style={{transform: "scale(1)"}}>
            			   <div className="lazy-holder">
                        <ProgressiveImage
                            className="main-image"
                            delay={500}
                            src={square_image}
                            srcSetData={{
                              srcSet: `${square_image} 320w, ${square_image} 700w, ${square_image} 2000w`,
                              sizes: '(max-width: 2000px) 100vw, 2000px'
                            }}
                            placeholder={square_image}
                        >
                          {(src, loading, srcSetData, placeholder) => (
                                <img style={{ opacity: loading ? 0 : 1, transition: "0.3s ease" }}
                                  src={src}
                                  srcSet={srcSetData.srcSet}
                                  sizes={srcSetData.sizes}
                                  alt="an image"
                                />
                            )}


                        </ProgressiveImage>
                      </div>
                  </div>
            		</div>
            		<div className="col-md-8 col-sm-9 col-12 offset-md-1">
            			<div className={`${data.rectangle_lazy_load_background ? data.rectangle_lazy_load_background : ""} image-item rec-img`} ref={triggerRecImg} style={{transform: "scale(1)"}}>
            			   <div className="lazy-holder">
                        <ProgressiveImage
                            className="main-image"
                            delay={500}
                            src={rect_image}
                            srcSetData={{
                              srcSet: `${rect_image} 320w, ${rect_image} 700w, ${rect_image} 2000w`,
                              sizes: '(max-width: 2000px) 100vw, 2000px'
                            }}
                            placeholder={rect_image}
                        >
                          {(src, loading, srcSetData, placeholder) => (
                                <img style={{ opacity: loading ? 0 : 1, transition: "0.3s ease" }}
                                  src={src}
                                  srcSet={srcSetData.srcSet}
                                  sizes={srcSetData.sizes}
                                  alt="an image"
                                />
                            )}


                        </ProgressiveImage>
                      </div>

                  </div>
            		</div>
            	</div>
            </div>

        	<div className="container">
          		<div className="row">
            		<div className="col-md-8 cta-holder">
                {cta_description &&
                  <h3 className="big-title title">
                    {
                      cta_description
                    }
                  </h3>
                }

                  {cta_button_text && cta_url &&
                    <div className="btn-wrap">
                      <Link to={ cta_url } className="button big-btn blue-btn" > { cta_button_text } </Link>
                    </div>
                  }

          			</div>
          		</div>
        	</div>
      </section>
  )


}
