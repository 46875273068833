import React, { useState, useEffect, useCallback, useRef } from "react"
import axios from 'axios'
import "./styles/relatednews.scss"
import { Link } from 'react-router-dom'
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";




export default function RelatedNews({ data, backgroundClass }){
  let List = data.articles;

  function handleMove(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
    }
  }
  function handleLeave(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce");
    }
  }


  if(List){
    return (
      <section className={`relatednews-section ${backgroundClass ? backgroundClass : ""}`}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 main-titles">
                { data.label &&
                  <p className="p-big"> {data.label} </p>
                }
                { data.description &&
                    <h3 className="big-title title" dangerouslySetInnerHTML={{ __html: data.description }} />
                }
                { data.cta &&
                  <a className="button small-btn blue-btn" href={data.cta.url}
                      onMouseEnter={handleMove}
                      onMouseMove={handleMove}
                      onMouseLeave={handleLeave}
                  >
                    {data.cta.title}
                  </a>
                }


              </div>
            </div>
            <div className="row align-items-start">
              {List.map((item, i) => {
                return (
                    <MyItem item={item} id={item.id} bg={backgroundClass} key={i} />
                )
              })}

            </div>
          </div>
      </section>
    )
  }else{
    return ``;
  }
}



function MyItem({item, id}){
  const [fullItem, setFullItem] = useState(null);
  let getFullItemURL = '/wp-json/wp/v2/posts/'+item.ID;
  React.useEffect(() => {
    axios.get(getFullItemURL).then(res => {
      setFullItem(res.data);
    });
  }, []);


  function handleMove(e) {
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
  }
  function handleLeave(e) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce");
  }
  if(fullItem != null){
    item = fullItem;
    return (
      <div className={`col-md-6 item`}>
        <div  className={`item-wrapper`}>
            <Link to={`/news/${item.slug}`}
                  onMouseEnter={handleMove}
                  onMouseMove={handleMove}
                  onMouseLeave={handleLeave}
            >
              {item.featured_image &&
              <div  className={`image-wrapper`}>
                  <div className="item-thumb" style={{backgroundImage: "url("+item.featured_image+")"}}></div>
              </div>
              }
              <div className={`titles ${item.acf && item.acf.summary ? 'with-summary' : ""}`}>
                <h3 className="medium-title title" dangerouslySetInnerHTML={{ __html: item.title.rendered }} />
                {item.acf && item.acf.summary &&
                <p className="p-big desc">
                  {item.acf.summary}
                </p>
                }
              </div>
            </Link>
        </div>
      </div>
    )
  }else{
    return ``;
  }

}
