import React, {useState, useRef, useEffect} from "react"
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import SEO from "../components/seo"
import TopSection from "../components/topsection"
import WorkNews from "../components/worknews"
import BigList from "../components/biglist"

import LogoSlider from "../components/logoslider"
import CTAwithTitle from "../components/ctawithtitle"
import Shapes from "../components/shapes"
import MediaComp from "../components/mediacomp"
import DraggableSlider from "../components/dragslider"
import LargeSlider from "../components/largeslider"
import QuoteSlider from "../components/quoteslider"
import StickyImages from "../components/stickyimgs"
import NumbersList from "../components/numberslist"
import CTAwithImages from "../components/ctawithimages"
import SidedCTA from "../components/sidedcta"
import FAQ from "../components/faq"
import PasswordProtectedForm from '../components/passwordprotectedform';

import PagesRoutes from '../components/pagesroutes'
import SingleNews from '../pages/singlenews';

import NoMatchPage from '../pages/404';
import axios from 'axios'
import md5 from 'md5'


export default function DefaultTemplate(props) {
  if(props.props){
    props = props.props; //internal route form single news component
  }
  const path = props.location.pathname;
  //console.log('i am in default template', props);
  let api_url = '/wp-json/wp/v2/pages/';
  const [axiosArr, setAxiosArr] = useState([]);
  const [newToken, setNewToken] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const [pageData, setPageData] = useState([]);
  const [pageTemplate, setPageTemplate] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState('');
  const [gotoSingleNews, setGotoSingleNews] = useState(false);

  function renderNotFound(){
    setError('404');
    setNewToken(false);
    setPageData(['404']);
    setLoad(true)
  }

  useEffect(() => {
    //console.log('i am in default template: preview', props.match.params.id);
    if(path.indexOf('_preview') > -1){
      let password = prompt('Password');
      const pageID = path.substr(path.lastIndexOf('/') + 1);
      api_url += pageID+'/revisions/?per_page=1&offset=0';
      if(password){
        axios.all([axios.post('/wp-json/madeo/v1/token',{sec_key:md5(password)})])
          .then(res => {
            let token_res = res[0].data;
            if(token_res.token != undefined){
              setAxiosArr([
                axios.get(api_url,{headers: {authorization: 'Bearer '+token_res.token}})
              ]);
              setNewToken(true);
            }else{
              renderNotFound()
            }
            
          })
          .catch(err => {
            renderNotFound()
          })
    }else{
      renderNotFound()
    }  
      
      
    }else{
      api_url += '?slug='+path.substring(1);
      setAxiosArr([
        axios.get(api_url)
      ]);
      setNewToken(true);
    }

    }, [path]);

    function RedirectWithStatus({ from, to, status }) {
      return (
        <Route
          render={({ staticContext }) => {
            // there is no `staticContext` on the client, so
            // we need to guard against that here
            if (staticContext) staticContext.status = status;
            return <Redirect from={from} to={to} />;
          }}
        />
      );
    }
  
  useEffect(() => {
    if(newToken){
      axios.all(axiosArr)
        .then(res => {
          if(res[0].data.length > 0){
            setPageData(res[0].data[0]);
            setPageTemplate(res[0].data[0].template);
            setLoad(true);
          }else{
            if(path.indexOf('_preview') == -1){
              const post_api_url = '/wp-json/wp/v2/posts/?slug='+path.substring(1).slice(0,-1);
              axios.all([axios.get(post_api_url)]).then(post_res=>{
                if(post_res[0].data.length > 0){
                  setGotoSingleNews(true);
                }else{
                  setPageData(['404']);
                  setLoad(true);
                }
              })
            }
            
          }
          
        })
        .catch(err => {
            setError(err);
            setPageData(['404']);
            setLoad(true)
        })
    }

  }, [newToken, axiosArr]);

  if(gotoSingleNews){
    return(
      <RedirectWithStatus status={301} from={path.substring(1)} to={ `/news/${path.substring(1)}`} />
    )
  }

  /*if (load && error) {
    return (
      <PagesRoutes pageData={pageData} />
    )

  }

  <PagesRoutes pageData={pageData} />*/

  if (load && pageData[0] == '404') {
    return (
      <Route component={NoMatchPage} />
    )
  }

  function setValidPassword(){
    setShowContent(true);
    sessionStorage.setItem('post_password_'+pageData.id, true);
  }

  function isPreRendering() {
    return navigator.userAgent === 'ReactSnap';
  }



  if (load && pageData) {
    let validated_password = sessionStorage.getItem('post_password_'+pageData.id);
    if(pageData.content.protected && !showContent && validated_password == null && !isPreRendering()){
      return(
        <>
              <Helmet
                  bodyAttributes={{
                      class: `background-white`
                  }}
              />
              <div className={`single-wrapper background-white `}>
                <PasswordProtectedForm pageData={pageData} setValidPassword={setValidPassword}/>
              </div>
          </>
      )
    }else if(showContent || !pageData.content.protected || (pageData.content.protected && validated_password == "true")){


      /*if(pageData.template != ''){
        //console.log('i should be routed to: ',pageData.template );
        if(props.match.params.id){
          return (
            <PagesRoutes pageData={pageData} is_preview="true" />
          )

        }

        /*console.log('i should be in about template',pageData );
        /*<Route exact path={`/pageData.slug}`} pageSlug={pageData.slug} component={Aboutus} />
        return (
            <Aboutus props={props}/>
        )*
      }*/

      let seo_data = pageData.yoast_meta;
      let featured_image = pageData.featured_image;

      let acf = pageData.acf;

      function isPreRendering() {
        return navigator.userAgent === 'ReactSnap';
      };
    
      let preRender = isPreRendering();

        return(
            <>
              { seo_data &&
              <SEO data={seo_data} image={featured_image} />
              }
                <Helmet
                    bodyAttributes={{
                        class: `${acf.background_color}`
                    }}
                />

                { !preRender &&
                <>
                  { acf.title &&
                    <TopSection title={acf.title}
                    description={acf.description}
                    backgroundClass={acf.background_color} filter={false} filterAllPath={`work`}/>
                  }


                { acf.page_content && acf.page_content.map((sectionData, index) => {
                    switch(sectionData.acf_fc_layout){
                        case 'work_and_news':{
                            return(
                            <WorkNews data={sectionData.details} backgroundClass="background-beige" showLabel={true} targetPage="homepage" key={index} />
                            )
                            break;
                        }
                        case 'logos_slider':{
                            return(
                              <LogoSlider data={sectionData} backgroundClass="background-saumon" key={index} two_rows={sectionData.two_rows_slider}/>
                            )
                            break;
                        }
                        case 'draggable_slider':{
                            return(
                              <DraggableSlider data={sectionData.draggable_slider_pattern} backgroundClass="background-beige" key={index} />
                            )
                            break;
                        }
                        case 'large_slider':{
                          return(
                            <LargeSlider data={sectionData.large_slider} backgroundClass="background-saumon" key={index} />
                          )
                          break;
                        }
                        case 'quotes_slider':{
                          return(
                            <QuoteSlider data={sectionData} backgroundClass="background-saumon" key={index} />
                          )
                          break;
                        }
                        case 'sticky_images':{
                          return(
                            <StickyImages data={sectionData} backgroundClass="background-beige" key={index} />
                            )
                          break;
                        }
                        case 'numbers':{
                          return(
                            <NumbersList data={sectionData} backgroundClass="background-saumon" key={index} />
                          )
                          break;
                        }
                        case 'cta_with_title':{
                            return(
                              <CTAwithTitle data={sectionData} backgroundClass="background-saumon" key={index} />
                            )
                            break;
                        }
                        case 'sided_cta':{
                          return(
                            <SidedCTA data={sectionData.sided_cta} backgroundClass="background-yellow" key={index} />
                          )
                          break;
                        }
                        case 'cta_with_images':{
                          return(
                            <CTAwithImages data={sectionData} backgroundClass="background-yellow" key={index} />
                          )
                          break;
                        }
                        case 'big_list':{
                            return(
                              <BigList data={sectionData} backgroundClass="background-orange" key={index} />
                            )
                            break;
                        }
                        case 'shapes_one_row':{
                            return(
                              <Shapes data={sectionData} key={index} />
                            )
                            break;
                        }
                        case 'shapes_two_row':{
                          return(
                            <Shapes data={sectionData} key={index} />
                          )
                          break;
                        }
                        case 'faq':{
                          return(
                          <FAQ data={sectionData.faq} title={acf.title} backgroundClass={acf.background_color} key={index} />
                          )
                          break;
                        }
                        case 'mixed_image_video':{
                          switch (sectionData.media_type) {
                            case 'image':

                              switch (sectionData.content_type) {
                                case 'full':
                                  if (sectionData.desktop_image || sectionData.mobile_image.url) {
                                    let desktop = sectionData.desktop_image.url,
                                        mobile = sectionData.mobile_image.url;
                                        if (!mobile) {
                                          mobile = desktop;
                                        }
                                    return(
                                      <MediaComp item1={desktop} image1Mobile={mobile}  item1Type="image"  layout="full" key={index} />
                                    )
                                  }
                                  break;
                                case 'medium':
                                  if (sectionData.medium_desktop || sectionData.medium_mobile.ur) {
                                    let desktop = sectionData.medium_desktop.url,
                                        mobile = sectionData.medium_mobile.ur;
                                        if (!mobile) {
                                          mobile = desktop;
                                        }
                                    return (
                                      <MediaComp item1={desktop} image1Mobile={mobile}  item1Type="image"  layout="medium" key={index} />
                                    )
                                  }
                              }
                              break;
                              case 'video':
                                switch (sectionData.content_type) {
                                  case 'full':
                                    if (sectionData.full_video) {
                                      return(
                                        <MediaComp item1={sectionData.full_video} item1Type="video"  layout="full" key={index} />
                                      )
                                    }
                                    break;
                                  case 'medium':
                                    if (sectionData.medium_video_url) {
                                      return (
                                        <MediaComp item1={sectionData.medium_video_url} item1Type="video"  layout="medium" key={index} />
                                      )
                                    }
                                }
                                break;
                              case 'sided':
                                let first_type  = sectionData.sided_first_media_type,
                                    first_image = sectionData.first_sided_image.url,
                                    first_mobile = sectionData.first_mobile_sided_image.url,
                                    first_video = sectionData.first_sided_video,
                                    second_type = sectionData.sided_second_media_type,
                                    second_image = sectionData.second_sided_image.url,
                                    second_mobile = sectionData.second_mobile_sided_image.url,
                                    second_video = sectionData.second_sided_video,
                                    first_item,
                                    first_item_mobile,
                                    second_item,
                                    second_item_mobile;

                                    if (!first_mobile) {
                                      first_mobile = first_image;
                                    }

                                    if (!second_mobile) {
                                      second_mobile = second_image;
                                    }

                                    if (first_type == 'image') {
                                        first_item = first_image;
                                        first_item_mobile = first_mobile;
                                    } else {
                                        first_item = first_video;
                                        first_item_mobile = '';
                                    }

                                    if (second_type == 'image') {
                                        second_item = second_image;
                                        second_item_mobile = second_mobile;
                                    } else {
                                        second_item = second_video;
                                        second_item_mobile = '';
                                    }

                                return (
                                  <MediaComp item1={first_item} image1Mobile={first_item_mobile}  item1Type={first_type} item2={second_item} image2Mobile={second_item_mobile} item2Type={second_type} layout="sided" key={index} />
                                )

                              break;
                          }
                            break;
                        }


                    }


                  }
                )}
                </>
                }


            </>
        )

    }
  } else {
    return (
      <div style={{height: '100vh'}}></div>
    )
  }

}
