import React, { useState, useEffect, useCallback, useRef } from "react"
import { Link } from 'react-router-dom'
import axios from 'axios'
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

import "./styles/singleintro.scss";




export default function SingleIntro({ clientName, title, introText, tags, source, backgroundClass, newsArticle }) {
  const isNewsArticle = newsArticle;
  const [categoriesData, setcategoriesData] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState('');
  let bodyLock;
  let taxonomyUrl = source;

  if (taxonomyUrl == 'categories') {
      taxonomyUrl = 'category';
  }


  useEffect(() => {
    if (tags) {
      for (var i = 0; i < tags.length; i++) {
        axios.get('/wp-json/wp/v2/'+source+'/'+tags[i])
          .then(function(response) {
            categoriesData.push(response.data);
            if (categoriesData.length == tags.length) {
              setLoad(true);
            }
          })
          .catch(function(error) {
            //console.log(error);
          });
      }
    }

    setTimeout(function(){
      bodyLock = document.getElementsByTagName('body')[0];
      bodyLock.style.overflow = '';
      bodyLock.style.position = '';
      bodyLock.style.top = '';
      bodyLock.style.width = '';
      bodyLock.style.paddingRight = "";
    }, 20);

  }, []);

  function handleMove(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor');
      /*console.log(e.target)*/
      if (e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'button' || e.target.tagName.toLowerCase() === 'input') {       cursor.classList.add("large-cirlce")
      }else{
        cursor.classList.remove("large-cirlce")
      }
    }
  }
  function handleLeave(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce")
    }
  }

    if (load) {
      return(
          <section className={`singleintro-section ${clientName ? "has-topname" : "no-topname"} ${backgroundClass ? backgroundClass : ""} ${newsArticle ? "news-intro" : ""} ${ introText || categoriesData ? "" : "only-title"}`}
                   onMouseEnter={handleMove}
                   onMouseMove={handleMove}
                   onMouseLeave={handleLeave}
          >
            <div className="container">
              <div className="row">
                <div className={`col-xl-10 col-lg-11 col-md-11`}>
                    {clientName &&
                      <p className="p-big client-name">
                        {clientName}
                      </p>
                    }

                    <h1 className="large-title title" dangerouslySetInnerHTML={{ __html: title }}  />
                    {introText &&
                      <h2 className="medium-title intro-text" dangerouslySetInnerHTML={{ __html: introText }}  />
                    }
                    {categoriesData &&
                        <ul className="tags-holder">
                        {categoriesData.map((item, index) =>(
                          <li key={item.id}><Link className={`button small-btn`} to={'/'+taxonomyUrl+'/'+item.slug}>{item.name}</Link></li>
                        ))}
                        </ul>
                    }
                </div>
              </div>
            </div>
          </section>
      )
    } else {
      return (
            <section className={`singleintro-section ${clientName ? "has-topname" : "no-topname"} ${backgroundClass ? backgroundClass : ""} ${newsArticle ? "news-intro" : ""} ${ introText ? "" : "only-title"}`}
                     onMouseEnter={handleMove}
                     onMouseMove={handleMove}
                     onMouseLeave={handleLeave}
            >
              <div className="container">
                <div className="row">
                  <div className={`col-xl-10 col-lg-11 col-md-11`}>
                      {clientName &&
                        <p className="p-big client-name">
                          {clientName}
                        </p>
                      }

                      <h1 className="large-title title" dangerouslySetInnerHTML={{ __html: title }}  />
                      {introText &&
                        <h2 className="medium-title intro-text" dangerouslySetInnerHTML={{ __html: introText }}  />
                      }
                  </div>
                </div>
              </div>
            </section>
      )
    }
}
