import React from "react"
import { Link } from 'react-router-dom'
import ProgressiveImage from 'react-progressive-graceful-image';


import background from "./../../images/work1.png"

import "./../styles/sidedcta.scss";

const posts = [
  { id: 1, url: "#", background: background, backgroud_color:"background-blue", button_text: "Join Us"},
  { id: 2, url: "#", background: background, backgroud_color:"background-yellow",  button_text: "New Project"},
]
function SidedCTA({ data, backgroundClass }) {


  function handleMove(e) {
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
  }
  function handleLeave(e) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce");
  }

    return(
        <section className={`sidedcta-section ${backgroundClass ? backgroundClass : ""}`}>
          <div className="container">
            <div className="row">

              {posts.map((item, index) =>
                <div key={index} className="col-md-6 item">
                  <Link to={item.button_url}
                        onMouseEnter={handleMove}
                        onMouseMove={handleMove}
                        onMouseLeave={handleLeave}
                      >
                      <div className="image-wrapper">
                        <div className={`image-item ${item.backgroud_color}`}>
                            <div className="lazy-holder">
                              <ProgressiveImage
                                  className="main-image"
                                  delay={500}
                                  src={item.background}
                                  srcSetData={{
                                    srcSet: `${item.background} 320w, ${item.background} 700w, ${item.background} 2000w`,
                                    sizes: '(max-width: 2000px) 100vw, 2000px'
                                  }}
                                  placeholder={item.background}
                              >
                                {(src, loading, srcSetData, placeholder) => (
                                      <img style={{ opacity: loading ? 0 : 1, transition: "0.3s ease" }}
                                        src={src}
                                        srcSet={srcSetData.srcSet}
                                        sizes={srcSetData.sizes}
                                        alt="an image"
                                      />
                                  )}
                              </ProgressiveImage>
                            </div>
                        </div>


                        <div className="button-wrapper">
                            <button className="button big-btn white-btn">{item.button_text}</button>
                        </div>
                      </div>

                    </Link>
                </div>
              )}

            </div>
          </div>
        </section>
    )
}

export default SidedCTA
