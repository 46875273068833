import React, {useState, useRef, useeEffect} from "react"
import ReactPlayer from 'react-player/lazy'
import "./../styles/videocomp.scss";

export default function ShapeItemVideo({video, video2, type, backgroundClass}){
  const [on, setOn] = useState(false);
  const [on2, setOn2] = useState(false);
  //const [ready, setReady] = useState(false);
  //console.log('shape', shape)
  //console.log('hoveredShape', hoveredShape)

  ///function handleReady(){
    //setReady(true);
 // }

  const videoWrapper = useRef(null)

  React.useEffect(() => {
    const { current: videoWrap } = videoWrapper

    let video_ = videoWrap.querySelector('iframe');
    // console.log('videoWrap', videoWrap)
    // console.log(video_)
  })

 /*function bindIFrameMousemove(iframe){
    iframe.contentWindow.addEventListener('mousemove', function(event) {
        var clRect = iframe.getBoundingClientRect();
        var evt = new CustomEvent('mousemove', {bubbles: true, cancelable: false});

        evt.clientX = event.clientX + clRect.left;
        evt.clientY = event.clientY + clRect.top;

        //console.log(evt);
        iframe.dispatchEvent(evt);
    });
};

bindIFrameMousemove(document.getElementById('iFrameId'));*/

  return(

      <section className={`video-section ${backgroundClass ? backgroundClass : ""} ${type==="full" ? "full-video" : ""} ${type==="medium" ? "medium-video" : ""} ${type==="sided" ? "sided-video" : ""}`}>
        <div className={` ${type==="full" ? "container-fluide no-padding" : ""} ${type==="medium" || type==="sided" ? "container" : ""} `}>
          <div className="row">
            <div className={`item ${type==="full" || type==="medium" ? "col-12" : ""} ${ type==="sided" ? "col-md-6" : ""} `}>
                <div className="player-wrapper" ref={videoWrapper}>
                  <ReactPlayer className='react-player'
                    width='100%'
                    height='100%'
                    url={video} controls={false}
                    playing={on}
                    config={{
                      youtube: {
                        playerVars: { showinfo: 0 }
                      },
                    }}
                  />
                  <div className="video-overlay" onClick={event => {setOn(!on)}}/>
                </div>
            </div>
            {video2 ?
              <div className="item col-md-6">
                  <div className="player-wrapper">
                    <ReactPlayer className='react-player'
                      width='100%'
                      height='100%'
                      url={video2} controls={false}
                      playing={on2}
                      config={{
                        youtube: {
                          playerVars: { showinfo: 0 }
                        },
                      }}
                    />
                    <div className="video-overlay" onClick={event => {setOn2(!on2)}}/>
                  </div>
                </div>
              :
              ``
            }
          </div>
        </div>
      </section>

  )
}
