import React from "react"

import "./styles/cursor.scss";

function Cursor() {
    
    return (
    	<div className="cursor-wrapper ">
		      <div className="cursor">
		      		<div className="circle">
		      			<span className="span-left" />
		      			<span className="span-right" />
		      		</div>
		      </div>
        </div>
    );

}



export default Cursor
