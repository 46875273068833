import React, { Component } from 'react';
import PortfolioItem from './PortfolioItem';
import axios from 'axios';

export default class Portfolios extends Component {
  state = {
    portfolio: [],
    isLoaded: false
  }

  componentDidMount(){
    axios.get('/wp-json/wp/v2/projects')
    .then(res => this.setState({
      portfolios : res.data,
      isLoaded: true
    }))
    .catch(err=> console.log(err));
  }
  render() {

    const { portfolios, isLoaded } = this.state;

    if (isLoaded) {
      return (
        <div>
          { portfolios.map(portfolio => (
            <PortfolioItem key={portfolio.id} portfolio={portfolio} />
          )) }
        </div>
      );
    }
    return <h3>Loading...</h3>
  }
}
