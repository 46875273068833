import React, { useState } from "react"
import { Link } from 'react-router-dom'

import "./styles/biglist.scss";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";


function BigList({ data, backgroundClass }) {


  let List, small_title, intro_text;
  List = data.lists;
  small_title = data.small_title;
  intro_text = data.intro_text;

  if (small_title) {
    small_title = small_title.replace(/(<([^>]+)>)/ig,"");
  }

  if (intro_text) {
    intro_text = intro_text.replace(/(<([^>]+)>)/ig,"");
  }

  const [activeIndex, setActiveIndex] = useState(-1);

  function handleMove(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
    }

  }
  function handleLeave(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce");
    }
  }


  return(
      <section className={`biglist-section ${backgroundClass ? backgroundClass : ""}`}>
        <div className="container">
          <div className="row ">
            <div className="col-md-12 main-titles">
                {small_title &&
                  <p className="p-big"> {small_title} </p>
                }
                {intro_text &&
                  <h3 className="big-title title">
                    {intro_text}
                  </h3>
                }
            </div>

            <div className={`col-12 list ${ activeIndex === -1 ? '': 'hovering'}`}>
                {List.map((item, index) =>(
                  
                    <div
                      className={`item ${ activeIndex === index ? 'hover': ''}`}
                      key={index}
                      onMouseEnter={event => {setActiveIndex(activeIndex === index ? -1 : index)}}
                      onMouseLeave={event => {setActiveIndex(activeIndex === index ? -1 : index)}}
                    >
                    {item.external_link &&
                      <a target="_blank" href={item.link_url}
                      onMouseEnter={handleMove}
                      onMouseMove={handleMove}
                      onMouseLeave={handleLeave}>
                        <div className="icon-wrap">
                          <span className={`icon ${item.icon}`}></span>
                        </div>
                        <h4 className="x-large-title item-title">
                          {item.title}
                        </h4>
                      </a>
                    }
                    {!item.external_link &&
                      <Link to={item.link_url}
                            onMouseEnter={handleMove}
                            onMouseMove={handleMove}
                            onMouseLeave={handleLeave}
                      >
                          <div className="icon-wrap">
                            <span className={`icon ${item.icon}`}></span>
                          </div>
                          <h4 className="x-large-title item-title">
                            {item.title}
                          </h4>
                      </Link>
                    }
                    </div>
                
                )
                )}


            </div>
          </div>
        </div>
      </section>
  )


}

export default BigList
