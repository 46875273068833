import React, { useState, useEffect, useRef } from 'react';

import useInterval from "./../hooks/useinterval";
import Slider from "react-slick";


import "./../styles/logoslider.scss";
import img1 from "./../../images/logos/madeo-logo-Abercrombie-Kent-1.png"
import img2 from "./../../images/logos/madeo-logo-ashoka-1.png"
import img3 from "./../../images/logos/madeo-logo-care-1.png"
import img4 from "./../../images/logos/madeo-logo-conversocial-1.png"
import img5 from "./../../images/logos/madeo-logo-Durst-Organization-1.png"
import img6 from "./../../images/logos/madeo-logo-echoing-green-1.png"
import img7 from "./../../images/logos/madeo-logo-eji-1.png"
import img8 from "./../../images/logos/madeo-logo-havenlife-1.png"
import img9 from "./../../images/logos/madeo-logo-hbfit-1.png"
import img10 from "./../../images/logos/madeo-logo-innocence-project-1.png"
import img11 from "./../../images/logos/madeo-logo-lendkey-1.png"
import img12 from "./../../images/logos/madeo-logo-lewis-1.png"
import img13 from "./../../images/logos/madeo-logo-mit-1.png"
import img14 from "./../../images/logos/madeo-logo-nba-1.png"
import img15 from "./../../images/logos/madeo-logo-seagate-1.png"
import img16 from "./../../images/logos/madeo-logo-seeds-of-peace-1.png"
import img17 from "./../../images/logos/madeo-logo-trillium-1.png"
import img18 from "./../../images/logos/madeo-logo-usip-1.png"
import img19 from "./../../images/logos/madeo-logo-zkipster-1.png"

let images = [
  { id: 0, url: img1},
  { id: 1, url: img2},
  { id: 2, url: img3},
  { id: 3, url: img4},
  { id: 4, url: img5},
  { id: 5, url: img6},
  { id: 6, url: img7},
  { id: 7, url: img8},
  { id: 8, url: img9},
  { id: 9, url: img10},
  { id: 10, url: img11},
  { id: 11, url: img12},
  { id: 12, url: img13},
  { id: 13, url: img14},
  { id: 14, url: img15},
  { id: 15, url: img16},
  { id: 16, url: img17},

]

function LogoSlider({ data, backgroundClass, haveTitle, two_rows }) {
    const [counter, setCounter] = useState(4);
    const [counter2, setCounter2] = useState(8);

    const [x, setX] = useState(1)
    const [oldX, setoldX] = useState(1)
    const [oldX2, setoldX2] = useState(1)


    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const ref4 = useRef(null)
    const ref5 = useRef(null)
    const ref6 = useRef(null)
    const ref7 = useRef(null)
    const ref8 = useRef(null)

    /// =====================
    let delay = 2000;

    // this in ms
    let delaybetweenImages = 0;
    ///// ==========================

    let delayBasic = 0;
    let imagesDelay = delayBasic + delaybetweenImages;

    useEffect(() => {
      const { current: item1 } = ref1
      const { current: item2 } = ref2
      const { current: item3 } = ref3
      const { current: item4 } = ref4
      const { current: item5 } = ref5
      const { current: item6 } = ref6
      const { current: item7 } = ref7
      const { current: item8 } = ref8

      let image1 = item1.querySelector('img')
      let image2 = item2.querySelector('img')
      let image3 = item3.querySelector('img')
      let image4 = item4.querySelector('img')
      let image5 = item5.querySelector('img')
      let image6 = item6.querySelector('img')
      let image7 = item7.querySelector('img')
      let image8 = item8.querySelector('img')

      let size = images.length - 1
      let imgSrc = ''

      const interval = setInterval(() => {

        
        if( two_rows === "true" ){
          if( (counter2 + 1) > size){
            setCounter2(0)
          }else{
            setCounter2(counter2 + 1)
          }

          imgSrc = images[counter2].url

            if(x === 1){
                item1.classList.add("out")
                item1.classList.remove("in")
                setTimeout(function(){
                    image1.src = imgSrc
                }, delayBasic);
                
                setTimeout(function(){
                    item1.classList.remove("out")
                    item1.classList.add("in")
                }, imagesDelay);


                setX(3);
                //setX(generateRandom_position(2, 8));
            }
            else if(x === 2){
              item2.classList.add("out")
                item2.classList.remove("in")
                setTimeout(function(){
                    image2.src = imgSrc
                }, delayBasic);

                setTimeout(function(){
                    item2.classList.remove("out")
                    item2.classList.add("in")
                }, imagesDelay);
                setX(7);
                //setX(generateRandom_position(3, 8));
            }
            else if(x === 3){
              item3.classList.add("out")
                item3.classList.remove("in")
                setTimeout(function(){
                    image3.src = imgSrc
                }, delayBasic);

                setTimeout(function(){
                    item3.classList.remove("out")
                    item3.classList.add("in")
                }, imagesDelay);
                setX(8)
                //setX(generateRandom_position(4, 8))
            }
            else if(x === 4){
                item4.classList.add("out")
                item4.classList.remove("in")
                setTimeout(function(){
                    image4.src = imgSrc
                }, delayBasic);

                setTimeout(function(){
                    item4.classList.remove("out")
                    item4.classList.add("in")
                }, imagesDelay);
                setX(1)
                //setX(generateRandom_position(5, 8))
            }
            else if(x === 5){
                item5.classList.add("out")
                item5.classList.remove("in")
                setTimeout(function(){
                    image5.src = imgSrc
                }, delayBasic);
                
                setTimeout(function(){
                    item5.classList.remove("out")
                    item5.classList.add("in")
                }, imagesDelay);


                setX(2);
                //setX(generateRandom_position(1, 4));
            }
            else if(x === 6){
              item6.classList.add("out")
                item6.classList.remove("in")
                setTimeout(function(){
                    image6.src = imgSrc
                }, delayBasic);

                setTimeout(function(){
                    item6.classList.remove("out")
                    item6.classList.add("in")
                }, imagesDelay);
                setX(4);
                //setX(generateRandom_position(1, 5));
            }
            else if(x === 7){
              item7.classList.add("out")
                item7.classList.remove("in")
                setTimeout(function(){
                    image7.src = imgSrc
                }, delayBasic);

                setTimeout(function(){
                    item7.classList.remove("out")
                    item7.classList.add("in")
                }, imagesDelay);
                setX(6)
                //setX(generateRandom_position(1, 6))
            }
            else if(x === 8){
              item8.classList.add("out")
                item8.classList.remove("in")
                setTimeout(function(){
                    image8.src = imgSrc
                }, delayBasic);

                setTimeout(function(){
                    item8.classList.remove("out")
                    item8.classList.add("in")
                }, imagesDelay);
                setX(5)
                //setX(generateRandom_position(1, 7))
            }
        }else{
          if( (counter + 1) > size){
            setCounter(0)
          }else{
            setCounter(counter + 1)
          }

          imgSrc = images[counter].url

            if(x === 1){
                item1.classList.add("out")
                item1.classList.remove("in")
                setTimeout(function(){
                    image1.src = imgSrc
                }, delayBasic);
                
                setTimeout(function(){
                    item1.classList.remove("out")
                    item1.classList.add("in")
                }, imagesDelay);


                setX(3);
                //setX(generateRandom_position(2, 4));
            }
            else if(x === 2){
              item2.classList.add("out")
                item2.classList.remove("in")
                setTimeout(function(){
                    image2.src = imgSrc
                }, delayBasic);

                setTimeout(function(){
                    item2.classList.remove("out")
                    item2.classList.add("in")
                }, imagesDelay);
                setX(4);
                //setX(generateRandom_position(3, 4));
            }
            else if(x === 3){
              item3.classList.add("out")
                item3.classList.remove("in")
                setTimeout(function(){
                    image3.src = imgSrc
                }, delayBasic);

                setTimeout(function(){
                    item3.classList.remove("out")
                    item3.classList.add("in")
                }, imagesDelay);
                setX(2)
                //setX(generateRandom_position(1, 2))
            }
            else if(x === 4){
              item4.classList.add("out")
                item4.classList.remove("in")
                setTimeout(function(){
                    image4.src = imgSrc
                }, delayBasic);

                setTimeout(function(){
                    item4.classList.remove("out")
                    item4.classList.add("in")
                }, imagesDelay);
                setX(1)
                //setX(generateRandom_position(1, 3))
            }
        }

        /*if(x === 1){
            item1.classList.add("out")
            item1.classList.remove("in")
            setTimeout(function(){
                image1.src = imgSrc
            }, delayBasic);
            
            setTimeout(function(){
                item1.classList.remove("out")
                item1.classList.add("in")
            }, imagesDelay);

            setX(generateRandom_position(2, 4));
        }
        else if(x === 2){
          item2.classList.add("out")
            item2.classList.remove("in")
            setTimeout(function(){
                image2.src = imgSrc
            }, delayBasic);

            setTimeout(function(){
                item2.classList.remove("out")
                item2.classList.add("in")
            }, imagesDelay);
            setX(generateRandom_position(3, 4));
        }
        else if(x === 3){
          item3.classList.add("out")
            item3.classList.remove("in")
            setTimeout(function(){
                image3.src = imgSrc
            }, delayBasic);

            setTimeout(function(){
                item3.classList.remove("out")
                item3.classList.add("in")
            }, imagesDelay);
            setX(generateRandom_position(1, 2))
        }
        else if(x === 4){
          item4.classList.add("out")
            item4.classList.remove("in")
            setTimeout(function(){
                image4.src = imgSrc
            }, delayBasic);

            setTimeout(function(){
                item4.classList.remove("out")
                item4.classList.add("in")
            }, imagesDelay);
            setX(generateRandom_position(1, 3))
        }*/


      }, delay);


      return () => clearInterval(interval);
    });




    function generateRandom_position(min, max) {
        let rtn = Math.floor(Math.random() * (max - min + 1)) + min;
        //let rtn = Random.Range(min, max)
        //return (rtn === num1 || rtn === num2) ? generateRandom_position(min, max, num1) : rtn;
        return rtn;


    }


    return (
      <section className={`logoslider-section ${haveTitle ? 'have-title' : ''} ${backgroundClass ? backgroundClass : ""}`}>
        {haveTitle ?
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-12">
                <h3 className="section-title medium-title">
                  Aenean sed elit suscipit, luctus sapien eget, cursus elit. 
                    Fusce vel tellus ligula. Praesent pretium vulputate urna a venenatis. Duis placerat felis lorem, at convallis lectus semper quis. 
                    Etiam fermentum metus id commodo aliquet. Cras nibh tellus, blandit ut rutrum eu, molestie non mi.
                </h3>
              </div>
            </div>
          </div>
          :
          ``
        }
        <div className="container">
              <div className={`logos-row row`}>
                <div className="col-md-3 col-6 item" ref={ref1} key={0}>
                    <img className={`logo-- logo-0`} src={images[0].url} alt="logo 1" />
                </div>
                <div className="col-md-3 col-6 item" ref={ref2}  key={1}>
                    <img className={`logo-- logo-1`} src={images[1].url} alt="logo 2" />
                </div>
                <div className="col-md-3 col-6 item" ref={ref3}  key={2}>
                    <img className={`logo-- logo-2`} src={images[2].url} alt="logo 3" />
                </div>
                  <div className="col-md-3 col-6 item" ref={ref4}  key={3}>
                    <img className={`logo-- logo-3`} src={images[3].url} alt="logo 4" />
              </div>
              </div>
              {two_rows ?
                <div className={`logos-row logos-row-2 row`}>
                  <div className="col-md-3 col-6 item" ref={ref5} key={4}>
                      <img className={`logo-- logo-4`} src={images[4].url} alt="logo 1" />
                  </div>
                  <div className="col-md-3 col-6 item" ref={ref6}  key={5}>
                      <img className={`logo-- logo-5`} src={images[5].url} alt="logo 2" />
                  </div>
                  <div className="col-md-3 col-6 item" ref={ref7}  key={6}>
                      <img className={`logo-- logo-6`} src={images[6].url} alt="logo 3" />
                  </div>
                    <div className="col-md-3 col-6 item" ref={ref8}  key={7}>
                      <img className={`logo-- logo-7`} src={images[7].url} alt="logo 4" />
                  </div>
                </div>
                :
                <div className="d-none">
                  <div className="col-md-3 col-6 item" ref={ref5} key={4}>
                      <img className={`logo-- logo-4`} src={images[4].url} alt="logo 1" />
                  </div>
                  <div className="col-md-3 col-6 item" ref={ref6}  key={5}>
                      <img className={`logo-- logo-5`} src={images[5].url} alt="logo 2" />
                  </div>
                  <div className="col-md-3 col-6 item" ref={ref7}  key={6}>
                      <img className={`logo-- logo-6`} src={images[6].url} alt="logo 3" />
                  </div>
                    <div className="col-md-3 col-6 item" ref={ref8}  key={7}>
                      <img className={`logo-- logo-7`} src={images[7].url} alt="logo 4" />
                  </div>
                </div>
              }
        </div>
    </section>
    );

}


export default LogoSlider
