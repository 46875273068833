import React, { Fragment, useState, useEffect } from "react"
/*import useDelayRouteExit from "delay-react-route-exit"
import history from '../history';*/
import axios from 'axios';


export default function Transition() {

  const [goingToUrl, setgoingToUrl] = useState([]);
  const [goingToBackground, setgoingToBackground] = useState();
  const [bgPallete, setbgPallete] = useState('');
  const [load, setLoad] = useState(false);
  const [error, setError] = useState('');
  const [animStart, setAnimStart] =  useState(false);
  /*const [on, historyPop] = useState(true);
  const delayTime = on ? 1400 : 0;*/
  let transitionClick;
  function transitionTrigger(){
    transitionClick = setTimeout( function(){
      // console.log("load");
      let el = document.getElementsByTagName('a'), currentHref, currentPath, currentPage;
      for (let i=0; i < el.length; i++) {
          el.item(i).onclick = function(event){
          if (!event.ctrlKey && !event.metaKey) {
            currentHref = ""+this.href;
            currentPath = ""+window.location.pathname;
            // console.log("click count");
            if(currentHref.substring(currentHref.lastIndexOf("/") + 1) !== "#" && this.getAttribute("target") !== "_blank" && this.getAttribute("target") !== "_self"){
              if((currentPath.substring(currentPath.lastIndexOf("/") + 1)).length > 1) {
                currentPage = currentPath.substring(currentPath.lastIndexOf("/") + 1);
              } else {
                currentPage = currentPath.split("/");
                currentPage = currentPage[ parseInt(currentPage.length - 2, 10)];
              }
              if(currentHref.substring(currentHref.lastIndexOf("/") + 1) !== currentPage && !(document.body.classList.contains('page-work') && !document.body.classList.contains('taxonomy-page') && currentHref.includes("work")) 
                && !(document.body.classList.contains('page-news') && currentHref.includes("news"))){
                // || currentHref.includes("about-us") || currentHref.includes("about") || currentHref.includes("work") || currentHref.includes("news") || currentHref.includes("careers") || currentHref.includes("contact") )
                //if((this.id == "logo") && !this.classList.contains('load-more') && !this.classList.contains('filterbtn') && currentPage !== "#"){
                if( !this.classList.contains('load-more') && !this.classList.contains('filterbtn') && currentPage !== "#"){
                  let going_to_url = currentHref.substring(currentHref.lastIndexOf("/") + 1);
                  /*switch (going_to_url) {
                    case 'news':
                      setbgPallete('palette-news');
                      break;
                    case '':
                      setbgPallete('palette-homepage');
                      break;
                    case 'about':
                      setbgPallete('palette-about');
                      break;
                    case 'about-us':
                      setbgPallete('palette-about');
                      break;
                    case 'work':
                      setbgPallete('palette-work');
                      break;
                    case 'careers':
                      setbgPallete('palette-careers');
                      break;
                    case 'contact':
                      setbgPallete('palette-contact');
                      break;
                    default:
                  }
                  switch (going_to_url) {
                    case 'newspage':
                      going_to_url = 'news'
                      break;
                    case '':
                      going_to_url = 'homepage'
                      break;
                    default:
                  }*/

                  if(going_to_url == ''){
                    going_to_url = 'homepage';
                  }
                  axios.all([
                    axios.get(`/wp-json/wp/v2/pages?slug=${going_to_url}`),
                  ])
                  .then(res => {
                    let pageDate = res[0].data[0].acf;
                    // console.log('data from trans', pageDate);
                    let pageTemplate = res[0].data[0].template;
                    switch(pageTemplate){
                      case 'template-about-us.php':{
                        setbgPallete('palette-about');
                                        break;
                                      }
                                      case 'template-contact.php':{
                                        setbgPallete('palette-contact');
                                          break;
                                        }
                                        case 'template-homepage.php':{
                                          setbgPallete('palette-homepage');
                                          break;
                                        }
                                        case 'template-careers.php':{
                                          setbgPallete('palette-careers');
                                          break;
                                        }
                                        case 'template-work.php':{
                                          setbgPallete('palette-work');
                                          break;
                                        }
                                        case 'template-news.php':{
                                          setbgPallete('palette-news');
                                          break;
                                        }
                                        case 'template-faq.php':{
                                          setbgPallete('palette-faq');
                                          break;
                                        }
                  }
                    setgoingToBackground(res[0].data[0].acf.background_color);
                    setLoad(true);
                    setAnimStart(true)
                    // console.log(res[0].data[0].acf.background_color);
                  })
                  .catch(err => {
                    setError(err.message);
                    setLoad(true)
                    setAnimStart(true)
                  })
                }
              } else {
                return false;
              }
            }
          }
          };
      }
    }, 1000);
  }

function clearClasses(startWith){
  var startsWith = startWith;
  var classes = document.getElementById('page-transition-inner').className.split(" ").filter(function(v) {
      return v.lastIndexOf(startsWith, 0) !== 0;
  });
  document.getElementById('page-transition-inner').className = classes.join(" ").trim();
}

useEffect(() => {
  // console.log('useEffect');
    setTimeout( function(){
      clearTimeout(transitionClick);
      transitionTrigger();
      if(document.getElementById('page-transition').classList.contains("transition-animate-reset")){
        document.getElementById('page-transition').classList.add("transition-pole-start");
        document.getElementById("wrapper").classList.add("page-animation-start");
        setTimeout(function(){
          document.getElementById("logo").classList.remove("title-view");
          document.getElementById('page-transition').classList.remove("transition-animate");
          document.getElementById("wrapper").classList.remove("page-animation-start");
          setTimeout(function(){
            document.getElementById('page-transition').classList.remove("transition-animate-reset");
          }, 100);
        }, 400);
      }
      // console.log("transition clases");
    }, 10);
    /*window.onpopstate = e => {
      historyPop(!on);
   }*/
  }, []);

  // delay used to wait for background from CMS
  /*const isExitingRoute = useDelayRouteExit(1400,  () => {
    //document.getElementById('page-transition').classList.add("transition-animate-reset", "transition-animate");
  });*/
  // console.log("test + " +goingToBackground);
  if (load && goingToBackground !== undefined && bgPallete != '') {
      clearClasses('palette-');
      clearClasses('background-');
      //document.getElementById('page-transition-inner').classList.remove("background-yellow", "background-beige");
      document.getElementById('page-transition-inner').classList.add(goingToBackground);
      //if(bgPallete){
        document.getElementById('page-transition-inner').classList.add(bgPallete);
      //}
      if(animStart ){
        document.getElementById('page-transition').classList.add("transition-animate-reset", "transition-animate");
      }
  }

  return (
      <>
      </>
  );
}
