import React, { useState } from "react"
import { Link } from 'react-router-dom'
import AccordionItem from './accordionitem';

import "./../styles/faq.scss";


const items = [
  { index: 0, title: `Lorem ipsum dolor sit amet.`, 
    desc: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor 
    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
    quis nostrud exercitation ullamco laboris nisi ut aliquip.`,
    linkText: "Join Us", linkUri: "/"
  },
  { index: 1, title: `Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore?`, 
    desc: `22 Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor 
    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
    quis nostrud exercitation ullamco laboris nisi ut aliquip.`,
    linkText: "Join Us 2", linkUri: "/"
  },
  { index: 2, title: `Quis nostrud exercitation ullamco.`, 
    desc: `33 Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor 
    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
    quis nostrud exercitation ullamco laboris nisi ut aliquip.`,
    linkText: "Join Us 3", linkUri: "/"
  },
  { index: 3, title: `Ut enim ad minim veniam quis nostrud exercitation.`, 
    desc: `44 Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor 
    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
    quis nostrud exercitation ullamco laboris nisi ut aliquip.`,
    linkText: "Join Us 3", linkUri: "/"
  },
]

export default function FAQ({ data, title, backgroundClass }) {
  const [activeIndex, setActiveIndex] = useState(-1);


  function handleMove(e) {
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
  }
  function handleLeave(e) {
    const cursor = document.querySelector('.cursor')
    cursor.classList.remove("large-cirlce")
  }

  let index = 1;

  return (
    <section className={`faq-section ${backgroundClass ? backgroundClass : ""}`} id="faq_accordion">
        <div className="container">
          <div className="row">
            <div className="col-12">
            <h1 className="x-large-title main-title" dangerouslySetInnerHTML={{ __html: title }}  />
            </div>
            <div className="col-12">
              { items.map((item, index) => (
                <div className={`item ${activeIndex === index ? 'active': ''}` } key={index}>
                      <button className={`big-title item-toggler ${backgroundClass} ${activeIndex === index ? 'active': ''}` }
                      onClick={event => {
                              setActiveIndex(
                              activeIndex === index ? -1 : index
                              )
                              //console.log(activeIndex)
                            }}
                      onMouseEnter={handleMove}
                      onMouseMove={handleMove}
                      onMouseLeave={handleLeave}
                      >
                          {item.title}
                          <span className="plus-icon" />
                      </button>
                      <AccordionItem isOpened={activeIndex === index}>
                            <p className="p-big desc">
                              {item.desc}
                            </p>
                            {item.linkText && item.linkUri ? 
                              <div className="cta-holder">
                                <a className="button medium-btn blue-btn" href={item.linkUri}
                                   onMouseEnter={handleMove} 
                                   onMouseMove={handleMove} 
                                   onMouseLeave={handleLeave}
                                >
                                  {item.linkText}
                                </a>
                              </div>
                              :
                              ''
                            }
                        </AccordionItem>
                  </div>
              ))}
            </div>
          </div>
        </div>
    </section>
  );
};

//export default Accordion
