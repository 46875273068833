import React from "react"
import { Link } from 'react-router-dom'
import "./../styles/contactinfo.scss";

function ContactInfo({ data, backgroundClass }) {


  function handleMove(e) {
      const cursor = document.querySelector('.cursor');
      if (e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'button' || e.target.tagName.toLowerCase() === 'input') {       cursor.classList.add("large-cirlce")
      }else{
        cursor.classList.remove("large-cirlce")
      }
  }
  function handleLeave(e) {
    const cursor = document.querySelector('.cursor')
    cursor.classList.remove("large-cirlce")
  }
  return(
      <section className={`contactinfo-section ${backgroundClass ? backgroundClass : ""}`}
               onMouseEnter={handleMove}
               onMouseMove={handleMove}
               onMouseLeave={handleLeave}
      >
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-lg-6 col-md-8 col-12">
                <h3 className="big-title">
                  20 Jay Street, Suite 500 <br />
                  Brooklyn, New York <br />
                  <a href="mailto:hi@madeomedia.com">hi@madeomedia.com</a>
                </h3>

                <h4 className="medium-title">
                    Have questions? <br />
                    <Link to="#"> See our FAQ Page </Link>
                </h4>
            </div>
          </div>
        </div>
      </section>
  )


}

export default ContactInfo
