import React, {useState, useEffect, useRef} from "react";
import Slider from "react-slick";
import './../styles/shapes.scss'

import ReactPlayer from 'react-player'


import image1 from "./../ui/shapes/pngs/shape1.png"
import image2 from "./../ui/shapes/pngs/shape2.png"
import image3 from "./../ui/shapes/pngs/shape3.png"
import image4 from "./../ui/shapes/pngs/shape4.png"
import image5 from "./../ui/shapes/pngs/shape5.png"
import image6 from "./../ui/shapes/pngs/shape6.png"


const slidesWithVideo = [
  { id: 0, image: image1, video: "https://madeomedia.wistia.com/medias/yxvyuwmxyl"},
  { id: 1, image: image2, video: "https://madeomedia.wistia.com/medias/uwp2xexb92"},
  { id: 2, image: image3, video: "https://madeomedia.wistia.com/medias/qy4zoh95c4"},
  { id: 3, image: image4, video: "https://madeomedia.wistia.com/medias/s210b0rrau"},
  { id: 4, image: image5, video: "https://madeomedia.wistia.com/medias/k4y6n8kxa5"},
  { id: 5, image: image6, video: "https://madeomedia.wistia.com/medias/5tuzssuums"},

]


function ShapeItemVideo(shape, video){
  const [on, setHover] = useState(false);
  const [ready, setReady] = useState(false);
  const [count, setCount] = useState(0);
  function handleHover(){
    if(ready){
      setHover(true);
    }
    setCount(count + 1);
  }
  function handleLeave(){
    setHover(false);
  }

  function handleReady(){
    setReady(true);
    setHover(false)

  }
  /*useEffect(() => {
      document.querySelector(window).wistiaIframeShim = false
  })*/

  return(
    <div className="col-4 shape-item" onMouseMove={handleHover} onMouseLeave={handleLeave} style={{overflow: 'hidden', paddingLeft: "0px", paddingRight: "0px"}}>
      <div className={`poster-img ${count > 0 ? 'hide' : ''} `} style={{ backgroundImage: `url(${image6})`}} />
      <div className="player-wrapper">
        <ReactPlayer className='react-player'
          width='100%'
          height='100%'
          url="https://madeomedia.wistia.com/medias/5tuzssuums" playing={on} loop="true" muted onReady={ count === 0 ? handleReady : "" } controls={false}  />
      </div>
    </div>
  )
}

export default function Shapes(shapesData) {


      return (

        <section className={`shapes-section background-orange`} >

          <div className="container-fluid" style={{paddingLeft: "0px", paddingRight: "0px"}}>
            <div className="row" style={{marginLeft: "0px", marginRight: "0px"}}>
              {
                console.log(slidesWithVideo)
              }
                {slidesWithVideo.map((item, i) => {
                    return (
                        <ShapeItemVideo  key={i} shape={item.image} video={item.video} />
                    )
                })}
            </div>
          </div>
        </section>
      );


}
