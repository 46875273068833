import React from "react"
import { Link } from 'react-router-dom'
import { Picture } from 'react-responsive-picture'

import "./../styles/paragraph.scss";

export default function Paragraph({ data, backgroundClass, categoriesList}) {
  function handleMove(e) {
      const cursor = document.querySelector('.cursor');
      /*console.log(e.target)*/
      if (e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'button' || e.target.tagName.toLowerCase() === 'input') {       cursor.classList.add("large-cirlce")
      }else{
        cursor.classList.remove("large-cirlce")
      }
  }
  function handleLeave(e) {
    const cursor = document.querySelector('.cursor')
    cursor.classList.remove("large-cirlce")
  }
  return(
      <section className={`paragraph-section ${backgroundClass ? backgroundClass : ""} `}
               onMouseEnter={handleMove} 
               onMouseMove={handleMove} 
               onMouseLeave={handleLeave}
      >
        <div className={` container `}>
          <div className="row">
            <div className={` ${categoriesList ? "col-lg-8 col-md-9" : "col-xl-8 col-md-10"} `}>
                <div dangerouslySetInnerHTML={{ __html: data }} />   
            </div>
            {categoriesList ? 
              <div className={` col-lg-3 col-md-3 offset-lg-1 `}>
                  <div className="category-list-wrapper">
                    <ul className="cat-list">
                      {categoriesList.map((item, i) => (
                          <li key={i}>
                              {
                                item.category
                              }
                          </li>
                      ))}
                    </ul>
                  </div>
              </div>
              :
              ""
            }
          </div>
        </div>
      </section>
  )
  


}

