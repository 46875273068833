import React,{ useState, useEffect } from "react"

//import Layout from "../components/layout"
import SEO from "../components/seo"

import TopSection from "../components/topsection"
import WorkNews from "../components/worknews"
import Shapes from "../components/shapes"
import { Helmet } from 'react-helmet'

import axios from 'axios'

export default function  Work(props) {
    const catSlug = props.match.params.slug;
    const [catData, setCatData] = useState([]);
    const [workData, setWorkData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');


    useEffect(() => {

        axios.all([
        axios.get('/wp-json/wp/v2/client/?slug='+catSlug)
        ])
            .then(res => {
                setCatData(res[0].data[0]);
                let catId = res[0].data[0].id;
                axios.get('/wp-json/wp/v2/projects?hide_password=true&orderby=menu_order&order=asc&offset=0&per_page=100&set_as_client_page=no&client='+catId).then(result => {
                    setWorkData(result);
                    setLoad(true);
                })

            })
            .catch(err => {
                setError(err.message);
                setLoad(true)
            })


    }, []);

    if (error) {
        return (
        <div style={{height: '100vh'}}></div>
        )

    }

    if(load){
    
      let seo = [{'property':'og:title', 'content':catData.name}, {'name':'description', 'content': catData.description}];  
      



      let seo_data = catData.yoast_head;
      let featured_image = catData.featured_image;

        let seo_title = catData.name;
        let seo_head= catData.yoast_head;
        let acf = catData.acf;

        function isPreRendering() {
            return navigator.userAgent === 'ReactSnap';
        };
      
        let preRender = isPreRendering();

        return(
            <>
                <SEO data={seo} image={featured_image} />
                <Helmet
                    bodyAttributes={{
                        class: `${acf.background_color ? acf.background_color : 'background-beige'} fixed-header`
                    }}
                />

                {  !preRender && 
                <>
                <TopSection title={catData.name}
                    description={catData.description}
                    backgroundClass="background-beige" filter={false} />


                <WorkNews data={workData} filter={false} backgroundClass="background-beige" targetPage="client" showLabel={true} />

                { acf.client_shapes && acf.client_shapes.shapes &&
                    <Shapes data={acf.client_shapes.shapes} rows="1" />
                }
                </>
                }

            </>
        )
    }else {
        return (
          <div style={{height: '100vh'}}></div>
        )
      }
}
