import React, {useState, useEffect, useRef} from "react"
import Slider from "react-slick";

import "./../styles/quoteslider.scss";

const List = [
  { id: 0, quote: "“A creative, edgy, poetic and moving experience.”", caption: "John Doe, Job Position"},
  { id: 1, quote: "“Lorem ipsum dolor sit amet, consectetur adipisicing..”", caption: "John Doe, Job Position 222"},
  { id: 2, quote: "“Incididunt ut labore et dolore magna aliqua..”", caption: "John Doe, Job Position 333"},
  { id: 3, quote: "“A creative, edgy, Lorem ipsum dolor sit amet.”", caption: "John Doe, Job Position  44"},
]


export default function QuoteSlider({ data, backgroundClass }) {


  const [activeSlide, setActiveSlide] = useState(0)
  const [autoSpeed, setAutoSpeed] = useState(4000)

  const dotsRef = useRef(null)
    const slider = useRef(null)

    useEffect(() => {
      const {current: dotsTrigger } = dotsRef
      const interval = setInterval(() => {
        if( (activeSlide + 1) > (List.length - 1)){
        setActiveSlide(0);
        }else{
        setActiveSlide(activeSlide + 1);
        }

        //console.log('activeSlide', activeSlide)
        //Slider.slickGoTo(activeSlide)
      }, 4000);
      return () => clearInterval(interval);
    });

    const settings = {
      dots: false,
      autoplay: false,
      fade: true,
      pauseOnHover: false,
      arrows: true,
      infinite: true,
      speed: 100,
      cssEase: 'ease',
      draggable: false,
      focusOnSelect: false,
      pauseOnFocus: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: 0,
      className: 'quote-carousel',
      

      responsive: [
        {
          breakpoint: 768,
          settings: {
            draggable: true,
            dots: true,
            arrows: false,
            //swipeToSlide: true,
            //afterChange: current => setActiveSlide(current)
          }
        },
        
      ]

    };

    function handleMove(e) {
      const cursor = document.querySelector('.cursor');
      
          let x = (e.pageX) - window.pageXOffset;;
          let y = (e.pageY) - window.pageYOffset;
        
          cursor.classList.remove("blue-cursor");
          cursor.classList.add("yellow-cursor");
          cursor.classList.remove("default-cursor");
          cursor.classList.add("arrow-cursor");
         
          cursor.style.transform = `translate(-50%, -50%) translate(${x}px, ${y}px)`
          
          //console.log(cursor)

          if ( e.pageX <= (window.innerWidth / 2) ){
            cursor.classList.remove("right");
            cursor.classList.add("left");

          }else{
            cursor.classList.remove("left");
            cursor.classList.add("right");
          }
      //}
     

    }

    
    function handleLeave(e) {
      const cursor = document.querySelector('.cursor')
      //cursor.classList.remove("in");
      cursor.classList.remove("arrow-cursor");
      cursor.classList.add("default-cursor");

    }

    function handleMouseDown(e) {
        const cursor = document.querySelector('.cursor')
        cursor.classList.add("mouse-down");
        //cursor.classList.add("in");
        cursor.classList.remove("default-cursor");
        cursor.classList.add("arrow-cursor");


    }
    function handleMouseUp(e) {
        const cursor = document.querySelector('.cursor')
        cursor.classList.remove("mouse-down");
        //cursor.classList.add("in");
        cursor.classList.remove("default-cursor");
        cursor.classList.add("arrow-cursor");


    }
    


    function handleNext(){
        if( (activeSlide + 1) > (List.length - 1)){
        setActiveSlide(0);
        }else{
        setActiveSlide(activeSlide + 1);
        }
    }

    function handlePrev(){
        if( (activeSlide - 1) < 0 ){
        setActiveSlide(List.length - 1);
        }else{
        setActiveSlide(activeSlide - 1);
        }
    }


    function handleClick(e){
      console.log('clicked')
        if ( e.pageX <= (window.innerWidth / 2) ){
          slider.current.slickPrev();

        }else{
          slider.current.slickNext();
        }
    }


    return (
      <section className={`quoteslider-section ${backgroundClass ? backgroundClass : ""}`}>
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-xl-8 col-lg-10 col-12 slider-cont"
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseEnter={handleMove}
                onMouseLeave={handleLeave}
                onMouseMove={handleMove}
                 onClick={handleClick}
                >
                <Slider {...settings} ref={slider}>
                  {List.map((item, index) => (
                        <div className={`item-content ${activeSlide === index ? "active" : ""}`} key={index} >
                            <blockquote className="quote">
                                {item.quote}
                            </blockquote>
                            <h4 className="quote-caption">
                                {item.quote_caption}
                            </h4>
                        </div>
                    )
                    )}
                </Slider>
                <button type="button" data-role="none" className="slick-arrow slick-next d-md-block d-none"
                    onClick={handleNext}
                > </button>

                <button type="button" data-role="none" className="slick-arrow slick-prev d-md-block d-none"
                    onClick={handlePrev}
                ></button>
            </div>
            <div className="col-12 d-md-block d-none">
                <ul className="slick-dots" ref={dotsRef}>
                    {List.map((item, index) => (
                        <li className={`${activeSlide === index ? "slick-active" : ""}`}
                        onClick={() => (setActiveSlide(index))}
                        key={index}>
                            <button>1</button>
                        </li>
                    )
                    )}
                </ul>
            </div>
          </div>
        </div>
      </section>
    );

}


/*WorkNews.defaultProps = {
  data: [],
}

WorkNews.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  backgroundClass: PropTypes.string

}*/
