import React, {useState, useEffect, useRef} from "react";
import './styles/dragslider.scss'
import  useWindowSize from "./hooks/usewindowsize"

import SwiperCore, { Navigation } from 'swiper';
import Swiper from 'react-id-swiper';
import 'intersection-observer'
import ProgressiveImage from 'react-progressive-graceful-image';


import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";


export default function DraggableSlider({data, backgroundClass, id}) {

  const [show, setShow] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);
  const [mouseUp, setMouseUp] = useState(false);
  const [mouseMove, setMouseMove] = useState(false);
  const [mouseLeave, setMouseLeave] = useState(false);


  const [sliderInit, setSliderInit] = useState(false);



  const [maxHeight, setMaxHeight] = useState(0);
  const refContainer = useRef(null);

  const size = useWindowSize();

    /*const settings = {
      className: "center",
      infinite: true,
      arrows: false,
      centerMode: false,
      slidesToShow: 1,
      //swipeToSlide: true,
      variableWidth: true,
      className: 'drag-carousel',
  }*/

  const params = {
      //freeMode: true,
      freeMode: false,
      slidesPerView: 'auto',
      centeredSlides: false,
      mousewheel: false,
      grabCursor: true,
      simulateTouch: true,
      touchStartPreventDefault: false,
      loop: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {
        init: function () {
          //console.log('Init')
          setSliderInit(true);
          document.querySelector('.cursor').classList.remove('arrow-cursor')
          document.querySelector('.cursor').classList.add('default-cursor')
        },

        
        toEdge: function (e) {
          document.querySelector('.cursor').classList.remove('arrow-cursor')
          document.querySelector('.cursor').classList.add('default-cursor')
        },

        reachEnd: function (e) {
        },
        
        reachBeginning: function (e) {
        },
        
       
          
      }


    }

    useEffect(() => {
        const { current: container_ } = refContainer;

        let x = 0, i = 0, item;
        let capHeight = 0;
        let prevcapHeight = 0;
        x = container_.querySelectorAll(".p-caption");
        setTimeout(
          () => {
            if(!show){ 
              for (i = 0; i < x.length; i++) {
                x[i].classList.add('show-in') 
              }
            } 
            setShow(true)
          },
          300
        );
        item = container_.querySelectorAll(".item");

        if(sliderInit){

          for (i = 0; i < x.length; i++) {

            if( size.width < 992 ){

              if( i == 0 ){
                capHeight = x[i].getBoundingClientRect().height;
                setMaxHeight(capHeight)
                prevcapHeight = capHeight;

                //console.log('maxx Height', maxHeight)

              }
              else if ( x[i].getBoundingClientRect().height > prevcapHeight ){
                capHeight = x[i].getBoundingClientRect().height
                setMaxHeight(capHeight)
                prevcapHeight = capHeight;

                //console.log('maxx Height', maxHeight)

              }else if ( x[i].getBoundingClientRect().height < prevcapHeight ){
                capHeight = x[i].getBoundingClientRect().height
                setMaxHeight(prevcapHeight)
                prevcapHeight = capHeight;

                //console.log('maxx Height', maxHeight)



              }

              container_.querySelector('.swiper-container').style.paddingBottom = `${maxHeight}px`;
              //container_.style.paddingBottom = `0px`;
              container_.nextElementSibling.style.marginTop = `0px`;
              if(container_.classList.contains('no-padding')){
                container_.style.paddingBottom = `0px`;
              }
              //console.log(" container_.nextElementSibling",  container_.nextElementSibling)

            }else if( size.width > 992 && container_.classList.contains('no-padding')){

              if( i == 0 ){
                capHeight = x[i].getBoundingClientRect().height;
                setMaxHeight(capHeight)
                prevcapHeight = capHeight;

                //console.log('maxx Height', maxHeight)

              }
              else if ( x[i].getBoundingClientRect().height > prevcapHeight && x[i].getBoundingClientRect().height < 100 ){
                capHeight = x[i].getBoundingClientRect().height
                setMaxHeight(capHeight)
                prevcapHeight = capHeight;

                //console.log('maxx Height', maxHeight)

              }else if ( x[i].getBoundingClientRect().height < prevcapHeight ){
                capHeight = x[i].getBoundingClientRect().height
                setMaxHeight(prevcapHeight)
                prevcapHeight = capHeight;

                //console.log('maxx Height', maxHeight)



              }

              container_.style.paddingBottom = `${maxHeight}px`;
              container_.nextElementSibling.style.marginTop = `-${maxHeight}px`;
              container_.querySelector('.swiper-container').style.paddingBottom = `0px`;

              //console.log(" container_.nextElementSibling",  container_.nextElementSibling)

            }

          }

       }
        
    });


    
    function handleMove(e) {
      if(!isMobile){
        const cursor = document.querySelector('.cursor');

        setMouseMove(true);
        setMouseLeave(false)
        if ( e.target.tagName.toLowerCase() === 'p' || e.target.tagName.toLowerCase() === 'span' || e.target.tagName.toLowerCase() === 'em' || e.target.tagName.toLowerCase() === 'b' || e.target.tagName.toLowerCase() === 'i'|| e.target.tagName.toLowerCase() === 'a')  {
            cursor.classList.remove("arrow-cursor");
            cursor.classList.add("default-cursor");
        }else{
            let x = (e.pageX) - window.pageXOffset;;
            let y = (e.pageY) - window.pageYOffset;

            cursor.classList.add("blue-cursor");
            cursor.classList.remove("default-cursor");
            cursor.classList.add("arrow-cursor");

            cursor.style.transform = `translate(-50%, -50%) translate(${x}px, ${y}px)`

           
              if ( e.pageX <= (window.innerWidth / 2) ){
                if (document.querySelector('.swiper-button-prev').classList.contains('swiper-button-disabled') )  {
                    cursor.classList.add("default-cursor");
                    cursor.classList.remove("arrow-cursor");
                }
                else{
                  cursor.classList.remove("default-cursor");
                  cursor.classList.add("arrow-cursor");
                  cursor.classList.remove("right");
                  cursor.classList.add("left");
                }
                
               // console.log('moving-left')
              }else{
                
                if (document.querySelector('.swiper-button-next').classList.contains('swiper-button-disabled') )  {
                    cursor.classList.add("default-cursor");
                    cursor.classList.remove("arrow-cursor");
                }
                else{
                  cursor.classList.remove("default-cursor");
                  cursor.classList.add("arrow-cursor");
                  cursor.classList.remove("left");
                  cursor.classList.add("right");
                }
                //console.log('moving-right')
              }
           // }

        }




        /*setTimeout(function(){ 
              console.log('fter clicking')
            if (document.querySelector('.swiper-button-next').classList.contains('swiper-button-disabled') )  {
              cursor.classList.add("left");
              cursor.classList.remove("right");
            }
            else if (document.querySelector('.swiper-button-prev').classList.contains('swiper-button-disabled') )  {
              cursor.classList.add("right");
              cursor.classList.remove("left");

            }else{
              console.log('its half half')
            }

            //if (!document.querySelector('.swiper-button-prev').classList.contains('swiper-button-disabled') && !document.querySelector('.swiper-button-prev').classList.contains('swiper-button-disabled') ) {
                if (left) {
                  cursor.classList.add("left");
                  cursor.classList.remove("right");
                  console.log('after clicked-left')
                }else if(right){
                  cursor.classList.add("right");
                  cursor.classList.remove("left");
                  console.log('after clicked-right')
                }
             // }
            }, 100);*/
      }
    }




    function handleLeave(e) {
      if(!isMobile){
          const cursor = document.querySelector('.cursor')
    
          cursor.classList.remove("blue-cursor");
          cursor.classList.add("default-cursor");
          cursor.classList.remove("arrow-cursor");

          setMouseLeave(true)
          setMouseDown(false)
          setMouseMove(false);

         
      }

    }
    function handleMouseDown(e) {
      if(!isMobile){
          const cursor = document.querySelector('.cursor')
          cursor.classList.add("down");
          setMouseDown(true)
          setMouseUp(false)
          setMouseLeave(false)

        }
    }
    function handleMouseUp(e) {
      if(!isMobile){
        const cursor = document.querySelector('.cursor')
        setMouseUp(true)
        setMouseDown(false)
        cursor.classList.remove("down");
      }

    }

    function handleOnClick(e) {
        const cursor = document.querySelector('.cursor')
      if ( e.target.tagName.toLowerCase() === 'p' || e.target.tagName.toLowerCase() === 'span' || e.target.tagName.toLowerCase() === 'em' || e.target.tagName.toLowerCase() === 'b' || e.target.tagName.toLowerCase() === 'i'|| e.target.tagName.toLowerCase() === 'a')  {
         
      }else{
          if ( e.pageX <= (window.innerWidth / 2) ){
            if(document.querySelector('.swiper-button-prev').classList.contains('swiper-button-disabled')){

            }else{
              document.querySelector('.swiper-button-prev').dispatchEvent(new Event('click'));
            }
            
          }else if( e.pageX > (window.innerWidth / 2) ){
            if(document.querySelector('.swiper-button-next').classList.contains('swiper-button-disabled')){

            }else{
              document.querySelector('.swiper-button-next').dispatchEvent(new Event('click'));
            }
             
          }   
      }

    }

    function handleMouseOnItem(el){
      let elParent = el.target.parentElement.parentElement;

      //console.log( 'target bounding' , el);
      //console.log( 'target bounding parent' , elParent);

      let bounding = elParent.getBoundingClientRect();

            //console.log( 'bounding' , bounding);
      let right = bounding.right;

      if(
          bounding.left >= 0 &&
          (right <= (window.innerWidth) )
        ){
        elParent.classList.add('fully-in');
        //console.log('its here')
      }else{
        elParent.classList.remove('fully-in');
       // console.log('it left')
      }




    }

    return (
      <section ref={refContainer} id={id}
          
          className={`dragableslider-section ${backgroundClass ? backgroundClass : ""} ${mouseDown ? 'mouseDown' : ''} ${mouseUp ? 'mouseUp' : ''} ${mouseMove ? 'mouseMove': ''} ${mouseLeave ? 'mouseLeave': ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={`slider-cont`}
                    onMouseDown={handleMouseDown}
                    onTouchStart={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchEnd={handleMouseUp}
                    onMouseMove={handleMove}
                    
                    onMouseLeave={handleLeave}

                    onClick={handleOnClick}

              >
                  <Swiper {...params}>
                    {data.map((item, i) => {
                      // console.log(item)
                      let caption_text = item.image_caption;
                      return (
                          <div className="item" id={`slide_${item.id}`}key={i}>

                            <div className={`${item.lazy_load_background ? item.lazy_load_background : ""} img-wrap`} onMouseMove={handleMouseOnItem}>
                              {item.image && 
                                <img className="placeholder-img" src={item.image.sizes.large} alt={item.image.alt}/>
                              }
                                <div className="lazy-holder" >
                                {item.image && 
                                  <ProgressiveImage
                                      className="main-image"
                                      delay={500}
                                      src={item.image.sizes.large}
                                      srcSetData={{
                                        srcSet: `${item.image.sizes.large} 320w, ${item.image.sizes.large} 700w, ${item.image.sizes.large} 2000w`,
                                        sizes: '(max-width: 2000px) 100vw, 2000px',
                                        alt: `${item.image.alt}`
                                      }}
                                      placeholder={item.image.sizes.large}
                                  >
                                
                                    {(src, loading, srcSetData, placeholder) => (
                                          <img className={`gallery-main-img ${loading ? 'not-loaded' : "img-loaded"} `} style={{ opacity: loading ? 0 : 1, transition: "0.3s ease" }}
                                            src={src}
                                            srcSet={srcSetData.srcSet}
                                            sizes={srcSetData.sizes}
                                            alt={item.image.alt}
                                          />
                                      )}


                                  </ProgressiveImage>
                                }
                                </div>

                            </div>
                            {caption_text &&
                              <p className="p-caption" dangerouslySetInnerHTML={{__html: caption_text}} />
                            }
                          </div>
                      )
                     })}
                    
                  </Swiper>
                </div>
            </div>
          </div>
        </div>


      </section>
    );
  }
