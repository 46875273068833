import React, { useState, useEffect, useCallback, useRef } from "react"
import work1 from "./../../images/work1.png"
import work1_placeholder from "./../../images/work1_placeholder.png"
import work2 from "./../../images/work3.png"
import work2_placeholder from "./../../images/work3_placeholder.jpg"
import news1 from "./../../images/news1.png"
import news1_placeholder from "./../../images/news1_placeholder.png"
import news2 from "./../../images/news2.png"
import news2_placeholder from "./../../images/news2_placeholder.png"
import "./../styles/worknews.scss"
import { Link } from 'react-router-dom'


import ProgressiveImage from 'react-progressive-graceful-image';

// Your code



const NewsList = [
  { id: 1, url: "/", type:"portrait", title: "Our work just won a Webby Award for 'Best Nonprofit Website'" , label: "work", background: work2, backgroundPlaceholder: work2_placeholder,
    bgColor: "background-yellow", desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."  
  },
  { id: 2, url: "/", type:"landscape", title: "News Heading 11" , label: "news", background: news1, backgroundPlaceholder: news1_placeholder,
    bgColor: "background-orange", desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."  
  },
  { id: 3, url: "/", type:"landscape", title: "Heading on 2 lines to introduce Echoing Green project" , label: "news", background: news2, backgroundPlaceholder: news2_placeholder,
    bgColor: "background-saumon", desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."  
  },
  { id: 4, url: "/", type:"portrait", title: "New website for the book, movie, and important story of ‘Just Mercy’" ,label: "project", background: work1, backgroundPlaceholder: work1_placeholder,
    bgColor: "background-blue", desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."  
  },
]

const WorkList = [
  { id: 1, url: "/", type:"portrait", title: "Innocence Project" , label: "2015 - Present", label_url: "/", background: work2,
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."  
  },
  { id: 2, url: "/", type:"landscape", title: "News Heading 11" ,  background: news1,
    desc: "Completely new website, reflecting the organization’s strategic plan and introducing a new approach to engaging with audiences."  
  },
  { id: 3, url: "/", type:"landscape", title: "Work Heading on 2 lines to introduce Echoing Green project" , background: news2,
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."  
  },
  { id: 4, url: "/", type:"portrait", title: "Work New website for the book, movie, and important story of ‘Just Mercy’", label: "2017 - Present", label_url: "/", background: work1,
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."  
  },
]

const Filter = () => {
  let buttons = [
    {text: "All", key: 1, active: true},
    {text: "Branding", key: 2, active: false},
    {text: "Website", key: 3, active: false},
    {text: "Nonprofit", key: 4, active: false},
    {text: "Branding", key: 5, active: false}
  ]; 
  function handleMove(e) {
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
  }
  function handleLeave(e) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce");
  }
  return (
    <ul className="filter-holder">
      {buttons.map((item, i) =>
        <li key={i}>
          <a onMouseEnter={handleMove} 
            onMouseMove={handleMove} 
            onMouseLeave={handleLeave}
            className={`button small-btn ${item.active ? 'active' : 'not-active'}`} href="#" key={i}>{item.text}</a>
        </li>

      )}
    </ul>
  );
};
 
export default function WorkNews({ data, backgroundClass, work, client, filter, filterData }){
  const isWork = work;
  const isClient = client;
  let List;
  if(isWork == true){
    List = WorkList;
  } else {
    List = NewsList;
  }

  function handleMove(e) {
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
  }
  function handleLeave(e) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce");
  }

  return (
    <section className={`worknews-section ${backgroundClass ? backgroundClass : ""}`}>
        <div className="container">
        {filter ? 
          <div className="row">
            <div className="col-lg-11">
              <Filter filterData={filterData} />
            </div>
          </div>
          :
          ''
        }
          <div className="row align-items-center">
            {List.map((item, i) => {
              return (
                  <MyItem item={item} id={item.id} bg={backgroundClass} key={i} workItem={isWork} clientPage={isClient}/>
              )
             })}
            
          </div>
          {isWork && !isClient ?
            <div className="row justify-content-center load-more-btn">
              <div className="col-auto"><a className="button big-btn blue-btn load-more" href="#"
                   onMouseEnter={handleMove} 
                  onMouseMove={handleMove} 
                  onMouseLeave={handleLeave}
              >More Projects</a></div>
            </div>
          : `` }
        </div>
    </section>
  )
}


function MyItem({item, bg, id, workItem, clientPage}){
  // init ref
  const containerRef = React.useRef(null)
  const triggerRef = React.useRef(null)
  let height = 0;

  const isWorkItem = workItem;
  const isClientPage = clientPage;

  React.useEffect(() => {
    const { current: containerElement } = containerRef
    const { current: triggerElement } = triggerRef
  })

  function handleMove(e) {
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
  }
  function handleLeave(e) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce");
  }

  return (
    <div className={`col-md-6 item ${item.label && 'work-item'}`}>
      <div  className={`item-wrapper`}>
          <Link to={item.url}
                onMouseEnter={handleMove} 
                onMouseMove={handleMove} 
                onMouseLeave={handleLeave}
          >
            <div  className={`${item.bgColor} image-wrapper`} ref={containerRef} style={{height: "auto",}} >
                <img className="placeholder-img" src={item.backgroundPlaceholder} style={{opacity: "0"}} />
                <div className="lazy-holder" style={{position: "absolute", left: "0px", top: "0px", width: "100%", height: "100%" }} >
                  <ProgressiveImage 
                      className="main-image"
                      delay={500}
                      src={item.background} 
                      srcSetData={{
                        srcSet: `${item.background} 320w, ${item.background} 700w, ${item.background} 2000w`,
                        sizes: '(max-width: 2000px) 100vw, 2000px'
                      }}
                      placeholder={item.backgroundPlaceholder}
                  >
                    {(src, loading, srcSetData, placeholder) => (
                          <img style={{ opacity: loading ? 0 : 1, transition: "0.3s ease" }}
                            src={src} 
                            srcSet={srcSetData.srcSet}
                            sizes={srcSetData.sizes}
                            alt="an image"
                          />
                      )}

                  
                  </ProgressiveImage>
                </div>
            </div>
          </Link>
          {item.label && isWorkItem && !isClientPage ? <div className={`client-label ${bg}`}><Link to={item.label_url}><p>{item.label}</p></Link></div> : `` }
          <Link to={item.url}
                onMouseEnter={handleMove} 
                onMouseMove={handleMove} 
                onMouseLeave={handleLeave}
          >
            <div className="titles">
              <h3 className="medium-title title">
                  {item.title} 
              </h3>
              <p className="p-big desc">
                {item.desc}
              </p>
            </div>
          </Link>
      </div>
    </div>
  )

}


/*
<picture>
                  <source media="(min-width:650px)" srcSet={item.background} />
                  <source media="(min-width:465px)" srcSet={item.background} />
                  <img src={item.background} ref={triggerRef} alt="Flowers" style={{width: "100%", height: "auto",}} />
                  {item.label && !isWorkItem ? <span className={`label ${bg}`}>{item.label}</span> : `` }
                </picture>

*/

//                <div ref={triggerRef} style={{backgroundImage: `url(${work2})`, width: "100%", height: "500px", transform: "scale(0.5)"}}>When this hits the top the page will turn blue</div>

  /*React.useEffect(() => {
    const { current: containerElement } = containerRef
    const { current: triggerElement } = triggerRef

    height = triggerElement.getBoundingClientRect().height + 200;

    if (!containerElement && !triggerElement) {
      return undefined
    }

    const myTimeline = gsap.timeline({ paused: true })
    myTimeline.to(triggerElement, {
      scale: 1,
      ease: 'power2.out',
    })


    const scrollScene = new ScrollScene({
      triggerElement: triggerElement,
      gsap: {
        timeline: myTimeline,
        reverseSpeed: 4,
      },
      toggle: {
        element: triggerElement,
        className: 'in-view',
        reverse: true,
      },
      scene: {
        triggerElement: triggerElement,
        reverse: true,
      },
      triggerHook: 1,
      offset: "-50",
      duration: '100%',
    })

    // destroy on unmount
    return () => {
      scrollScene.destroy()
    }
  })*/
