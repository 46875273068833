import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

export class PortfolioItem extends Component {
 state = {
   imgUrl: '',
   isLoaded: false
 }

  static propTypes = {
    portfolio: PropTypes.object.isRequired
  }

  componentDidMount() {
    const { featured_media } = this.props.portfolio;
    const getImageUrl = axios.get(`/wp-json/wp/v2/media/${featured_media}`);

    Promise.all([getImageUrl]).then(res => {
      this.setState({
        imgUrl: res[0].data.media_details.sizes.full.source_url,
        isLoaded: true
      });
    });
  }

  render() {
    const { id, slug, title } = this.props.portfolio;
    const { imgUrl, isLoaded } = this.state;

    if (isLoaded) {
      return (
        <div>
          <h2> {title.rendered} </h2>
          <img style={{width: '100%'}} src={imgUrl} alt={title.rendered}/>
          <Link to={`/work/${slug}`}>Link here</Link>
        </div>
      );
    }


    return null;

  }
}
export default PortfolioItem;
