import React,{ useState, useEffect } from "react"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

//import Layout from "../components/layout"
import SEO from "../components/seo"

import TopSection from "../components/topsection"
import WorkNews from "../components/worknews"
import Shapes from "../components/shapes"
import { Helmet } from 'react-helmet'

import axios from 'axios'
import md5 from 'md5'
import NoMatchPage from '../pages/404'


export default function  NewsPage(props) {

    const path = props.match.path;
    let api_url = '/wp-json/wp/v2/pages/';
    const [axiosArr, setAxiosArr] = useState([]);
    const [newToken, setNewToken] = useState(false);

    const [pageData, setPageData] = useState([]);
    const [newsData, setNewsdata] = useState([]);
    const [newsCatData, setNewsCatData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');
    function isPreRendering() {
        return navigator.userAgent === 'ReactSnap';
    };
    let preRender = isPreRendering();

  useEffect(() => {
    //console.log('news props',props);
    if(path.indexOf('_preview') > -1){

        let password = prompt('Password');
        const pageID = path.substr(path.lastIndexOf('/') + 1);
        api_url += pageID+'/revisions/?per_page=1&offset=0';
        if(password){
            axios.all([axios.post('/wp-json/madeo/v1/token',{sec_key:md5(password)})])
            .then(res => {
                let token_res = res[0].data;
                if(token_res.token != undefined){
                setAxiosArr([
                    axios.get(api_url,{headers: {authorization: 'Bearer '+token_res.token}}),
                    axios.get('/wp-json/wp/v2/posts?offset=0&per_page=12&hide_password=true'),
                    axios.get('/wp-json/wp/v2/categories/?hide_empty=true&hide_password=true')
                ]);
                setNewToken(true);
                }else{
                setNewToken(false);
                setLoad(true);
                setError('404');
                }

            })
            .catch(err => {
                setError(err.message);
                setLoad(false)
            })
        }else{
            setNewToken(false);
            setLoad(true);
            setError('404');
        }


    }else{
      api_url += '?slug='+path.substring(1);
      //console.log('show news', api_url);
      
        let data_url = '/wp-json/wp/v2/posts?offset=0&per_page=12&hide_password=true'
                if(preRender){
                    data_url = '/wp-json/wp/v2/posts?offset=0&per_page=100&hide_password=true'
                }
      setAxiosArr([
        axios.get(api_url),
        axios.get(data_url),
        axios.get('/wp-json/wp/v2/categories/?hide_empty=true&hide_password=true')
      ]);
      setNewToken(true);
    }

    }, [path]);



    useEffect(() => {
        if(newToken){
            axios.all(axiosArr)
            .then(res => {
                setPageData(res[0].data[0]);
                setNewsdata(res[1]);
                setNewsCatData(res[2].data);
                setLoad(true);
            })
            .catch(err => {
                setError(err.message);
                setLoad(true)
            })

        }
    }, [newToken, axiosArr]);

    if (error) {
        return (
            <Route component={NoMatchPage} />
        )

    }

    if (load) {
        let acf = pageData.acf;

        let seo_data = pageData.yoast_meta;
        let featured_image = pageData.featured_image;

        

        return(
            <>
                { seo_data &&
              <SEO data={seo_data} image={featured_image} />
              }
                <Helmet
                    bodyAttributes={{
                        class: `page-news ${acf.background_color} fixed-header`
                    }}
                />
                
                { acf.title && !preRender &&
                    <TopSection title={acf.title}
                    description={acf.description}
                    backgroundClass={acf.background_color} filter={true} />
                }

                { acf.page_content && acf.page_content.map((sectionData, index) => {
                    switch(sectionData.acf_fc_layout){
                        case 'news_data':{
                            return(
                            <WorkNews data={newsData} backgroundClass="background-beige" filter={true} filterData={newsCatData} filterAllPath={`news/`} targetPage="news" showLabel={false} key={index}/>
                            )
                            break;
                        }
                        case 'shapes':{
                            if(!preRender){
                                return(
                                    <Shapes data={sectionData} rows="1" key={index}/>
                                )
                            }
                            break;
                        }
                    }


                    }
                )}
                
                

            </>
        )
    }else {
        return (
          <div style={{height: '100vh'}}></div>
        )
      }
}
