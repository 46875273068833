import React from "react"

//import Layout from "../components/layout"
//import SEO from "../components/seo"

//import Cursor from "../components/lslidercursor"


function UI({}) {

  

    return(
        <>
             <div className="container">
                    <div className="row" style={{}}>
                        <div className="col-12">
                            <h2 className="big-title mb-3"> Big button </h2>
                        </div>
                        <div className="col-12">
                            <h3 className="medium-title d-lg-block d-none mb-5"> Xlarge + Large </h3>
                        </div>
                        <div className="col-12">
                            <h3 className="medium-title d-lg-none d-sm-block d-none mb-5"> Medium + Small </h3>
                        </div>
                        <div className="col-12">
                            <h3 className="medium-title d-sm-none d-block mb-5"> XSmall </h3>
                        </div>
                        <div className="col-12 mb-5">
                            <a className="button big-btn blue-btn" href="#">Learn More</a>
                        </div>
                        <div className="col-12 mb-5">
                            <a className="button big-btn white-btn" href="#">Learn More</a>
                        </div>


                        <div className="col-12">
                            <h2 className="big-title mb-3"> Medium button </h2>
                        </div>
                        <div className="col-12">
                            <h3 className="medium-title d-lg-block d-none mb-5"> Xlarge + Large </h3>
                        </div>
                        <div className="col-12">
                            <h3 className="medium-title d-lg-none d-sm-block d-none mb-5"> Medium + Small </h3>
                        </div>
                        <div className="col-12">
                            <h3 className="medium-title d-sm-none d-block mb-5"> XSmall </h3>
                        </div>
                        <div className="col-12 mb-5">
                            <a className="button medium-btn blue-btn" href="#">Learn More</a>
                        </div>
                        <div className="col-12 mb-5">
                            <a className="button medium-btn white-btn" href="#">Learn More</a>
                        </div>

                        <div className="col-12 mb-5">
                            <a className="button small-btn" href="#">Branding</a>
                        </div>
                        <div className="col-12 mb-5">
                            <a className="button small-btn active" href="#">Branding</a>
                        </div>

                        <div className="col-12">
                            <h3 className="medium-title mb-3"> Draggable Cursor </h3>
                        </div>
                        <div className="col-12  mb-5">
                            <div className={`static-curcor drag-cursor in`} style={{position: 'relative'}}>
                              <div className="circle">
                                <span className="span-left" />
                                <span className="span-right" />
                              </div>
                            </div>

                            <div className={`static-curcor drag-cursor in down`} style={{position: 'relative'}}>
                              <div className="circle">
                                <span className="span-left" />
                                <span className="span-right" />
                              </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <h3 className="medium-title mb-3"> Arrow Cursor (Blue) </h3>
                        </div>
                        <div className="col-12  mb-5">
                            <div className="cursor-holder background-white" > 
                                <div className="static-curcor arrow-cursor  blue-cursor in" style={{position: 'relative', opacity: '1'}}>
                                    <div className="circle"></div>
                                </div>
                                <div className="mb-5" />
                                <div className="static-curcor arrow-cursor blue-cursor in mouse-down" style={{position: 'relative', opacity: '1'}}>
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <h3 className="medium-title mb-3"> Arrow Cursor (Yellow) </h3>
                        </div>
                        <div className="col-12  mb-5">
                            <div className="cursor-holder background-orange ">  
                                <div className="static-curcor arrow-cursor yellow-cursor" style={{position: 'relative'}}>
                                    <div className="circle"></div>
                                </div>
                                <div className="mb-5" />
                                <div className="static-curcor arrow-cursor yellow-cursor mouse-down" style={{position: 'relative', opacity: '1'}}>
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
             </div>
            
        </>
    )
}

export default UI
