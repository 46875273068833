import React,{ useState, useEffect } from "react"
import useDelayRouteExit from "delay-react-route-exit";

//import Layout from "../components/layout"
import SEO from "../components/seo"

import TopSection from "../components/topsection"
import WorkNews from "../components/worknews"
import BigList from "../components/biglist"
import RelatedNews from "../components/relatednews"
import Shapes from "../components/shapes"
import { Helmet } from 'react-helmet'

import axios from 'axios'

export default function  Work(props) {

    const catSlug = props.match.params.slug;
    const [pageData, setPageData] = useState([]);
    const [pageColor, setPageColor] = useState([]);
    const [collectionData, setCollectionData] = useState([]);
    const [workData, setWorkData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');



    useEffect(() => {

        axios.all([
        axios.get('/wp-json/wp/v2/pages?slug=work'),
        axios.get('/wp-json/wp/v2/collections/?slug='+catSlug),
        ])
            .then(res => {
                setPageColor(res[0].data[0].acf.background_color);
                setPageData(res[0].data[0]);
                setCollectionData(res[1].data[0]);
                let catId = res[1].data[0].id;
                axios.get('/wp-json/wp/v2/projects?hide_password=true&offset=0&per_page=8&orderby=menu_order&order=asc&set_as_client_page=no&collections='+catId).then(result => {
                    setWorkData(result);
                    setLoad(true);
                })

            })
            .catch(err => {
                setError(err.message);
                setLoad(true)
            })


    }, []);

    if (error) {
        return (
        <div style={{height: '100vh'}}></div>
        )

    }

    if(load){
        let seo_title = pageData.yoast_meta.yoast_wpseo_title;
        let seo_head= pageData.yoast_head;
        let acf = pageData.acf;

        let seo_data = pageData.yoast_meta;
        let featured_image = pageData.featured_image;

        function isPreRendering() {
            return navigator.userAgent === 'ReactSnap';
          };
      
          let preRender = isPreRendering();


        return(
            <>
                <SEO data={seo_data} image={featured_image} />
                <Helmet
                    bodyAttributes={{
                        class: `page-work ${acf.background_color} fixed-header taxonomy-page`
                    }}
                />

                {  !preRender && 
                <>
                { collectionData.name &&
                    <TopSection title={collectionData.name}
                    description={collectionData.description}
                    backgroundClass={acf.background_color} filter={false} />
                }

                { acf.page_content && acf.page_content.map((sectionData, index) => {
                    switch(sectionData.acf_fc_layout){
                        case 'work':{
                            return(
                            <WorkNews  client_exclude={``} data={workData} filter={false} backgroundClass={pageColor} targetPage="work" showLabel={true} collections={collectionData.id} key={index} />
                            )
                            break;
                        }
                        case 'big_list':{
                            return(
                                <BigList data={sectionData} backgroundClass="background-saumon" key={index}/>
                            )
                            break;
                        }
                        case 'related_news':{
                            return(
                            <RelatedNews data={sectionData} backgroundClass="background-yellow"  key={index}/>
                            )
                            break;
                        }
                        case 'shapes':{
                            return(
                            <Shapes data={sectionData} rows="1" key={index}/>
                            )
                            break;
                        }
                    }


                    }
                )}

                </>
                }


            </>
        )
    }else {
        return (
          <div style={{height: '100vh'}}></div>
        )
      }
}
