import React, {useEffect, useRef, useState} from "react"
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import  useWindowSize from "./hooks/usewindowsize";
import "./styles/homeintro.scss";

export function debounce(func, wait = 1, immediate = false) {
  let timeout;
  return function() {
    const context = this;
    const args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const HomeIntro = (title, backgroundClass) => {

    let title_text = title.data;
    backgroundClass = title.backgroundClass;
    const { width, height } = useWindowSize();
    let bodyLock, scrollPosition;

    let data = []
    data = title_text.split(' ');

    const introRef = useRef(null);
    const introTitleRef = useRef(null);
    const titleHolderRef = useRef(null);

    function enable(scrollPosition) {
      bodyLock.style.paddingRight = getScrollbarWidth()+"px";
      bodyLock = document.getElementsByTagName('body')[0];
      scrollPosition = scrollPosition;
      bodyLock.style.overflow = 'hidden';
      bodyLock.style.position = 'fixed';
      bodyLock.style.top = `-${scrollPosition}px`;
      bodyLock.style.width = '100%';
      setTimeout(function(){
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh+"px");
      }, 500);
    };
    function disable(scrollPosition) {
      bodyLock = document.getElementsByTagName('body')[0];
      bodyLock.style.overflow = '';
      bodyLock.style.position = '';
      bodyLock.style.top = '';
      bodyLock.style.width = '';
      bodyLock.style.paddingRight = "";
      window.scrollTo(0, scrollPosition);
      setTimeout(function(){
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh+"px");
      }, 500);
    };

    function isPreRendering() {
      return navigator.userAgent === 'ReactSnap';
    };

    useEffect(() => {
        const { current: intro} = introRef;
        const { current: introTitle } = introTitleRef;
        const { current: titleHolder } = titleHolderRef;
        let time = 0;
        
        bodyLock = document.getElementsByTagName('body')[0];
      
      if (!isPreRendering()) {
        if(document.getElementById("main-header").classList.contains("no-animation")){
          time = 10;
        } else {
          enable(scrollPosition);
        }
        setTimeout( function(){
            animationTrigger();
        }, time);
        setTimeout( function(){
          if(document.getElementById('page-transition').classList.contains("transition-animate-reset")){
            document.getElementById('page-transition').classList.add("transition-pole-start");
            document.getElementById("wrapper").classList.add("page-animation-start");
            setTimeout(function(){
              document.getElementById('page-transition').classList.remove("transition-animate");
              document.getElementById("wrapper").classList.remove("page-animation-start");
              setTimeout(function(){
                document.getElementById('page-transition').classList.remove("transition-animate-reset");
              }, 500);
            }, 500);
          }
        }, 10);
      }
    }, []);

    function getScrollbarWidth() {
      return window.innerWidth - document.documentElement.clientWidth;
    }

    function animationTrigger(){
        let bodyBlock = document.getElementsByTagName('body')[0];
        let $scaleVal, $spacing = 200;
        let titleTime, scalingTime, headerTime;
        clearTimeout(titleTime);
        clearTimeout(scalingTime);
        clearTimeout(headerTime);
        bodyBlock.classList.remove('homepage-block');
        if( window.innerWidth >= 992){
          $scaleVal = 0.5;
        } else {
          $scaleVal = 0.7;
        }
        if( window.innerWidth >= 576){
          $spacing = 210;
        } else {
          $spacing = 160;
        }
        let intro = document.getElementsByClassName('intro')[0];
        intro.classList.remove("animated");
        intro.classList.add("animated");
        let mainTitleHolder = document.getElementById('title-holder');
        let mainTitle = document.getElementById('intro-title');
        let transitionDiv = document.getElementById('transition-val');
        let wordCount = mainTitle.childElementCount;
        //let delay = getComputedStyle(transitionDiv).getPropertyValue("transition-delay");
        //delay = delay.replace(/s/gi,'');
        //console.log(delay);
        let time = 0.7;//Number(delay);
        //console.log(time);
        let titleheight = getComputedStyle(mainTitle).getPropertyValue("height");
        
        //console.log("run it")
        if(document.getElementById("main-header").classList.contains("no-animation")){
          
          //console.log("run it  2")
          intro.classList.add("no-intro-animation");
          mainTitleHolder.style.height = parseInt(titleheight, 10)+"px";
            document.getElementById('main-header').classList.add("animated", "transparent");
            intro.style.height = ((parseInt(titleheight, 10) * $scaleVal) + $spacing )+"px";
            document.getElementById('intro-title').classList.add("scaling");
            mainTitleHolder.style.height = (parseInt(titleheight, 10) * $scaleVal)+"px";
            headerTime = setTimeout( function(){
              document.getElementById('main-header').classList.remove("home-animation", "transparent");
              intro.classList.add("resize-ready");
            }, 1200);
        } else {
          titleTime = setTimeout( function(){
            mainTitleHolder.style.height = parseInt(titleheight, 10)+"px";
          }, 300);
          scalingTime = setTimeout( function(){
            document.getElementById('main-header').classList.add("animated", "transparent");
            intro.style.height = ((parseInt(titleheight, 10) * $scaleVal) + $spacing )+"px";
            document.getElementById('intro-title').classList.add("scaling");
            mainTitleHolder.style.height = (parseInt(titleheight, 10) * $scaleVal)+"px";
            headerTime = setTimeout( function(){
              document.getElementById('main-header').classList.remove("home-animation", "transparent");
              intro.classList.add("resize-ready");
              disable(scrollPosition);
              document.getElementsByTagName('body')[0].classList.add("intro-loaded");
            }, 1200);
          }, (time * 1000) - 30);
        }
    }

    function scaleUpdate(){
      let $scaleVal, $spacing = 200;
      if( window.innerWidth >= 992){
        $scaleVal = 0.5;
      } else {
        $scaleVal = 0.7;
      }
      if( window.innerWidth >= 576){
        $spacing = 210;
      } else {
        $spacing = 160;
      }
      // console.log($scaleVal);
      let intro = document.getElementsByClassName('intro')[0];
      let mainTitleHolder = document.getElementById('title-holder');
      let mainTitle = document.getElementById('intro-title');
      let wordCount = mainTitle.childElementCount;
      mainTitleHolder.style.height = null;
      mainTitle.style.height = null;
      let titleheight = getComputedStyle(mainTitle).getPropertyValue("height");
      mainTitleHolder.style.height = parseInt(titleheight, 10)+"px";
      intro.style.height = ((parseInt(titleheight, 10) * $scaleVal) + $spacing )+"px";
      mainTitleHolder.style.height = (parseInt(titleheight, 10) * $scaleVal)+"px";
    }

    useEffect(() => {
      window.addEventListener('resize', scaleUpdate);
      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener('resize', scaleUpdate);
      }
    }, [])

  if (data) {
    return(
        <section className={`intro ${backgroundClass ? backgroundClass : ""}`} ref={introRef}>
          <div id="transition-val"></div>
          <div className="container">
            <div className="row align-items-end">
              <div id="title-holder" className="col-md-12" ref={titleHolderRef}>
                  <h1 id="intro-title" className="x-large-title"  ref={introTitleRef}>
                  {data.map((item, i) => {
                    return (
                      <span key={i}>{item} </span>
                    )
                   })}
                  </h1>
              </div>
            </div>
          </div>
        </section>
    )
  }


}


export default HomeIntro
