import React, { useState, useEffect, Fragment } from "react"
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import useDelayRouteExit from "delay-react-route-exit";

import TopSection from "../components/topsection"
import Shapes from "../components/shapes"
import SEO from "../components/seo"
import FAQ from "../components/faq"

import axios from 'axios'
import md5 from 'md5'
import NoMatchPage from '../pages/404';

export default function Faq(props) {
  //console.log(props);
  const path = props.match.path;
  let api_url = '/wp-json/wp/v2/pages/';
  const [axiosArr, setAxiosArr] = useState([]);
  const [newToken, setNewToken] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState('');


  useEffect(() => {
    if(path.indexOf('_preview') > -1){
      let password = prompt('Password');
      const pageID = path.substr(path.lastIndexOf('/') + 1);
      api_url += pageID+'/revisions/?per_page=1&offset=0';
      if(password){
        axios.all([axios.post('/wp-json/madeo/v1/token',{sec_key:md5(password)})])
          .then(res => {
            let token_res = res[0].data;
            if(token_res.token != undefined){
              setAxiosArr([
                axios.get(api_url,{headers: {authorization: 'Bearer '+token_res.token}})
              ]);
              setNewToken(true);
            }else{
              setNewToken(false);
              setLoad(true);
              setError('404');
            }
            
          })
          .catch(err => {
              setError(err.message);
              setLoad(false)
          })
      }else{
        setNewToken(false);
        setLoad(true);
        setError('404');
      }
        
      
      
    }else{
      api_url += '?slug='+path.substring(1);
      setAxiosArr([
        axios.get(api_url)
      ]);
      setNewToken(true);
    }
    
    }, [path]);
  
  

  useEffect(() => {
    if(newToken){
      axios.all(axiosArr)
        .then(res => {
            setPageData(res[0].data[0]);
            setLoad(true);
        })
        .catch(err => {
            setError(err.message);
            setLoad(true)
        })
    }
  }, [newToken, axiosArr]);

  if (error) {
    return (
      <Route component={NoMatchPage} />
    )

  }


  if (load) {
    let acf = pageData.acf;

    let seo_data = pageData.yoast_meta;
    let featured_image = pageData.featured_image;

    function isPreRendering() {
      return navigator.userAgent === 'ReactSnap';
    };
  
    let preRender = isPreRendering();

    return(
        <>
            { seo_data &&
              <SEO data={seo_data} image={featured_image} />
              }
            <Helmet
                bodyAttributes={{
                    class: `page-faq ${acf.background_color} fixed-header`
                }}
            />

            { !preRender &&        
            <>
              { acf.title &&
                  <TopSection title={acf.title}
                  description={acf.description}
                  backgroundClass={acf.background_color} />
              }

              { acf.page_content && acf.page_content.map((sectionData, index) => {
                  switch(sectionData.acf_fc_layout){
                      case 'faq':{
                          return(
                          <FAQ data={sectionData.faq} title={acf.title} backgroundClass={acf.background_color} />
                          )
                          break;
                      }
                      case 'shapes_two_rows':{
                          return(
                          <Shapes data={sectionData} rows="2" />
                          )
                          break;
                      }
                  }


                  }
              )}
            </>
            }


        </>
    )

  } else {
    return (
      <div style={{height: '100vh'}}></div>
    )
  }


}
