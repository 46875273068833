import React from "react"

//import "./styles/imagecomp.scss";
import "./styles/mediacomp.scss";

export default function ImageComp({ img, img_mobile, img2, img2_mobile, type, backgroundClass }) {

  if(img){
    if(!img_mobile){
      img_mobile = img.sizes.large;
    }else{
      img_mobile = img_mobile.url;
    }
  }   
  
  
  
  if(img || img2){ 
  return(
      <section className={`media-section ${backgroundClass ? backgroundClass : ""} ${type==="full" ? "full-image" : ""} ${type==="medium" ? "medium-image" : ""} ${type==="sided" ? "sided-image" : ""}`}>
        <div className={` ${type==="full" ? "container-fluide no-padding" : ""} ${type==="medium" || type==="sided" ? "container" : ""} `}>
          <div className="row">
            { img &&
            <div className={`item ${type==="full" || type==="medium" ? "col-12" : ""} ${ type==="sided" ? "col-md-6" : ""} `}>                  
                  <picture>
                    <source srcSet={`${img.url}`} media="(min-width:768px)" />
                    <source srcSet={`${img_mobile}`} media="(max-width:767.98px)" />
                    <img srcSet={`${img.url}`} alt="images alt" />
                  </picture>
            </div>
            }
            {img2 ? 
              <div className="item col-md-6">                  
                  <picture>
                    <source srcSet={`${img2.url}`} media="(min-width:768px)" />
                    <source srcSet={`${img2_mobile.sizes.large}`} media="(max-width:767.98px)" />
                    <img srcSet={`${img2.url}`} alt="images alt" />
                  </picture>
                </div>
              : 
              `` 
            }

          </div>
        </div>
      </section>
  )
  }


}

