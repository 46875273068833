import React, { useState, useRef, useEffect } from "react"
import {Collapse} from 'react-collapse';

export default function AccordionItem ({isOpened, children}) {
  return (
    <Collapse isOpened={isOpened}>
          <div className={`accordion-body ${isOpened ? "open" : ""}`}>
            {children}
          </div>
    </Collapse>
  );
};

