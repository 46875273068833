import React, { Component, Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Redirect } from "react-router-dom";
//import { browserHistory } from 'react-router';
//import history from './history';
//import useDelayRouteExit from "delay-react-route-exit";
import Portfolios from './components/Portfolios';
import PortfolioPage from './components/PortfolioPage';
import PageTransition from './components/pagetransition';
import Patterns from './pages/patternspage';
import StyleGuide from './pages/styleguide';
import UI from './pages/ui';
import HomePage from './pages/homepage';
import AboutUs from './pages/aboutus';
import ContactUs from './pages/contactus';
import Careers from './pages/careers';
import CareersSingle from './pages/singlecareers';
import Faq from './pages/faqpage';
import News from './pages/newspage';
import Work from './pages/work';
import SingleWork from './pages/singlework';
import Category from './pages/category';
import projectCategory from './pages/projectcategory';
import projectCollections from './pages/projectsCollections';
import Client from './pages/client';
import DefaultTemplate from './pages/defaultTemplate';
import NoMatchPage from './pages/404';

import SingleNews from './pages/singlenews';
import ScrollTop from './components/scrolltop';
import { BrowserView, isMobile, osName, browserName } from "react-device-detect";

import Cursor from './components/cursor';
import Header from './components/header';
import Footer from "./components/footer"
import "./components/styles/layout.scss"




import SetFavICon from './components/setfavicon';


import NewsFilter from './pages/newswithfilterstatic';
import axios from 'axios'

//import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'


import './App.css';




function App() {
  
  //const history = useHistory();
  // const browserHistory = createBrowserHistory()
  // browserHistory.listen((location, action) => {
  //   ReactGA.pageview(location.pathname + location.search)
  // })
  function handleMove(e) {
    if (!isMobile) {
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("default-cursor")

      let x = (e.pageX) - window.pageXOffset;;
      let y = (e.pageY) - window.pageYOffset;
      cursor.style.transform = `translate(-50%, -50%) translate(${x}px, ${y}px)`

      if (e.target.tagName.toLowerCase() === 'iframe') {
          cursor.classList.remove("default-cursor")
      }else{
          cursor.classList.add("default-cursor")
      }
    }
  }
  function handleMouseDown(e) {
    if (!isMobile) {
        const cursor = document.querySelector('.cursor')
        cursor.classList.add("mouse-down");
    }
  }
  function handleMouseUp(e) {
    if (!isMobile) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("mouse-down");
    }
  }
  function handleLeave(e) {
    if (!isMobile) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("default-cursor")
    }
  }

  function handleMouseUp(e) {
    if (!isMobile) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("mouse-down");
    }
  }

  function forceTrailingSlash(nextState, replace) {
  const path = nextState.location.pathname;
  if (path.slice(-1) !== '/') {
    replace({
      ...nextState.location,
      pathname: path + '/'
    });
  }
}


function forceTrailingSlashOnChange(prevState, nextState, replace) {
  forceTrailingSlash(nextState, replace);
}

  const [allPages, SetAllPages] = useState([]);
  const [gotData, SetGotData] = useState(false);

  useEffect(() => {
    //ReactGA.pageview(window.location.pathname + window.location.search)
        axios.all([axios.get('wp-json/wp/v2/pages')])
        .then(res => {
          SetAllPages(res[0].data);
          SetGotData(true);
        })
        .catch(err => {

        });
  }, []);

    return (
      <>
        <div role="main" id="wrapper" className={` ${isMobile ? 'is-mobile' : ""} ${BrowserView ? 'browser-view' : ""} ${'os-'+osName.toLowerCase()+' browser-'+browserName.toLowerCase()}`}
             onMouseEnter={handleMove}
             onMouseMove={handleMove}
             onMouseLeave={handleLeave}
             onMouseDown={handleMouseDown}
             onMouseUp={handleMouseUp}
        >
          <Router>
            <ScrollTop />
            <SetFavICon />
            <Header />
            <div id="content">

                <Switch>
                  <Route exact path="/axs-madeo" render={() => (window.location = "https://cms.madeostudio.com/axs-madeo")} />
                  <Redirect from="/branding-workshops/" to="/news/branding-the-online-branding-workshop-for-remote-teams-based-on-3-three-hour-brand-sprint-by-jake-knapp-google-ventures/" />
                  <Redirect from="/innocence-project/" to="/client/innocence-project/" />
                  <Redirect from="/work/eji-segregation-in-america-website/" to="/work/equal-justice-initiative-2019-redesign/" />

                  <Route exact path="/homepage" component={HomePage} />

                  <Route exact path="/careers/:slug" component={CareersSingle} />
                  <Route path="/_preview/careers/:id" component={CareersSingle} />


                  <Route exact path="/work/:slug" component={SingleWork} />
                  <Route path="/_preview/work/:id" component={SingleWork} />
                  <Route path="/project-category/:slug" component={projectCategory} />
                  <Route path="/collection/:slug" component={projectCollections} />
                  <Route path="/client/:slug" component={Client} />

                  <Route path="/patternspage" component={Patterns} />
                  <Route path="/styleguide" component={StyleGuide} />
                  <Route path="/ui" component={UI} />

                  <Route path="/newsfilter" component={NewsFilter} />

                  <Route exact path="/" component={HomePage} />
                  <Route path="/category/:slug" component={Category} />

                  <Route exact path="/news/:slug" component={SingleNews} />
                  <Route path="/_preview/news/:id/" component={SingleNews} />

                  { allPages.length > 0 && allPages.map((pageData, index) => {
                    if(pageData.status == 'publish'){

                      switch(pageData.template){
                        /*case '':{
                            return (
                              <Route exact path={`/${pageData.slug}`} pageSlug={pageData.slug} component={DefaultTemplate} key={index}  />
                            )
                          break;
                        }*/
                        case 'template-homepage.php':{
                          //console.log('homepage temp1: ',pageData.slug);
                          return (
                            <Route exact path={`/${pageData.slug}`} pageSlug={pageData.slug} component={HomePage} key={index}  />
                          )
                            break;
                        }
                        case 'template-about-us.php':{
                            return (
                              <Route exact path={`/${pageData.slug}`} pageSlug={pageData.slug} component={AboutUs} key={index}  />
                            )
                          break;
                        }
                        case 'template-contact.php':{
                            return (
                              <Route exact path={`/${pageData.slug}`} pageSlug={pageData.slug} component={ContactUs} key={index}  />
                            )
                          break;
                          }
                          case 'template-faq.php':{
                              return (
                                <Route exact path={`/${pageData.slug}`} pageSlug={pageData.slug} component={Faq} key={index}  />
                              )
                            break;
                          }
                          case 'template-careers.php':{
                              return (
                                <Route exact path={`/${pageData.slug}`} pageSlug={pageData.slug} component={Careers} key={index}  />
                              )
                            break;
                          }
                          case 'template-work.php':{
                              return (
                                <Route exact path={`/${pageData.slug}`} pageSlug={pageData.slug} component={Work} key={index}  />
                              )
                            break;
                          }
                          case 'template-news.php':{
                              return (
                                <Route exact path={`/${pageData.slug}`} pageSlug={pageData.slug} component={News} key={index}  />
                              )
                            break;
                          }
                      }
                    }
                  })

                  }


                  { allPages.length > 0 && allPages.map((pageData, index) => {

                    switch(pageData.template){
                      case '':{
                          return (
                            <Route exact path={`/_preview/page/${pageData.id}`} component={DefaultTemplate} key={index}  />
                          )
                        break;
                      }
                      case 'template-homepage.php':{
                        //console.log('homepage temp2: ',pageData.slug);
                        return (
                          <Route exact path={`/_preview/page/${pageData.id}`}  component={HomePage} key={index}  />
                        )
                          break;
                      }
                      case 'template-about-us.php':{
                          return (
                            <Route exact path={`/_preview/page/${pageData.id}`}  component={AboutUs} key={index}  />
                          )
                        break;
                      }
                      case 'template-contact.php':{
                          return (
                            <Route exact path={`/_preview/page/${pageData.id}`}  component={ContactUs} key={index}  />
                          )
                        break;
                        }
                        case 'template-faq.php':{
                            return (
                              <Route exact path={`/_preview/page/${pageData.id}`}  component={Faq} key={index}  />                            )
                          break;
                        }
                        case 'template-careers.php':{
                            return (
                              <Route exact path={`/_preview/page/${pageData.id}`}  component={Careers} key={index}  />
                            )
                          break;
                        }
                        case 'template-work.php':{
                            return (
                              <Route exact path={`/_preview/page/${pageData.id}`}  component={Work} key={index}  />
                            )
                          break;
                        }
                        case 'template-news.php':{
                            return (
                              <Route exact path={`/_preview/page/${pageData.id}`}  component={News} key={index}  />
                            )
                          break;
                        }
                    }



                  })

                  }
                  {allPages.length > 0 && <Route path="/:slug" component={DefaultTemplate} />  }
                  {allPages.length > 0 && <Route component={NoMatchPage} />  }


                </Switch>
            </div>

            <Footer />
            <Cursor  />
            <PageTransition />
          </Router>
        </div>

      </>


    );

}


export default App;
/*

<Route path="/_preview/page/:id" component={DefaultTemplate} />

              <Route path="/:slug" component={DefaultTemplate} />
                  <Route path="/about-us" component={AboutUs} />
                  <Route path="/_preview/about-us/:id" component={AboutUs} />

                  <Route path="/faq" component={Faq} />
                  <Route path="/_preview/faq/:id" component={Faq} />

                  <Route exact path="/contact" component={ContactUs} />
                  <Route path="/_preview/contactus/:id" component={ContactUs} />

                  <Route exact path="/careers" component={Careers} />
                  <Route path="/_preview/careers-index/:id" component={Careers} />

                  <Route path="/news/" component={News} />
                  <Route path="/_preview/news-index/:id" component={News} />

                  <Route exact path="/work" component={Work} />
                  <Route path="/_preview/work-index/:id" component={Work} />*/
