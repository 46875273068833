import React from "react"
import { Picture } from 'react-responsive-picture'

import "./../styles/imagecomp.scss";

export default function ImageComp({ img, img_mobile, img2, img2_mobile, type, backgroundClass }) {
  return(
      <section className={`image-section ${backgroundClass ? backgroundClass : ""} ${type==="full" ? "full-image" : ""} ${type==="medium" ? "medium-image" : ""} ${type==="sided" ? "sided-image" : ""}`}>
        <div className={` ${type==="full" ? "container-fluide no-padding" : ""} ${type==="medium" || type==="sided" ? "container" : ""} `}>
          <div className="row">
            <div className={`item ${type==="full" || type==="medium" ? "col-12" : ""} ${ type==="sided" ? "col-md-6" : ""} `}>
                  <Picture
                      sources = {[
                          {
                              srcSet: img,
                              media: "(min-width:576px)",
                          },
                          {
                              srcSet: img_mobile,
                              media: "(max-width:575.98px)",
                          }
                      ]}
                  />
            </div>
            {img2 ? 
              <div className="item col-md-6">
                  <Picture
                      sources = {[
                          {
                              srcSet: img2,
                              media: "(min-width:576px)",
                          },
                          {
                              srcSet: img2_mobile,
                              media: "(max-width:575.98px)",
                          }
                      ]}
                  />
                </div>
              : 
              `` 
            }

          </div>
        </div>
      </section>
  )
  


}

