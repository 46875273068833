import React, { Component, useEffect, useState } from 'react';

import { BrowserView, isMobile, osName, browserName } from "react-device-detect";



import favico from './ui/favicon/fav-1/favicon.ico'
import favicon96x96 from './ui/favicon/fav-1/favicon-96x96.png'
import favicon32x32 from './ui/favicon/fav-1/favicon-32x32.png'
import favicon16x16 from './ui/favicon/fav-1/favicon-16x16.png'
import faviconAdroid192 from './ui/favicon/fav-1/android-icon-192x192.png'
import faviconApple from './ui/favicon/fav-1/apple-icon.png'



import favico_2 from './ui/favicon/fav-2/favicon.ico'
import favicon96x96_2 from './ui/favicon/fav-2/favicon-96x96.png'
import favicon32x32_2 from './ui/favicon/fav-2/favicon-32x32.png'
import favicon16x16_2 from './ui/favicon/fav-2/favicon-16x16.png'
import faviconAdroid192_2 from './ui/favicon/fav-2/android-icon-192x192.png'
import faviconApple_2 from './ui/favicon/fav-2/apple-icon.png'


import favico_3 from './ui/favicon/fav-3/favicon.ico'
import favicon96x96_3 from './ui/favicon/fav-3/favicon-96x96.png'
import favicon32x32_3 from './ui/favicon/fav-3/favicon-32x32.png'
import favicon16x16_3 from './ui/favicon/fav-3/favicon-16x16.png'
import faviconAdroid192_3 from './ui/favicon/fav-3/android-icon-192x192.png'
import faviconApple_3 from './ui/favicon/fav-3/apple-icon.png'

import favico_4 from './ui/favicon/fav-4/favicon.ico'
import favicon96x96_4 from './ui/favicon/fav-4/favicon-96x96.png'
import favicon32x32_4 from './ui/favicon/fav-4/favicon-32x32.png'
import favicon16x16_4 from './ui/favicon/fav-4/favicon-16x16.png'
import faviconAdroid192_4 from './ui/favicon/fav-4/android-icon-192x192.png'
import faviconApple_4 from './ui/favicon/fav-4/apple-icon.png'


function getFaviconEl() {
    return document.getElementById("favicon");
}

function getFavicon_appleEl() {
   return document.getElementById("favicon_apple");
}
function getFavicon_192El() {
    return document.getElementById("favicon_192");
}
function getFavicon_96El() {
    return document.getElementById("favicon_96");
}
function getFavicon_32El() {
    return document.getElementById("favicon_32");
}
function getFavicon_16El() {
    return document.getElementById("favicon_16");
}


export default function FavIcon() {


  const [x, setX] = useState(0);
  /*function handleFavIcon() {
      const favicon = getFaviconEl(); // Accessing favicon element

      const interval = setInterval(() => {

        if( (x + 1) > 4){
          setX(0)
        }else{
          setX(x + 1)
        }

        if(x === 1){
            favicon.href = favico_yellow;
        }
        else if(x === 2){
          favicon.href = favico_blue;
          
        }
        else if(x === 3){
          favicon.href = favico_green;
        }
        else if(x === 4){
         favicon.href = favico_orange;
        }


      }, 1000);


      return () => clearInterval(interval);
  }*/
  useEffect(() => {

      const favicon = getFaviconEl(); // Accessing favicon element
      const favicon_apple = getFavicon_appleEl(); // Accessing favicon element
      const favicon_192 = getFavicon_192El(); // Accessing favicon element
      const favicon_96 = getFavicon_96El(); // Accessing favicon element
      const favicon_32 = getFavicon_32El(); // Accessing favicon element
      const favicon_16 = getFavicon_16El(); // Accessing favicon element

      const interval = setInterval(() => {
        if( (x + 1) > 3){
          setX(0)
        }else{
          setX(x + 1)
        }

        if(x === 0){
            favicon.href = favico;
            favicon_apple.href = faviconApple;
            favicon_192.href = faviconAdroid192;
            favicon_96.href = favicon96x96;
            favicon_32.href = favicon32x32;
            favicon_16.href = favicon16x16;
        }
        else if(x === 1){
            favicon.href = favico_2;
            favicon_apple.href = faviconApple_2;
            favicon_192.href = faviconAdroid192_2;
            favicon_96.href = favicon96x96_2;
            favicon_32.href = favicon32x32_2;
            favicon_16.href = favicon16x16_2;
        }
        if(x === 2){
            favicon.href = favico_3;
            favicon_apple.href = faviconApple_3;
            favicon_192.href = faviconAdroid192_3;
            favicon_96.href = favicon96x96_3;
            favicon_32.href = favicon32x32_3;
            favicon_16.href = favicon16x16_3;
        }
        else if(x === 3){
            favicon.href = favico_4;
            favicon_apple.href = faviconApple_4;
            favicon_192.href = faviconAdroid192_4;
            favicon_96.href = favicon96x96_4;
            favicon_32.href = favicon32x32_4;
            favicon_16.href = favicon16x16_4;
          
        }
        
        /*else if(x === 4){
            favicon.href = favico_saumon;
            //favicon_apple.href = faviconApple_saumon;
            favicon_192.href = faviconAdroid192_saumon;
            favicon_512.href = faviconAdroid512_saumon;
            favicon_32.href = favicon32x32_saumon;
            favicon_16.href = favicon16x16_saumon;
        }*/


      }, 3000);


      return () => clearInterval(interval);
  });

  return null;

}