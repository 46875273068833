import React, {useState} from "react"

import "./styles/pagetransition.scss";

export default function PageTransition({start}) {
    return (
        <div id="page-transition" className="page-transition transition-pole-start" data-start={start}>
            <div id="page-transition-inner" className="row inner-holder background-yellow">
                <div className="pole col-6 col-md-3"></div>
                <div className="pole col-6 col-md-3"></div>
                <div className="pole col-6 col-md-3 d-none d-md-block"></div>
                <div className="pole col-6 col-md-3 d-none d-md-block"></div>
            </div>
        </div> 
    );
}
