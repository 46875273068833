import React from "react"
import PropTypes from "prop-types"
import  useWindowSize from "./hooks/usewindowsize";
import { useMediaQuery } from 'react-responsive';

import "./styles/stickyimages.scss";


export default function StickyImages({ data, backgroundClass }) {

    const { width, height } = useWindowSize();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
    })
    const isTablet = useMediaQuery({
        query: '(max-width: 991.98px) and (min-width: 768px)'
    })
    const isMobile = useMediaQuery({
        query: '(max-width: 767.98px)'
    })


    let imgs = data.images;
    let title = data.side_content;

    return (
        <section className={`stickyimages-section ${backgroundClass ? backgroundClass : ""}`}>
            <div className="container">

                <div className="sticky-parent row">
                    <div className="col-12">
                        {imgs.map((item, key) =>
                            <div className="sticky-image-holder" key={key} style={{zIndex:`${ 100 - key}` ,  bottom: isDesktopOrLaptop ? `-${item.desktop_top_offset}vw` : (isTablet ? `-${item.tablet_top_offset}vw` : `-${item.mobile_top_offset}vw`)}} data-position-desktop={`-${item.desktop_top_offset}vw`} data-position-tablet={`-${item.tablet_top_offset}vw`} data-position-mobile={`-${item.mobile_top_offset}vw`}>
                                <div className="row">
                                    <div className={`${item.classes}`}>
                                        { item.image &&
                                          <div className={`sticky-image`}>
                                              {/*<img src={item.url} key={item.key} />*/}
                                              <picture>
                                                  <source srcSet={`${item.image.url}`} media="(min-width:992px)" />
                                                  <source srcSet={`${item.image.sizes.large}`} media="(min-width:567px)" />
                                                  <source srcSet={`${item.image.sizes.large}`} media="(max-width:566.98px)" />
                                                  <img srcSet={`${item.image.url}`} alt="images alt" />
                                              </picture>
                                          </div>
                                        }
                                    </div>
                                </div>
                            </div>

                        )}
                    </div>
                    { title &&
                      <div className="col-md-10 col-lg-5 col-content">
                          <h5 className="medium-title">{title}</h5>
                      </div>
                    }
                </div>
            </div>
        </section>
    );
}
