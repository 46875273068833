import React, {useState, useRef, useeEffect} from "react"
import ReactPlayer from 'react-player'
//import "./styles/videocomp.scss";
import "./styles/mediacomp.scss";
import useWindowSize from "./hooks/usewindowsize";

export default function ShapeItemVideo({video, video2, type, backgroundClass}){
  const [on, setOn] = useState(true);
  const [on2, setOn2] = useState(true);
  const [wistia, setNotWistea] = useState(true)
  const [wistia2, setNotWistea2] = useState(true)
  const [ratio, setRatio] = useState(0)
  const [ratio2, setRatio2] = useState(0)

  const size = useWindowSize();
  let video1__;
  let video2__;
  const videoSection = useRef(null)
  const videoWrapper = useRef(null)
  const videoWrapper2 = useRef(null)

  React.useEffect(() => {
    const { current: Section } = videoSection
    const { current: videoWrap } = videoWrapper
    const { current: videoWrap2 } = videoWrapper2
    
  })

  function isPreRendering() {
    return navigator.userAgent === 'ReactSnap';
  };

  function handleReady(){
      video1__ = videoWrapper.current.querySelector('video');       
      if( video1__ === null || video1__ === undefined ){
        setNotWistea(true);
      }
      else{
          setNotWistea(false);
          let video_rect = video1__.getBoundingClientRect();
          let video_H = video_rect.height;
          let video_W = video_rect.width;
          setRatio((video_H / video_W))
      }
  }

  function handleReady2(){
      video2__ = videoWrapper2.current.querySelector('video');      
      if( video2__ === null || video2__ === undefined ){
        setNotWistea2(true);
      }
      else{
          setNotWistea2(false);
          let video_rect2 = video2__.getBoundingClientRect();
          let video_H2 = video_rect2.height - 10;
          let video_W2 = video_rect2.width;
         
          setRatio2((video_H2 / video_W2))
      }
      

  }
 
  return(

      <section  ref={videoSection} className={`media-section ${backgroundClass ? backgroundClass : ""} ${type==="full" ? "full-video" : ""} ${type==="medium" ? "medium-video" : ""} ${type==="sided" ? "sided-video" : ""}`}>
        <div className={` ${type==="full" ? "container-fluide no-padding" : ""} ${type==="medium" || type==="sided" ? "container" : ""} `}>
          <div className="row">
            <div className={`item ${type==="full" || type==="medium" ? "col-12" : ""} ${ type==="sided" ? "col-md-6" : ""} `}>
                <div className="player-container" style={{position: "relative"}}>
                    <div className={`player-wrapper ${wistia ? 'others' : 'wistia'} `} ref={videoWrapper} style={{paddingTop: `${ratio * 100}%`  }}>
                      {!isPreRendering() &&
                        <ReactPlayer className='react-player'
                          width='100%'
                          height="100%"
                          url={video} controls={false}
                          playing={on}
                          onReady={handleReady}
                          onPlay={handleReady}
                          muted={true}
                          loop={true}
                          
                        />
                      }
                      <div className="video-overlay" onClick={event => {setOn(!on)}}/>
                    </div>
                </div>
            </div>
            {!isPreRendering() && video2 ?
              <div className="item col-md-6" >
                <div className="player-container" style={{position: "relative"}}>
                    <div className={`player-wrapper ${wistia2 ? 'others' : 'wistia'} `} ref={videoWrapper2} style={{paddingTop: `${ratio2 * 100}%`  }}>
                        <ReactPlayer className='react-player'
                          width='100%'
                          height="100%"
                          url={video2} controls={false}
                          playing={on2}
                          onReady={handleReady2}
                          onPlay={handleReady2}
                          muted={true}
                          loop={true}
                        />
                        <div className="video-overlay" onClick={event => {setOn2(!on2)}}/>
                      </div>
                    </div>
                </div>
              :
              ``
            }
          </div>
        </div>
      </section>

  )
}
