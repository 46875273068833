import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axios from 'axios';
// import ReactGA from 'react-ga'
// import { gtag, install } from 'ga-gtag';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-W48CLF5'
}



// Substitute your tracking ID (begins with "G-", "UA-", "AW-" or "DC-")
// install('G-P46T8372GM'); 

TagManager.initialize(tagManagerArgs)

// ReactGA.initialize('UA-51789447-1',{transport: 'beacon'})

axios.defaults.baseURL = 'https://cms.madeostudio.com';


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
