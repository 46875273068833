import React, {useState, useRef, useEffect} from "react"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
//import { DeferRenderProvider } from 'react-defer-renderer'
import { Helmet } from 'react-helmet'

//import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeIntro from "../components/homeintro"
import WorkNews from "../components/worknews"
import BigList from "../components/biglist"

import LogoSlider from "../components/logoslider"
import CTAwithTitle from "../components/ctawithtitle"
import Shapes from "../components/shapes"
import axios from 'axios'
import md5 from 'md5'
import NoMatchPage from '../pages/404';



export default function HomePage(props) {
  if(props.props){
    props = props.props;
  }
  const path = props.match.path;
  let api_url = '/wp-json/wp/v2/pages/';
  const [axiosArr, setAxiosArr] = useState([]);
  const [newToken, setNewToken] = useState(false);

  const [pageData, setPageData] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState('');


  useEffect(() => {
    if(path.indexOf('_preview') > -1){
      let password = prompt('Password');
      const pageID = path.substr(path.lastIndexOf('/') + 1);
      api_url += pageID+'/revisions/?per_page=1&offset=0';
      if(password){
        axios.all([axios.post('/wp-json/madeo/v1/token',{sec_key:md5(password)})])
          .then(res => {
            let token_res = res[0].data;
            if(token_res.token != undefined){
              setAxiosArr([
                axios.get(api_url,{headers: {authorization: 'Bearer '+token_res.token}})
              ]);
              setNewToken(true);
            }else{
              setNewToken(false);
              setLoad(true);
              setError('404');
            }
            
          })
          .catch(err => {
              setError(err.message);
              setLoad(false)
          })
      }else{
        setNewToken(false);
        setLoad(true);
        setError('404');
      }



    }else{
      let slug = path.substring(1);
      if(slug == ''){
        slug = 'homepage';
      }
      api_url += '?slug='+slug;
      setAxiosArr([
        axios.get(api_url)
      ]);
      setNewToken(true);
    }

    }, [path]);
  

  useEffect(() => {
    if(newToken){
      axios.all(axiosArr)
        .then(res => {
            setPageData(res[0].data[0]);
            setLoad(true);
        })
        .catch(err => {
            setError(err.message);
            setLoad(true)
        })
    }

  }, [newToken, axiosArr]);
let work_news = [];
  if (error) {
    return (
      <Route component={NoMatchPage} />
    )

  }

  if (load && pageData) {

    let seo_data = pageData.yoast_meta;
    let featured_image = pageData.featured_image;

    let seo_title = pageData.yoast_meta.yoast_wpseo_title;
    let seo_head= pageData.yoast_head;
    let acf = pageData.acf;
    let homepageBlock;    
    handleVisibilityChange();
    function handleVisibilityChange() {
      if (document.visibilityState !== "visible") {
        document.getElementById("main-header").classList.add("no-animation");
        homepageBlock = '';
      } else {
        homepageBlock = 'homepage-block';
      }
    }

      return(
          <>
            { seo_data &&
            <SEO data={seo_data} image={featured_image} />
            }
              <Helmet
                  bodyAttributes={{
                      class: `page-home ${homepageBlock} ${acf.background_color} fixed-header`
                  }}
              />

              {acf.intro_text &&
                <HomeIntro data={acf.intro_text} backgroundClass="background-beige" />
              }

              { acf.page_content && acf.page_content.map((sectionData, index) => {
                  switch(sectionData.acf_fc_layout){
                      case 'custom_work_news':{
                          return(
                          <WorkNews data={sectionData.details} backgroundClass="background-beige" showLabel={true} targetPage="homepage" key={index} />
                          )
                          break;
                      }
                      case 'logos_slider':{
                          return(
                            <LogoSlider data={sectionData} backgroundClass="background-saumon" key={index} />
                          )
                          break;
                      }
                      case 'cta_with_title':{
                          return(
                            <CTAwithTitle data={sectionData} backgroundClass="background-saumon" key={index} />
                          )
                          break;
                      }
                      case 'big_list':{
                          return(
                            <BigList data={sectionData} backgroundClass="background-orange" key={index} />
                          )
                          break;
                      }
                      case 'shapes_one_row':{
                          return(
                            <Shapes backgroundClass="" data={sectionData} key={index} />
                          )
                          break;
                      }
                  }


                }
              )}



          </>
      )
  } else {
    return (
      <div style={{height: '100vh'}}></div>
    )
  }

}
