import React from "react"
import { Picture } from 'react-responsive-picture'
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

import "./styles/paragraph.scss";

export default function IntroText({ text, backgroundClass }) {
  function handleMove(e) {
    if(!isMobile){
        const cursor = document.querySelector('.cursor');
        /*console.log(e.target)*/
        if (e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'button' || e.target.tagName.toLowerCase() === 'input') {       cursor.classList.add("large-cirlce")
        }else{
          cursor.classList.remove("large-cirlce")
        }
    }
  }
  function handleLeave(e) {
    if(!isMobile){
        const cursor = document.querySelector('.cursor')
        cursor.classList.remove("large-cirlce")
    }
  }
  return(
      <section className={`introtext-section ${backgroundClass ? backgroundClass : ""}`}
               onMouseEnter={handleMove} 
               onMouseMove={handleMove} 
               onMouseLeave={handleLeave}
      >
        <div className={` container `}>
          <div className="row">
            <div className={` col-xl-10 col-lg-10 col-md-10`}>
                <h3 className="medium-title" dangerouslySetInnerHTML={{ __html: text }} />   
            </div>
          </div>
        </div>
      </section>
  )
  


}

