import React from "react"
import { Link } from 'react-router-dom'
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

import "./styles/ctawithtitle.scss";

export default function CTAwithTitle({ data, backgroundClass}) {
  let title, cta_text, cta_url;

  title = data.title_text;
  if (title) {
    title = title.replace(/(<([^>]+)>)/ig,"");
  }
  cta_text = data.cta_text;
  cta_url = data.cta_url;

  function handleMove(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor');
      /*console.log(e.target)*/
      if (e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'button' || e.target.tagName.toLowerCase() === 'input') {       cursor.classList.add("large-cirlce")
      }else{
        cursor.classList.remove("large-cirlce")
      }
    }
  }
  function handleLeave(e) {
    if(!isMobile){
        const cursor = document.querySelector('.cursor')
        cursor.classList.remove("large-cirlce")
    }
  }

  return(
    <section className={`cta-with-title-section ${backgroundClass ? backgroundClass : ""} `}
       onMouseEnter={handleMove}
       onMouseMove={handleMove}
       onMouseLeave={handleLeave}
      >
        	<div className="container">
          		<div className="row justify-content-center text-center">
            		<div className="col-xl-8 col-lg-10 col-md-10 col-12 cta-holder">
                  { title &&
                    <h3 className="big-title title">
  		            		{title}
  		            	</h3>
                  }
                  { cta_text && cta_url &&
                    <div className="btn-wrap">
  		            		<Link to={cta_url} className="button big-btn blue-btn" > { cta_text } </Link>
  		            	</div>
                  }
          			</div>
          		</div>
        	</div>
      </section>
  )


}
