import React, { Fragment, useState, useEffect } from "react"
import {useSpring, animated, interpolate, config } from "react-spring";
import { isMobile } from "react-device-detect";
import "./styles/topsection.scss";

export function debounce(func, wait = 1, immediate = false) {
  let timeout;
  return function() {
    const context = this;
    const args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const Filter = ({filterData, activeFilter, filterAllPath}) => {
  if(filterData){
    return (
      <ul className="filter-holder">
        <li key={-1}><a className={`button small-btn ${activeFilter == undefined ? "active" : "" }` } href={`/${filterAllPath}`} key={-1} slug={`all`}>{`All`}</a></li>
        {filterData.map((item, i) =>{
          if(activeFilter && activeFilter == item.slug){
            return(
              <li key={item.id}><a className={`button small-btn active`} href={`/${item.taxonomy}/${item.slug}`}  key={item.id} slug={item.slug}>{item.name}</a></li>
            )
          }else{
            return(
              <li key={item.id}><a className={`button small-btn not-active`} href={`/${item.taxonomy}/${item.slug}`}  key={item.id} slug={item.slug}>{item.name}</a></li>
            )
          }

        }
        )}
      </ul>
    );
  }

};

function TopSection({ title, backgroundClass, description, filter, filterData, activeFilter, filterAllPath }) {


  const isDescription = description;
  //const isFilter = filter;
  let descriptionContent, gridCol;
  //filterBar;

  useEffect(() => {
    let pageMainTitle = document.getElementById('page-main-title');
    let headerTitle = document.getElementById('page-title');
    document.getElementById('logo').classList.add('hover-ready');
    //headerTitle.innerHTML = ""+pageMainTitle.innerHTML;
    headerTitle.innerText = ""+pageMainTitle.innerText;
  }, []);

  if(isDescription){
    descriptionContent = <h3 className="medium-title" dangerouslySetInnerHTML={{ __html: description }}  />;
    gridCol = "col-md-10";
  } else {
    gridCol = "col-md-11";
  }

  /*if(isFilter == true){
    //filterBar = <Filter filterData={filterData}  activeFilter={activeFilter} filterAllPath={filterAllPath}/>;
  }*/
  function handleMove(e) {
    if (!isMobile) {
      const cursor = document.querySelector('.cursor');
      /*console.log(e.target)*/
      if (e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'button' || e.target.tagName.toLowerCase() === 'input') {       cursor.classList.add("large-cirlce")
      }else{
        cursor.classList.remove("large-cirlce")
      }
    }
  }
  function handleLeave(e) {
    if (!isMobile) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce")
    }
  }

  // Spring - H1 Animation
  let startPoint = 1;
  const [scrollY, setScrollY] = useState(1);
  useEffect(() => {
    const handleScroll = () => {
      if(window.scrollY < 225){
        startPoint = 0.7 + (((((225 - window.scrollY) / 225 ) * 100) / 100) * 0.3);
      } else {
        startPoint = 0.7;
      }
      setScrollY(startPoint);
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [{ springscrollY }, springsetScrollY] = useSpring(() => ({
    springscrollY: 1
  }));
  springsetScrollY({ springscrollY: scrollY, config: {precision: 0.99} });
  const interpHeader = springscrollY.interpolate(
    o => `scale(${o}) translate3d(0,0,0)`
  );

  return(
      <section className={`top-section ${backgroundClass ? backgroundClass : ""} ${filter ? "with-filter" : "" }`} onMouseEnter={handleMove} onMouseMove={handleMove} onMouseLeave={handleLeave}>
        <div className="container">
          <div className="row">
            <div className={`${gridCol}`}>
                <animated.h1 id="page-main-title" style={{ transform: interpHeader }} className="x-large-title" dangerouslySetInnerHTML={{ __html: title }}  />
                {descriptionContent}
            </div>
          </div>
        </div>
      </section>
  )


}


export default TopSection


//                {filterBar}
