import React, {useRef, useEffect} from "react"
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Picture } from 'react-responsive-picture'
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

import "./styles/paragraph.scss";

export default function Paragraph({ data, ctaButton, ctaUrl, externalLink, backgroundClass, categoriesList}) {

  const section = useRef(null);
  function handleMove(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor');
      /*console.log(e.target)*/
      if (e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'button' || e.target.tagName.toLowerCase() === 'input') {
          cursor.classList.add("large-cirlce")
      }else{
          cursor.classList.remove("large-cirlce")
      }
    }
  }
  function handleLeave(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce")
    }
  }

  function handleIframe(){
     let current_Sec = section.current;
     let iframes = current_Sec.querySelectorAll('iframe')
     if (iframes.length > 0){
        /*for (let i = 0; i < iframes.length; i++) {
            //console.log(iframes[i].src);
            //console.log('split' , iframes[i].src.split("?")[0]);
            //document.write(array);
            //var red = document.getElementById('red');
            let iframeSrc = iframes[i].src.split("?")[0];
            let parent = iframes[i].parentNode;
            let new_div = document.createElement('DIV');
            let iframe_overlay = document.createElement('DIV');
            iframe_overlay.classList.add('iframe-overlay')
            new_div.classList.add('iframe-holder')
            parent.appendChild(new_div);
            //iframes[i].src.split("?")[0]
            new_div.appendChild(iframes[i]);
            new_div.appendChild(iframe_overlay);
            iframe_overlay.addEventListener("click", function() {
                iframeSrc = iframeSrc + "?autoplay=true&muted=true&controls=false";
                iframes[i].src = iframeSrc;
            });

        }*/
     }

  }
   useEffect(() => {
      handleIframe();
  });
  return(
      <section ref={section} className={`paragraph-section ${backgroundClass ? backgroundClass : ""} `}
               onMouseEnter={handleMove}
               onMouseMove={handleMove}
               onMouseLeave={handleLeave}
      >
        <div className={` container `}>
          <div className="row">
            <div className={` ${categoriesList ? "col-lg-8 col-md-9" : "col-xl-8 col-md-10"} `}>
                <div dangerouslySetInnerHTML={{ __html: data }} />
                {externalLink && ctaButton && ctaUrl &&
                  <div className="cta-holder"><a className="button medium-btn blue-btn" target="_blank" href={ctaUrl}>{ctaButton}</a></div>
                }
                {!externalLink && ctaButton && ctaUrl &&
                  <div className="cta-holder"><Link className="button medium-btn blue-btn" to={ctaUrl}>{ctaButton}</Link></div>
                }
            </div>
            {categoriesList &&
              <div className={` col-lg-3 col-md-3 offset-lg-1 `}>
                  <div className="category-list-wrapper">
                    <ul className="cat-list">
                      {categoriesList.map((item, i) => (
                          <li key={i}>
                              {
                                item.category_name
                              }
                          </li>
                      ))}
                    </ul>
                  </div>
              </div>
            }
          </div>
        </div>
      </section>
  )



}
