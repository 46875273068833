import React from "react"
import { Link } from 'react-router-dom'

import "./../styles/ctawithtitle.scss";

export default function CTAwithTitle({ data, backgroundClass }) {
  function handleMove(e) {
        const cursor = document.querySelector('.cursor');
        /*console.log(e.target)*/
        if (e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'button' || e.target.tagName.toLowerCase() === 'input') {       cursor.classList.add("large-cirlce")
        }else{
          cursor.classList.remove("large-cirlce")
        }
    }
    function handleLeave(e) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce")
    }
  return(
      <section className={`cta-with-title-section ${backgroundClass ? backgroundClass : ""}`}
               onMouseEnter={handleMove} 
               onMouseMove={handleMove} 
               onMouseLeave={handleLeave}
      >
          <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-xl-8 col-lg-10 col-md-10 col-12 cta-holder">
                  <h3 className="big-title title">
                    2 to 3 lines introducing the career page of madeo lorem ipsum dolor sit amet, consectetur adipisicing.
                  </h3>
                  <div className="btn-wrap">
                    <Link to="/aboutus" className="button big-btn blue-btn" > Learn More</Link>
                  </div>
                </div>
              </div>
          </div>
      </section>
  )
  

}

