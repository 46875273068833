import React, {useState, useEffect, useRef} from "react";
import Slider from "react-slick";
import ProgressiveImage from 'react-progressive-graceful-image';

import './../styles/largeslider.scss';

import img1 from "./../../images/slider.jpg"
import img2 from "./../../images/slider-1.jpg"
import img3 from "./../../images/slider-2.jpg"
import img4 from "./../../images/slider-3.jpg"


import "./../styles/largeslider.scss";


const List = [
  { index: 0, image: img1  },
  { index: 1, image: img2  },
  { index: 2, image: img3  },
  { index: 3, image: img4  },
]

export default function LargeSlider({ data, backgroundClass, id }) {




  const [activeSlide, setActiveSlide] = useState(0)
  const [autoSpeed, setAutoSpeed] = useState(4000)

  const dotsRef = useRef(null)
  const slider = useRef(null)
  const container = useRef(null)


    useEffect(() => {
      const {current: dotsTrigger } = dotsRef
      const {current: SliderCont } = container
      const {current: sliderRef } = slider
      const interval = setInterval(() => {
        if( (activeSlide + 1) > (List.length - 1)){
        setActiveSlide(0);
        //sliderRef.slickGoTo(0)

        }else{
        setActiveSlide(activeSlide + 1);
        //sliderRef.slickGoTo(activeSlide)
        }

      }, 4000);
      return () => clearInterval(interval);





    });

    const settings = {
      dots: false,
      autoplay: false,
      fade: true,
      pauseOnHover: false,
      arrows: true,
      infinite: true,
      speed: 100,
      cssEase: 'ease',
      draggable: false,
      focusOnSelect: false,
      pauseOnFocus: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: 0,
      className: 'quote-carousel',

      responsive: [
        {
          breakpoint: 768,
          settings: {
            draggable: true,
            dots: true,
            arrows: false,
            //swipeToSlide: true,
            //afterChange: current => setActiveSlide(current)
          }
        },

      ]

    };

   function handleMove(e) {
      const cursor = document.querySelector('.cursor');
      const largeCarousel = document.querySelector(".largeslider-section");

      if( document.querySelector(".slick-dots").contains(e.target) ){
          cursor.classList.remove("arrow-cursor");
          cursor.classList.add("default-cursor");
      }else{
          let x = (e.pageX) - window.pageXOffset;;
          let y = (e.pageY) - window.pageYOffset;

          cursor.classList.remove("yellow-cursor");
          cursor.classList.add("blue-cursor");
          cursor.classList.remove("default-cursor");
          cursor.classList.add("arrow-cursor");

          cursor.style.transform = `translate(-50%, -50%) translate(${x}px, ${y}px)`


          if ( e.pageX <= (window.innerWidth / 2) ){
            cursor.classList.remove("right");
            cursor.classList.add("left");

          }else{
            cursor.classList.remove("left");
            cursor.classList.add("right");
          }
      }
    }

    function handleLeave(e) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("arrow-cursor");
      cursor.classList.add("default-cursor");

    }

    function handleMouseDown(e) {
        const cursor = document.querySelector('.cursor')
        cursor.classList.add("mouse-down");

    }
    function handleMouseUp(e) {
        const cursor = document.querySelector('.cursor')
        cursor.classList.remove("mouse-down");

    }

    function handleNext(){
        if( (activeSlide + 1) > (List.length - 1)){
        setActiveSlide(0);
        }else{
        setActiveSlide(activeSlide + 1);
        }
    }

    function handlePrev(){

        if( (activeSlide - 1) < 0 ){
        setActiveSlide(List.length - 1);
        }else{
        setActiveSlide(activeSlide - 1);
        }
    }


    function handleClick(e){
      //console.log('clicked')
        if ( e.pageX <= (window.innerWidth / 2) ){
          slider.current.slickPrev();

        }else{
          slider.current.slickNext();
        }
    }
  return (

    <section className={`largeslider-section ${backgroundClass ? backgroundClass : ""}`} id={id}>
        <div className="container">
          <div className="row text-center justify-content-center slider-cont" onMouseMove={handleMove} onMouseLeave={handleLeave}>
            <div className="col-12 " ref={container}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMove}
                onMouseLeave={handleLeave}
                onClick={handleClick}
                >
                <Slider {...settings} ref={slider}>
                    {List.map((item, index) => (
                        <div className={`item-content ${activeSlide === index ? "active" : ""}`}
                          key={index} >
                          <div
                            data-swiper-parallax={`40%`}
                            data-swiper-parallax-duration="1000"
                            className="swiper-slide-visible slider-bg image-holder"
                          >
                            <div className="img background-yellow" >
                                <div className="lazy-holder" >
                                  <ProgressiveImage
                                      className="main-image"
                                      delay={500}
                                      src={item.image}
                                      srcSetData={{
                                        srcSet: `${item.image} 320w, ${item.image} 700w, ${item.image} 2000w`,
                                        sizes: '(max-width: 2000px) 100vw, 2000px'
                                      }}
                                      placeholder={item.image}
                                  >
                                    {(src, loading, srcSetData, placeholder) => (
                                          <img style={{ opacity: loading ? 0 : 1, transition: "0.3s ease" }}
                                            src={src}
                                            srcSet={srcSetData.srcSet}
                                            sizes={srcSetData.sizes}
                                            alt="an image"
                                          />
                                      )}


                                  </ProgressiveImage>
                                </div>



                            </div>
                          </div>
                        </div>
                    )
                    )}
                </Slider>
                <button type="button" data-role="none" className="slick-arrow slick-next d-md-block d-none"
                    onClick={handleNext} draggable="true" ondrag={handleNext}
                > </button>

                <button type="button" data-role="none" className="slick-arrow slick-prev d-md-block d-none"
                    onClick={handlePrev} draggable="true" ondrag={handlePrev}
                ></button>
            </div>

            <div className="col-12 d-md-block d-none">
                <ul className="slick-dots" ref={dotsRef}>
                    {List.map((item, index) => (
                        <li className={`${activeSlide === index ? "slick-active" : ""}`}
                        onClick={() => (setActiveSlide(index))}
                        key={index}>
                            <button>1</button>
                        </li>
                      )
                    )}
                </ul>
            </div>
          </div>
        </div>
    </section>



  );
}
//                            data-parallax="scroll"




/*
 <div className="App">
      <div className="content">
        <h2>react-id-swiper parallax demo </h2>
        <p style={{ color: "black" }}>
          Showing parallax with each background image.
        </p>
      </div>
      <div className="slider">
        <Swiper {...params}>
          <div>
          That slides with '' class would have the parallax working when you add data-swiper-parallax="" for example.


            <div
              data-swiper-parallax={`40%`}
              data-swiper-parallax-duration="4000"
              className=" swiper-slide-visible slider-bg"
              data-parallax="scroll"
              style={{
                backgroundImage: `url('https://source.unsplash.com/collection/2091539/1000x500')` , height: "500px"
              }}
            />
          </div>
          <div>
            <div
              data-swiper-parallax={`40%`}
              data-swiper-parallax-duration="4000"
              data-parallax="scroll"
              className="swiper-slide-visible slider-bg"

              style={{
                backgroundImage: `url('https://source.unsplash.com/collection/2091539/1000x400)`, height: "500px"
              }}
            />
          </div>
          <div>
            <div
              data-swiper-parallax={`40%`}
              data-swiper-parallax-duration="4000"
              data-parallax="scroll"
              className="swiper-slide-visible slider-bg"
              style={{
                backgroundImage: `url('https://source.unsplash.com/collection/2091539/1000x450')` , height: "500px"
              }}
            />
          </div>
        </Swiper>
      </div>
    </div>*/
