import React, { useState, useEffect, useRef } from 'react';

import useInterval from "./hooks/useinterval";
import Slider from "react-slick";

import { useTransition, useSpring, animated, config } from 'react-spring';

import "./styles/logoslider.scss";


function LogoSlider({ data, backgroundClass, two_rows }) {

    const [counter2, setCounter2] = useState(8);
    const [counter, setCounter] = useState(4);
    const [x, setX] = useState(1)
    //const [y, setY] = useState(0)
    const [oldX, setoldX] = useState(1)
    const [oldX2, setoldX2] = useState(1)
    /// =====================
    let delay = 2000;

    const [ex0 , setEx0 ] = useState(0)
    const [ex1 , setEx1 ] = useState(1)
    const [ex2 , setEx2 ] = useState(2)
    const [ex3 , setEx3 ] = useState(3)
    const [ex4 , setEx4 ] = useState(4)
    const [ex5 , setEx5 ] = useState(5)
    const [ex6 , setEx6 ] = useState(6)
    const [ex7 , setEx7 ] = useState(7)

    // this in ms
    let delaybetweenImages = 0;
    ///// ==========================

    let delayBasic = 0;
    let imagesDelay = delayBasic + delaybetweenImages;



    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const ref4 = useRef(null)
    const ref5 = useRef(null)
    const ref6 = useRef(null)
    const ref7 = useRef(null)
    const ref8 = useRef(null)

    let images = data.logos_slider;
    let description_text = data.description_text;

    if (description_text) {
      description_text = description_text.replace(/(<([^>]+)>)/ig,"");
    }

    useEffect(() => {
      const { current: item1 } = ref1
      const { current: item2 } = ref2
      const { current: item3 } = ref3
      const { current: item4 } = ref4
      const { current: item5 } = ref5
      const { current: item6 } = ref6
      const { current: item7 } = ref7
      const { current: item8 } = ref8


      let image1 = item1.querySelector('img')
      let image2 = item2.querySelector('img')
      let image3 = item3.querySelector('img')
      let image4 = item4.querySelector('img')
      let image5 = item5.querySelector('img')
      let image6 = item6.querySelector('img')
      let image7 = item7.querySelector('img')
      let image8 = item8.querySelector('img')

      let size = images.length - 1
      let imgSrc = ''


      const interval = setInterval(() => {
        if( two_rows === "true" || two_rows){
            imgSrc = images[counter2].logo
            if(x === 1){
                setEx0(counter2)
                setCounter2( generateRandom_position2(0, size, counter2, ex1, ex2, ex3, ex4, ex5, ex6, ex7) )
                //console.log("counter2", counter2)
                setX(3);
                item1.classList.add("out")
                item1.classList.remove("in")
                setTimeout(function(){
                    image1.src = imgSrc
                }, delayBasic);

                setTimeout(function(){
                    item1.classList.remove("out")
                    item1.classList.add("in")
                }, imagesDelay);
                //setX(generateRandom_position(2, 8));
            }
            else if(x === 2){
                  setEx1(counter2)
                  setCounter2( generateRandom_position2(0, size, ex0, counter2, ex2, ex3, ex4, ex5, ex6, ex7) )
                  setX(7);
                  item2.classList.add("out")
                  item2.classList.remove("in")
                  setTimeout(function(){
                      image2.src = imgSrc
                  }, delayBasic);

                  setTimeout(function(){
                      item2.classList.remove("out")
                      item2.classList.add("in")
                  }, imagesDelay);

            }
            else if(x === 3){
              setEx2(counter2)
              setCounter2( generateRandom_position2(0, size, ex0, ex1, counter2, ex3, ex4, ex5, ex6, ex7) )
              setX(8)
              item3.classList.add("out")
              item3.classList.remove("in")
              setTimeout(function(){
                  image3.src = imgSrc
              }, delayBasic);

              setTimeout(function(){
                  item3.classList.remove("out")
                  item3.classList.add("in")
              }, imagesDelay);
              //setX(generateRandom_position(4, 8))

            }
            else if(x === 4){
              setEx3(counter2)
              setCounter2( generateRandom_position2(0, size, ex0, ex1, ex2, counter2, ex4, ex5, ex6, ex7) )
              //console.log("counter2", counter2)
              setX(1)
              item4.classList.add("out")
              item4.classList.remove("in")
              setTimeout(function(){
                  image4.src = imgSrc
              }, delayBasic);

              setTimeout(function(){
                  item4.classList.remove("out")
                  item4.classList.add("in")
              }, imagesDelay);


              //setX(generateRandom_position(5, 8))

            }
            else if(x === 5){
              setEx4(counter2)
              setCounter2( generateRandom_position2(0, size, ex0, ex1, ex2, ex3, counter2, ex5, ex6, ex7) )
              setX(2);
              item5.classList.add("out")
              item5.classList.remove("in")
              setTimeout(function(){
                  image5.src = imgSrc
              }, delayBasic);

              setTimeout(function(){
                  item5.classList.remove("out")
                  item5.classList.add("in")
              }, imagesDelay);
            }
            else if(x === 6){
              setEx5(counter2)
              setCounter2( generateRandom_position2(0, size, ex0, ex1, ex2, ex3, ex4, counter2, ex6, ex7) )
              setX(4);
              item6.classList.add("out")
              item6.classList.remove("in")
              setTimeout(function(){
                  image6.src = imgSrc
              }, delayBasic);

              setTimeout(function(){
                  item6.classList.remove("out")
                  item6.classList.add("in")
              }, imagesDelay);

            }
            else if(x === 7){
              setEx6(counter2)
              setCounter2( generateRandom_position2(0, size, ex0, ex1, ex2, ex3, ex4, ex5, counter2, ex7) )
              //console.log("counter2", counter2)
              setX(6)
              item7.classList.add("out")
              item7.classList.remove("in")
              setTimeout(function(){
                  image7.src = imgSrc
              }, delayBasic);

              setTimeout(function(){
                  item7.classList.remove("out")
                  item7.classList.add("in")
              }, imagesDelay);

            }
            else if(x === 8){
              setEx7(counter2)
              setCounter2( generateRandom_position2(0, size, ex0, ex1, ex2, ex3, ex4, ex5, ex6, counter2) )
              //console.log("counter2", counter2)
              setX(5)
              item8.classList.add("out")
              item8.classList.remove("in")
              setTimeout(function(){
                  image8.src = imgSrc
              }, delayBasic);

              setTimeout(function(){
                  item8.classList.remove("out")
                  item8.classList.add("in")
              }, imagesDelay);

            }
            imgSrc = images[counter2].logo
        }else{
            imgSrc = images[counter].logo
            if(x === 1){
                setEx0(counter)
                setCounter( generateRandom_position1(0, size, counter, ex1, ex2, ex3) )
                item1.classList.add("out")
                item1.classList.remove("in")
                setTimeout(function(){
                    image1.src = imgSrc
                }, delayBasic);
                setTimeout(function(){
                    item1.classList.remove("out")
                    item1.classList.add("in")
                }, imagesDelay);
                setX(generateRandom_position(2, 4))
            }

            else if(x === 2){
                setEx1(counter)
                setCounter( generateRandom_position1(0, size, ex0, counter, ex2, ex3) )
                item2.classList.add("out")
                item2.classList.remove("in")
                setTimeout(function(){
                    image2.src = imgSrc
                }, delayBasic);

                setTimeout(function(){
                    item2.classList.remove("out")
                    item2.classList.add("in")
                }, imagesDelay);
                setX(generateRandom_position(3, 4));
            }

            else if(x === 3){
              setEx2(counter)
              setCounter( generateRandom_position1(0, size, ex0, ex1, counter, ex3) )
              item3.classList.add("out")
              item3.classList.remove("in")
              setTimeout(function(){
                  image3.src = imgSrc
              }, delayBasic);
              setTimeout(function(){
                  item3.classList.remove("out")
                  item3.classList.add("in")
              }, imagesDelay);
              setX(generateRandom_position(1, 2))
            }


            else if(x === 4){
              setEx3(counter)
              setCounter( generateRandom_position1(0, size, ex0, ex1, ex2, counter) )
              item4.classList.add("out")
              item4.classList.remove("in")
              setTimeout(function(){
                  image4.src = imgSrc
              }, delayBasic);

              setTimeout(function(){
                  item4.classList.remove("out")
                  item4.classList.add("in")
              }, imagesDelay);
              setX(generateRandom_position(1, 3))
            }
            imgSrc = images[counter].logo
        }
      }, delay);


      return () => clearInterval(interval);
    });




    function generateRandom_position(min, max) {
        let rtn = Math.floor(Math.random() * (max - min + 1)) + min;
        //let rtn = Random.Range(min, max)
        //return (rtn === num1 || rtn === num2) ? generateRandom_position(min, max, num1) : rtn;
        return rtn;


    }


    function generateRandom_position1(min, max, ex0, ex1, ex2, ex3) {
        let rtn = Math.floor(Math.random() * (max - min + 1)) + min;
        //let rtn = Random.Range(min, max)
        return (rtn === ex0 || rtn === ex1 || rtn === ex2 || rtn === ex3) ? generateRandom_position1(min, max, ex0, ex1, ex2, ex3) : rtn;
        return rtn;

    }

    function generateRandom_position2(min, max, ex0, ex1, ex2, ex3, ex4, ex5, ex6, ex7) {
        let rtn = Math.floor(Math.random() * (max - min + 1)) + min;
        //let rtn = Random.Range(min, max)
        return (rtn === ex0 || rtn === ex1 || rtn === ex2 || rtn === ex3 || rtn === ex4 || rtn === ex5 || rtn === ex6 || rtn === ex7) ? generateRandom_position2(min, max, ex0, ex1, ex2, ex3, ex4, ex5, ex6, ex7) : rtn;
        return rtn;

    }


    return (
      <section className={`logoslider-section ${description_text ? 'have-title' : ''} ${backgroundClass ? backgroundClass : ""}`}>
        {description_text ?
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-12">
                <h2 className="section-title medium-title">
                  {description_text}
                </h2>
              </div>
            </div>
          </div>
          :
          ``
        }
        <div className="container">
              <div className={`logos-row row`}>
                <div className="col-md-3 col-6 item" ref={ref1} key={0}>
                    <img className={`logo-- logo-0`} src={images[0].logo} alt="logo 1" />
                </div>
                <div className="col-md-3 col-6 item" ref={ref2}  key={1}>
                    <img className={`logo-- logo-1`} src={images[1].logo} alt="logo 2" />
                </div>
                <div className="col-md-3 col-6 item" ref={ref3}  key={2}>
                    <img className={`logo-- logo-2`} src={images[2].logo} alt="logo 3" />
                </div>
                  <div className="col-md-3 col-6 item" ref={ref4}  key={3}>
                    <img className={`logo-- logo-3`} src={images[3].logo} alt="logo 4" />
                </div>
              </div>
              {two_rows ?
                <div className={`logos-row logos-row-2 row`}>
                  <div className="col-md-3 col-6 item" ref={ref5} key={4}>
                      <img className={`logo-- logo-4`} src={images[4].logo} alt="logo 1" />
                  </div>
                  <div className="col-md-3 col-6 item" ref={ref6}  key={5}>
                      <img className={`logo-- logo-5`} src={images[5].logo} alt="logo 2" />
                  </div>
                  <div className="col-md-3 col-6 item" ref={ref7}  key={6}>
                      <img className={`logo-- logo-6`} src={images[6].logo} alt="logo 3" />
                  </div>
                    <div className="col-md-3 col-6 item" ref={ref8}  key={7}>
                      <img className={`logo-- logo-7`} src={images[7].logo} alt="logo 4" />
                  </div>
                </div>
                :
                <div className="d-none">
                  <div className="col-md-3 col-6 item" ref={ref5} key={4}>
                      <img className={`logo-- logo-4`} alt="logo 1" />
                  </div>
                  <div className="col-md-3 col-6 item" ref={ref6}  key={5}>
                      <img className={`logo-- logo-5`} alt="logo 2" />
                  </div>
                  <div className="col-md-3 col-6 item" ref={ref7}  key={6}>
                      <img className={`logo-- logo-6`} alt="logo 3" />
                  </div>
                    <div className="col-md-3 col-6 item" ref={ref8}  key={7}>
                      <img className={`logo-- logo-7`} alt="logo 4" />
                  </div>
                </div>
              }
        </div>
    </section>
    );

}


export default LogoSlider
