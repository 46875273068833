import React, { useState } from "react"
import { Link } from 'react-router-dom'
import AccordionItem from './accordionitem';
import { isMobile } from "react-device-detect";

import "./styles/faq.scss";

export default function FAQ({ data, title, backgroundClass }) {
  const [activeIndex, setActiveIndex] = useState(-1);

  let items = data;




  function handleMove(e) {
    if (!isMobile) {
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
    }
  }
  function handleLeave(e) {
    if (!isMobile) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce")
    }
  }

  let index = 1;

  return (
    <section className={`faq-section ${backgroundClass ? backgroundClass : ""}`} id="faq_accordion">
        <div className="container">
          <div className="row">
            <div className="col-12">
            <h1 className="x-large-title main-title" dangerouslySetInnerHTML={{ __html: title }}  />
            </div>
            <div className="col-12">
              { items && items.map((patternData, index) => (
                <div className={`item ${activeIndex === index ? 'active': ''}` } key={index}>
                      <button className={`big-title item-toggler ${backgroundClass} ${activeIndex === index ? 'active': ''}` }
                      onClick={event => {
                              setActiveIndex(
                              activeIndex === index ? -1 : index
                              )
                              //console.log(activeIndex)
                            }}
                      onMouseEnter={handleMove}
                      onMouseMove={handleMove}
                      onMouseLeave={handleLeave}
                      >
                          {patternData.faq_title}
                          <span className="plus-icon" />
                      </button>
                      <AccordionItem isOpened={activeIndex === index}>
                        {
                          patternData.faq_details && patternData.faq_details.map((pattern, index) => {
                            switch(pattern.acf_fc_layout){
                              case 'faq_description':{
                                let description = pattern.faq_description;//.replace(/(<([^>]+)>)/ig,"");
                                  return(
                                    <div key={index} className="faq-inner" dangerouslySetInnerHTML={{ __html: description}} />
                                  )
                                  break;
                              }
                              case 'faq_link':{
                                  let external = pattern.external_link;
                                  let faq_button_text = pattern.faq_button_text;
                                  let faq_button_url = pattern.faq_button_url;

                                  if (external) {
                                    return (
                                      <div key={index} className="cta-holder">
                                        <a className="button medium-btn blue-btn" target="_blank" href={faq_button_url}
                                           onMouseEnter={handleMove}
                                           onMouseMove={handleMove}
                                           onMouseLeave={handleLeave}>
                                          {faq_button_text}
                                        </a>
                                      </div>
                                  )

                                  } else {

                                    return (
                                      <div className="cta-holder">
                                        <Link className="button medium-btn blue-btn" to={faq_button_url}
                                           onMouseEnter={handleMove}
                                           onMouseMove={handleMove}
                                           onMouseLeave={handleLeave}>
                                          {faq_button_text}
                                        </Link>
                                      </div>
                                  )

                                  }
                                  break;
                              }
                            }
                          })
                        }
                      </AccordionItem>
                  </div>
              ))}
            </div>
          </div>
        </div>
    </section>
  );
};

//export default Accordion
