import React from "react"
import { Helmet } from 'react-helmet'

import useDelayRouteExit from "delay-react-route-exit";

//import Layout from "../components/layout"
// import SEO from "../components/seo"

export default function StyleGuide(){
   return(
  <>
    <Helmet
          bodyAttributes={{
              class: `StyleGuide`
          }}

    />
     <div className="container">
        <div className="mt-5 mb-5"></div>
            <div className="row" style={{height: "300px"}}>
                <div className="col color-box background-white" >
                    <span>White<br />#FFFFFF</span>
                </div>
                <div className="col color-box background-beige">
                    <span>Beige <br />#F7F2E7</span>
                </div>
                <div className="col color-box background-yellow">
                    <span>Yellow<br />#FFF8A4</span>
                </div>
                <div className="col color-box background-saumon">
                    <span>Peach<br />#FFD4A5</span>
                </div>
                <div className="col color-box background-orange">
                    <span>Orange<br />#FF9D68</span>
                </div>
                <div className="col color-box background-green">
                    <span>Green<br />#21CE99</span>
                </div>
                <div className="col color-box background-blue">
                    <span>Blue<br />#184EAC</span>
                </div>
                <div className="col color-box background-black color-white">
                    <span>Black<br />#000000</span>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    <h1 className="x-large-title mb-5">XLarge Heading</h1>
                    <h2 className="large-title mb-5">Large Heading</h2>
                    <h3 className="big-title mb-5">Big Heading</h3>
                    <h4 className="medium-title mb-5">Medium Heading</h4>
                    <h5 className="small-title mb-5">Small Heading</h5>

                    


                    <p className="p-big mb-5">Large Text:<br />
                        Lorem ipsum dolor sit amet, <a className="text-link"href="#">consectetur adipisicing</a> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <p className="mb-5">Medium Text:
                        <br /> Lorem ipsum dolor sit amet, consectetur <a className="text-link"href="#">consectetur adipisicing</a>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.</p>
                    <p className="p-caption mb-5">Caption: <br />
                        Lorem ipsum dolor sit amet, consectetur sed do tempor.</p>
                        <br />
                    <ul className="default-ul">
                        <li>
                            <b>Lorem ipsum dolor sit amet,</b> consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            <ul>
                                <li>
                                    SubList 1Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
                                </li>
                                <li>
                                    SubList 2
                                </li>
                                <li>
                                    SubList 3
                                </li>
                            </ul>
                        </li>
                        <li>List 2</li>
                        <li>List 3</li>
                        <li>List 4</li>
                    </ul>
                    <br />
                    <ol className="default-ol">
                        <li>
                            <b>Lorem ipsum dolor sit amet,</b> consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        </li>
                        <li>List 2</li>
                        <li>List 3</li>
                        <li>List 4</li>
                    </ol>
                    <br />
                    <blockquote className="blockquote">
                        <p>
                           <b>Lorem ipsum dolor sit amet,</b> consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        </p>
                    </blockquote>
                </div>
            </div>

            <div className="mt-5 mb-5"></div>
            <div className="mt-5 mb-5"></div>

     </div>

  </>
   )
}
