import React, { useState, useEffect, Fragment } from "react"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
//import Layout from "../components/layout"
import SEO from "../components/seo"
import TopSection from "../components/topsection"
import SidedCTA from "../components/sidedcta"
import ContactInfo from "../components/contactinfo"
import LargeSlider from "../components/largeslider"
import Shapes from "../components/shapes"


import "../components/styles/contact.scss"

import axios from 'axios'
import md5 from 'md5'
import NoMatchPage from '../pages/404';

export default function ContactUs(props) {
  // console.log(props);
  const path = props.match.path;
  let api_url = '/wp-json/wp/v2/pages/';
  const [axiosArr, setAxiosArr] = useState([]);
  const [newToken, setNewToken] = useState(false);

  const [data, setdata] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState('');



  useEffect(() => {
    if(path.indexOf('_preview') > -1){
      let password = prompt('Password');
      const pageID = path.substr(path.lastIndexOf('/') + 1);
      api_url += pageID+'/revisions/?per_page=1&offset=0';
      if(password){
        axios.all([axios.post('/wp-json/madeo/v1/token',{sec_key:md5(password)})])
          .then(res => {
            let token_res = res[0].data;
            if(token_res.token != undefined){
              setAxiosArr([
                axios.get(api_url,{headers: {authorization: 'Bearer '+token_res.token}})
              ]);
              setNewToken(true);
            }else{
              setNewToken(false);
              setLoad(true);
              setError('404');
            }

          })
          .catch(err => {
              setError(err.message);
              setLoad(false)
          })
      }else{
        setNewToken(false);
        setLoad(true);
        setError('404');
      }


    }else{
      api_url += '?slug='+path.substring(1);
      setAxiosArr([
        axios.get(api_url)
      ]);
      setNewToken(true);
    }

    }, [path]);



  useEffect(() => {
    if(newToken){
      axios.all(axiosArr)
        .then(res => {
            setdata(res[0].data[0].acf);
            setPageData(res[0].data[0]);
            setLoad(true);
        })
        .catch(err => {
            setError(err.message);
            setLoad(true)
        })

    }
  }, [newToken, axiosArr]);


  let title, background_color, patterns, sided_cta, large_slider, contact_information, shapesData;

  if (error) {
    return (
      <Route component={NoMatchPage} />
    )

  }

  if (load) {
    let seo_data = pageData.yoast_meta;
    let featured_image = pageData.featured_image;

    title = data.title;
    background_color = data.background_color;
    patterns = data.page_content;

    // console.log(patterns)

    { patterns.map(item => {

      switch (item.acf_fc_layout) {

        case 'sided_cta':
          sided_cta = item.sided_cta
          break;

        case 'large_slider':
          large_slider = item.large_slider;
          break;

        case 'large_slider':
          large_slider = item.large_slider;
          break;

        case 'contact_information':
          contact_information = item;
          break;

        case 'shapes_two_rows':
          shapesData = item;
      }

    })}

    function isPreRendering() {
      return navigator.userAgent === 'ReactSnap';
    };

    let preRender = isPreRendering();

    return(
      <>
        { seo_data &&
        <SEO data={seo_data} image={featured_image} />
        }
        <Helmet
              bodyAttributes={{
                  class: `page-contact ${background_color} fixed-header`
              }}
        />


        { !preRender &&
         <>
          { title &&
            <TopSection title={title} backgroundClass={background_color} />
          }

          { sided_cta &&
            <SidedCTA data={sided_cta} backgroundClass="background-yellow" />
          }

          { large_slider &&
            <LargeSlider data={large_slider} backgroundClass="background-saumon" />
          }

          { contact_information &&
            <ContactInfo data={contact_information} backgroundClass="background-saumon" />
          }

          { shapesData &&
            <Shapes data={shapesData} />
          }
        </>
       }

      </>
    )

  } else {
    return (
      <div style={{height: '100vh'}}></div>
    )
  }

}
