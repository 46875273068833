import React, {useState, useRef, useeEffect} from "react"
import ReactPlayer from 'react-player'
import "./styles/mediacomp.scss";
import useWindowSize from "./hooks/usewindowsize";

export default function ShapeItemVideo({item1, image1Mobile, item1Type, item2, image2Mobile, item2Type, layout, backgroundClass}){
  const [on, setOn] = useState(true);
  const [on2, setOn2] = useState(true);
  const [wistia, setNotWistea] = useState(true)
  const [wistia2, setNotWistea2] = useState(true)
  const [ratio, setRatio] = useState(0)
  const [ratio2, setRatio2] = useState(0)

  const size = useWindowSize();
  let video1__;
  let video2__;
  const videoSection = useRef(null)
  const videoWrapper = useRef(null)
  const videoWrapper2 = useRef(null)

  React.useEffect(() => {
    const { current: Section } = videoSection
    const { current: videoWrap } = videoWrapper
    const { current: videoWrap2 } = videoWrapper2

  })

  function isPreRendering() {
    return navigator.userAgent === 'ReactSnap';
  };

  function handleReady(){
      video1__ = videoWrapper.current.querySelector('video');
      if( video1__ === null || video1__ === undefined ){
        setNotWistea(true);
      }
      else{
          setNotWistea(false);
          let video_rect = video1__.getBoundingClientRect();
          let video_H = video_rect.height;
          let video_W = video_rect.width;
          setRatio((video_H / video_W))
      }
  }

  function handleReady2(){
      video2__ = videoWrapper2.current.querySelector('video');
      if( video2__ === null || video2__ === undefined ){
        setNotWistea2(true);
      }
      else{
          setNotWistea2(false);
          let video_rect2 = video2__.getBoundingClientRect();
          //let video_H2 = video_rect2.height - 10;
          let video_H2 = video_rect2.height;
          let video_W2 = video_rect2.width;

          setRatio2((video_H2 / video_W2))

          //console.log('video-rect', video_rect2)
      }


  }

  return(

      <section  ref={videoSection} className={`media-section ${backgroundClass ? backgroundClass : ""} ${layout==="full" ? "full-media" : ""} ${layout==="medium" ? "medium-media" : ""} ${layout==="sided" ? "sided-media" : ""}`}>
        <div className={` ${layout==="full" ? "container-fluide no-padding" : ""} ${layout==="medium" || layout==="sided" ? "container" : ""} `}>
          <div className="row">

            <div className={`item ${layout==="full" || layout==="medium" ? "col-12" : ""} ${ layout==="sided" ? "col-md-6" : ""} `}>
            {item1Type === "video" ?
                <div className="player-container" style={{position: "relative"}}>
                    <div className={`player-wrapper ${wistia ? 'others' : 'wistia'} `} ref={videoWrapper} style={{paddingTop: `${ratio * 100}%`  }}>
                      {!isPreRendering() &&
                        <ReactPlayer className='react-player'
                          width='100%'
                          height="100%"
                          url={item1} controls={false}
                          playing={on}
                          onReady={handleReady}
                          onPlay={handleReady}
                          muted={true}
                          loop={true}

                        />
                      }
                      <div className="video-overlay" onClick={event => {setOn(!on)}}/>
                    </div>
                </div>
                :
                <picture>
                  <source srcSet={`${item1}`} media="(min-width:768px)" />
                  <source srcSet={`${image1Mobile}`} media="(max-width:767.98px)" />
                  <img srcSet={`${item1}`} alt="img" />
                </picture>
            }

            </div>
            {item2 ?
              <div className="item col-md-6" >
                {item2Type === "video" ?
                  <div className="player-container" style={{position: "relative"}}>
                    <div className={`player-wrapper ${wistia2 ? 'others' : 'wistia'} `} ref={videoWrapper2} style={{paddingTop: `${ratio2 * 100}%`  }}>
                    {!isPreRendering() &&
                      <ReactPlayer className='react-player'
                        width='100%'
                        height="100%"
                        url={item2} controls={false}
                        playing={on2}
                        onReady={handleReady2}
                        onPlay={handleReady2}
                        muted={true}
                        loop={true}
                      />
                    }
                    <div className="video-overlay" onClick={event => {setOn2(!on2)}}/>
                    </div>
                  </div>
                  :
                  <picture>
                    <source srcSet={`${item2}`} media="(min-width:768px)" />
                    <source srcSet={`${image2Mobile}`} media="(max-width:767.98px)" />
                    <img srcSet={`${item2}`} alt="img" />
                  </picture>
                }
              </div>
              :
              ""
            }

          </div>
        </div>
      </section>

  )
}
