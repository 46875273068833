import React, { useState } from "react"
import { Link } from 'react-router-dom'
import { isMobile } from "react-device-detect";

import "./styles/accordion.scss";
import AccordionItem from './accordionitem';
import { getLineAndCharacterOfPosition } from "typescript";


export default function Accordion({data, posts ,departments, title, backgroundClass}) {

  const [activeIndex, setActiveIndex] = useState(-1);
  let postCount = 0;
  function handleMove(e) {
    if (!isMobile) {
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
    }
  }
  function handleLeave(e) {
    if (!isMobile) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce")
    }
  }

  return (
    
    <section className={`accordion-section ${backgroundClass ? backgroundClass : ""}`} id="accordion__">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="title small-title">{data.main_title}</h3>
            </div>
            <div className="col-12">
                {departments.map((item, index) => (  postCount = 0,

                    <div className={`accordion-item ${activeIndex === index ? 'active': ''}` } key={index}>
                        <button className={`x-large-title accordion-toggler ${backgroundClass} ${activeIndex === index ? 'active': ''}` }
                        onClick={event => {
                                setActiveIndex(
                                activeIndex === index ? -1 : index
                                )
                              }}
                        onMouseEnter={handleMove}
                        onMouseMove={handleMove}
                        onMouseLeave={handleLeave}
                        >
                            {item.name}
                            
                            {posts.map((post, index) => {
                              if (post.department[0] == item.id) {  postCount += 1 }
                                
                            })}
                            
                            <sup>{postCount}</sup>    
                            <span className="plus-icon" />
                        </button>
                        <AccordionItem isOpened={activeIndex === index}>
                            { item.description &&
                              <p className="accordion-desc" dangerouslySetInnerHTML={{ __html: item.description }} />
                            }
                            <ul className="job-list">
                            {posts.map((post, index) => {
                              if (post.department[0] == item.id) {
                                return (
                                  <li key={index}>
                                  <Link to={"/"+ post.type + "/" + post.slug}
                                          onMouseEnter={handleMove}
                                          onMouseMove={handleMove}
                                          onMouseLeave={handleLeave}
                                    >
                                      <div className="row">
                                        <h4 className="col-xl-5 col-lg-5 col-md-6 col-sm-8 col-12 job-title medium-title" dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
                                        { post.acf.job_type &&
                                          <p className="col-xl-7 col-lg-7 col-md-6 col-sm-4 col-12" dangerouslySetInnerHTML={{ __html: post.acf.job_type }} />
                                        }
                                      </div>
                                    </Link>
                                  </li>
                                )
                              }
                            })}
                            </ul>
                        </AccordionItem>
                    </div>

                ))
              }
            </div>
          </div>
        </div>
    </section>
  );
};

//export default Accordion
