import React, { useState, useEffect, useCallback, useRef } from "react";
import 'intersection-observer'
import ProgressiveImage from 'react-progressive-graceful-image';
import { Link } from 'react-router-dom' //useHistory, useLocation
import axios from 'axios';
import "./styles/worknews.scss"
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";


const UpdateSessionData = ({dataOffset}) => {
    sessionStorage.setItem('last_scroll_position', window.scrollY);
    sessionStorage.setItem('last_path_name', window.location.pathname);
    sessionStorage.setItem('last_items_count', document.querySelectorAll('.worknews-section .item').length);
    if(dataOffset != ''){
      sessionStorage.setItem('last_data_offset', dataOffset);
    }
    //console.log('last_scroll_position: ',sessionStorage.getItem('last_scroll_position'));
    //console.log('last_path_name: ',sessionStorage.getItem('last_path_name'));
    //console.log('last_data_offset: ',sessionStorage.getItem('last_data_offset'));
}




const Filter = ({filterData, activeFilter, filterAllPath, onFilterItemClick}) => {
  function handleMove(e) {
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
  }
  function handleLeave(e) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce");
  }
  return (
    <ul className="filter-holder">
      <li key={-1}><a className={`button small-btn filterbtn ${activeFilter == undefined ? "active" : "" }` }
      onClick={onFilterItemClick.bind(this)} href={`#`} key={-1} data-index-path={`/${filterAllPath}`} data-slug={`all`} data-id={`all`}>{`All`}</a></li>
      {filterData.map((item, i) =>{
        if(activeFilter && activeFilter == item.slug){
          return(
            <li key={item.id}><a
            onMouseEnter={handleMove}
            onMouseMove={handleMove}
            onMouseLeave={handleLeave}
            onClick={onFilterItemClick.bind(this)}
            className={`button small-btn active filterbtn`} href={`#`}
            data-posts-count={item.count}
            data-index-path={`/${item.taxonomy}/${item.slug}`}
            key={item.id} data-slug={item.slug} data-id={item.id}>{item.name}</a></li>
          )
        }else{
          return(
            <li key={item.id}><a
            onMouseEnter={handleMove}
            onMouseMove={handleMove}
            onMouseLeave={handleLeave}
            onClick={onFilterItemClick.bind(this)}
            className={`button small-btn not-active filterbtn`} href={`#`}
            data-posts-count={item.count}
            data-index-path={`/${item.taxonomy}/${item.slug}`}
            key={item.id} data-slug={item.slug} data-id={item.id}>{item.name}</a></li>
          )
        }

      }
      )}
    </ul>
    /*{onFilterItemClick.bind(this, item.slug, item.id)}*/
  );
};

export default function WorkNews({ data, backgroundClass, targetPage, catId, filter, filterData, activeFilter, client_exclude, collections }){ //work, client
  //targetPage {homepage, news, work, client}
  const loadmore_ref = useRef(null);
  let itemsPerPage = 4;
  let initOffset = 4;
  let enableLoadMore = false;
  let loadMoreApiURL;
  let total;
  let List = data;
  let buttonLabel = 'More ';
  let taxFilter = '';
  let filterAllPath = '';
  let filterTaxonomy = '';
  let excludeClientFilter = '';
  let pageInfoObj = {};
  

  switch(targetPage){
    case 'work':{
      enableLoadMore = true;
      itemsPerPage = 8;
      initOffset = 8;
      buttonLabel += 'Projects';
      loadMoreApiURL = '/wp-json/wp/v2/projects?orderby=menu_order&order=asc&hide_password=true&';
      // if(client_exclude != ''){
      //   loadMoreApiURL = '/wp-json/wp/v2/projects?orderby=menu_order&order=asc'+client_exclude+'&';
      // }else{
      //   loadMoreApiURL = '/wp-json/wp/v2/projects?orderby=menu_order&order=asc&';
      // }
      if(client_exclude != ''){
        excludeClientFilter = client_exclude+'&';
      }
      List = data.data;
      total = data.headers['x-wp-total'];
      if(catId){
        taxFilter = '&set_as_client_page=no&project-category='+catId
      }
      if(collections){
        taxFilter = '&set_as_client_page=no&collections='+collections
      }
      filterAllPath = 'work';
      filterTaxonomy = 'project-category';

      break;
    }
    case 'news':{
      enableLoadMore = true;
      buttonLabel += 'News';
      List = data.data;
      total = data.headers['x-wp-total'];
      loadMoreApiURL = '/wp-json/wp/v2/posts?hide_password=true&';
      excludeClientFilter = '';
      if(catId){
        taxFilter = '&categories='+catId
      }
      filterAllPath = 'news';
      filterTaxonomy = 'categories';
      itemsPerPage = 12;
      initOffset = 12;
      break;
    }
    case 'client':{
      enableLoadMore = false;
      List = data.data;
      break;
    }
  }

  if(enableLoadMore && itemsPerPage >= total ){
    enableLoadMore = false;
  }

  const [loadedData, SetLoadedData] = useState(List);
  const [offset, SetOffset] = useState(initOffset);
  const [totalLoadedData, SetTotalLoadedData] = useState(0);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [totalRenderedItems, setTotalRenderedItems] = useState(0);
  const [loadedItems, setLoadedItems] = useState();

  function handleMove(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
    }
  }
  function handleLeave(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce");
    }
  }

  function CheckBackToPosition(renderedItemsCount){
    let last_path_name = sessionStorage.getItem('last_path_name');
    let last_scroll_pos = sessionStorage.getItem('scroll_after_load_data');
    let last_loaded_item_count = parseInt(sessionStorage.getItem('last_items_count'));
    //console.log('totalRenderedItems: ', renderedItemsCount);
    if(last_path_name != undefined){
      
      //let current_loaded_item_count = document.querySelectorAll('.worknews-section .item .holder-loaded').length;
      //console.log('inside checkBackToPosition', last_scroll_pos);
      //console.log('inside checkBackToPosition, last_loaded_item_count', last_loaded_item_count);
      //console.log('inside checkBackToPosition, current_loaded_item_count', renderedItemsCount);
      if(last_loaded_item_count == renderedItemsCount){
        window.scrollTo(0, last_scroll_pos);
      }
      
    }
  }

  // const history = useHistory();
  // //const loadLocation = useLocation();
  // useEffect(() => {
  //     return history.listen((location) => {
        
  //     })
  // },[history]);

  
  // useEffect(() => {
  //   loadMorePosts();
  // }, []);

  function loadMorePosts(url, dataOffset){
    UpdateSessionData(dataOffset);

    loadmore_ref.current.blur();
    axios.get(url).then(res => {
      if(res.data.length > 0){
        if(dataOffset == 0){
          List = res.data;
        }else{
          List = loadedData.concat(res.data);
        }
        setImgLoaded(false);
        setLoadedItems(false);
        SetLoadedData(List);
        let totalItems = List.length;
        total = res.headers['x-wp-total'];
        SetTotalLoadedData(totalItems);
        SetOffset(totalItems);
        if(total == totalItems && enableLoadMore == true ){
          enableLoadMore = false;
          loadmore_ref.current.classList.add('d-none');
        }else if(total > totalItems){
          loadmore_ref.current.classList.add('d-none');
          window.setTimeout(() => {
              loadmore_ref.current.classList.remove('d-none');
          }, 100)
        }
        window.setTimeout(() => {
            document.querySelectorAll('.item-wrapper').forEach(function(item){ item.classList.remove('change-img') });
            setLoadedItems(true);
        }, 150);   
      }else{
        enableLoadMore = false;
        loadmore_ref.current.classList.add('d-none');
      }

    });

  };

  function handleLoadMore(e) {
    //console.log('cliced')
    enableLoadMore = true;
    e.preventDefault();
    if(enableLoadMore && total >= itemsPerPage ){
      let activeFilterBtn = document.querySelector('.filterbtn.active');
      if(activeFilterBtn && activeFilterBtn.getAttribute('data-slug') != 'all'){
        total = activeFilterBtn.getAttribute('data-posts-count');
        taxFilter = '&'+filterTaxonomy+'='+activeFilterBtn.getAttribute('data-id');
        if(targetPage = 'work'){
          taxFilter += '&set_as_client_page=no';
          excludeClientFilter = '';
        }
      }else{
        taxFilter = '';
        if(client_exclude != ''){
          excludeClientFilter = '&'+client_exclude;
        }
      }
      if(collections){
        taxFilter = '&set_as_client_page=no&collections='+collections
      }
      loadMorePosts(loadMoreApiURL+'offset='+offset+excludeClientFilter+'&per_page='+itemsPerPage+taxFilter, offset);
    }
    return false;
  }

  function OnFilterItemClick(e){
    e.preventDefault();
    let element = e.target;
    let slug = element.getAttribute('data-slug');
    let termId = element.getAttribute('data-id');
    let indexPath = element.getAttribute('data-index-path');
      
    if(!element.classList.contains('active')){
      pageInfoObj.last_category_id = document.querySelector('.filterbtn.active').getAttribute('data-id');
      pageInfoObj.current_category_id = termId;
      pageInfoObj.last_dataoffset = 0;
      pageInfoObj.last_pathname = document.querySelector('.filterbtn.active').getAttribute('data-index-path');
      pageInfoObj.current_pathname = indexPath;
      sessionStorage.setItem('pageInfo', pageInfoObj);
      //console.log('pageInfo: ',sessionStorage.getItem('pageInfo'));
      
      document.querySelectorAll('.filterbtn').forEach(function(filterBtnEle){ filterBtnEle.classList.remove('active') });
      document.querySelectorAll('.item-wrapper').forEach(function(item){ item.classList.add('change-img') });
      element.classList.add('active');
      window.history.pushState('', null, indexPath);
      if(slug != 'all'){
        total = element.getAttribute('data-posts-count');
        taxFilter = '&'+filterTaxonomy+'='+termId;
        if(targetPage == 'work'){
          taxFilter += '&set_as_client_page=no';
          excludeClientFilter = '';
        }
       }else{
        taxFilter = '';
        if(client_exclude != ''){
          excludeClientFilter = '&'+client_exclude;
        }
       }
      
      
      initOffset = 0;
      loadMorePosts(loadMoreApiURL+'offset='+initOffset+excludeClientFilter+'&per_page='+itemsPerPage+taxFilter, 0);
    }
    return false;
  }

  return (
        <section className={`worknews-section ${backgroundClass ? backgroundClass : ""}`}>
            <div className="container">
              {filter ?
                  <div className="row">
                    <div className="col-lg-11">
                      <Filter filterData={filterData} filterAllPath={filterAllPath} onFilterItemClick={OnFilterItemClick} activeFilter={activeFilter}/>
                    </div>
                  </div>
                  :
                  ``
                }
              <RenderPosts loadedData={loadedData} backgroundClass={backgroundClass} targetPage={targetPage} holderImgState={imgLoaded} renderedCounter={totalRenderedItems}/>

                { targetPage == 'work' ||targetPage == 'news'  ?
                <div className={ `row justify-content-center load-more-btn ${enableLoadMore ? "" : "d-none"}`} ref={loadmore_ref}>
                  <div className="col-auto">
                    <a className="button big-btn blue-btn load-more" href="#"
                      onMouseEnter={handleMove}
                      onMouseMove={handleMove}
                      onMouseLeave={handleLeave}
                      onClick={handleLoadMore}
                    >{buttonLabel}</a>
                  </div>
                </div>
                :
                ``
                }
            </div>
        </section>
      )

}

function RenderPosts({loadedData, backgroundClass, targetPage, holderImgState, renderedCounter}){
  useEffect(() => {
  //console.log('counter '+ document.querySelectorAll('.worknews-section .item').length +" scroll postion: "+sessionStorage.getItem('last_scroll_position'));
  if(sessionStorage.getItem('last_items_count') == document.querySelectorAll('.worknews-section .item').length){
    window.scrollTo(0, sessionStorage.getItem('last_scroll_position'));
  }
  });
  return(
<div className="row align-items-center">
              {loadedData.map((item, i) => {
                if(targetPage == 'homepage'){
                  return (
                    <HomePageCustomItem item={item} bg={backgroundClass} key={i} />
                  )
                }else{
                  return (
                    <MyItem item={item} id={item.id} bg={backgroundClass} key={i} targetPage={targetPage} holderImgState={holderImgState} renderedCounter={renderedCounter}/>
                  )
                }

              })}
              

            </div>
  )
}

function CutoffSrting({str, length}){
  if(str.length <= length){
      return str;
  }
  // let cut_str = str.slice(0, length);
  // return cut_str+'...';
  var trimmedString = str.substr(0, length);
  trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))+'...';
  return trimmedString;
}

function MyItem({item, bg, id, targetPage, holderImgState, renderedCounter}){
  //console.log(holderImgState);
  // init ref
  const containerRef = React.useRef(null)
  const triggerRef = React.useRef(null)
  const [imgLoaded, setImgLoaded] = useState(holderImgState);
  const [totalRenderedItems, setTotalRenderedItems] = useState(renderedCounter);
  const imgLoadClass = imgLoaded == true && 'holder-loaded';
  let height = 0;
  //console.log(imgLoadClass);

  //const isWorkItem = workItem;
  //const isClientPage = clientPage;


  React.useEffect(() => {
    const { current: containerElement } = containerRef
    const { current: triggerElement } = triggerRef
  })


  function handleMove(e) {
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
  }
  function handleLeave(e) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce");
  }

  

  function handleItemClick(e){
    UpdateSessionData('');
    return true;
  }

  function handleExternalLink( e){
    //e.preventDefault();
    //console.log('e', e);
    //window.open(external_url, '_blank');
    return false;
  }


  if(targetPage == 'work' && item.acf.set_as_client_page == true && item.acf.client_page){
      item.path = '/client/'+item.acf.client_page.slug;
  } else if (targetPage == 'client' || targetPage=='work') {
      item.path = '/work/'+item.slug;
  } else {
      item.path = '/news/'+item.slug;
  }

  const externalLink = item.acf.external_link;
  if(externalLink){
    item.path = externalLink;
  }
  //console.log('external link: ', externalLink+', '+item.path);
  // console.log(item);

    return (
      <div className={`col-md-6 item ${ targetPage=="work" && item.acf.project_label ? 'work-item' : `` }`}>
        <div  className={`item-wrapper ${imgLoadClass} load-more--result`}>
          { item.featured_image && !externalLink &&
          <Link to={item.path}  onMouseEnter={handleMove}
            onMouseMove={handleMove}
            onMouseLeave={handleLeave} onClick={handleItemClick}  target={externalLink ? '_blank' : '_self'}> 
            

            <div  className={`${item.acf.lazy_load_background ? item.acf.lazy_load_background : ""} image-wrapper`} ref={containerRef} style={{height: "auto",}} >
                <img className="placeholder-img" src={item.featured_image_thumbnail} alt={item.featured_image_alt} onLoad={() => setTimeout(function(){setImgLoaded(true);  setTotalRenderedItems(totalRenderedItems+1);}, 150)}/>
                <div className="lazy-holder" >
                  <ProgressiveImage
                      className="main-image"
                      delay={500}
                      src={item.featured_image}
                      srcSetData={{
                        srcSet: `${item.featured_image} 320w, ${item.featured_image} 700w, ${item.featured_image} 2000w`,
                        sizes: '(max-width: 2000px) 100vw, 2000px'
                      }}
                      placeholder={item.featured_image_thumbnail}
                  >
                    {(src, loading, srcSetData, placeholder) => (
                          <img style={{ opacity: loading ? 0 : 1, transition: "0.3s ease" }}
                            src={src}
                            srcSet={srcSetData.srcSet}
                            sizes={srcSetData.sizes}
                            alt={item.featured_image_alt}
                          />
                      )}


                  </ProgressiveImage>
                </div>
                {item.primary_category.name ? <span className={`label ${bg}`}>{item.primary_category.name}</span> : `` }

            </div>
            
            </Link>
          }
          { item.featured_image && externalLink &&
          <a href={item.path}  onMouseEnter={handleMove}
            onMouseMove={handleMove}
            onMouseLeave={handleLeave} onClick={handleItemClick}  target={externalLink ? '_blank' : '_self'}> 
            

            <div  className={`${item.acf.lazy_load_background ? item.acf.lazy_load_background : ""} image-wrapper`} ref={containerRef} style={{height: "auto",}} >
                <img className="placeholder-img" src={item.featured_image_thumbnail} alt={item.featured_image_alt} onLoad={() => setTimeout(function(){setImgLoaded(true);  setTotalRenderedItems(totalRenderedItems+1);}, 150)}/>
                <div className="lazy-holder" >
                  <ProgressiveImage
                      className="main-image"
                      delay={500}
                      src={item.featured_image}
                      srcSetData={{
                        srcSet: `${item.featured_image} 320w, ${item.featured_image} 700w, ${item.featured_image} 2000w`,
                        sizes: '(max-width: 2000px) 100vw, 2000px'
                      }}
                      placeholder={item.featured_image_thumbnail}
                  >
                    {(src, loading, srcSetData, placeholder) => (
                          <img style={{ opacity: loading ? 0 : 1, transition: "0.3s ease" }}
                            src={src}
                            srcSet={srcSetData.srcSet}
                            sizes={srcSetData.sizes}
                            alt={item.featured_image_alt}
                          />
                      )}


                  </ProgressiveImage>
                </div>
                {item.primary_category.name ? <span className={`label ${bg}`}>{item.primary_category.name}</span> : `` }

            </div>
            
            </a>
          }
            {targetPage=='work' && item.acf.project_label  ? <div className={`client-label ${bg}`}>
              <p>{item.acf.project_label}</p></div> : `` }
              { !externalLink ?
                <Link to={item.path}   onMouseEnter={handleMove}
                      onMouseMove={handleMove}
                      onMouseLeave={handleLeave} target={externalLink ? '_blank' : '_self'}>

              <div className={`titles ${item.acf.summary || item.acf.project_summary ? 'with-summary' : ""}`}>
                <h2 className="medium-title title" dangerouslySetInnerHTML={{ __html: item.title.rendered }} />
                {item.acf && item.acf.summary &&
                <p className="p-big desc">
                  <CutoffSrting str={item.acf.summary} length={124} />
                </p>
                }
                {item.acf && item.acf.project_summary &&
                <p className="p-big desc">
                  <CutoffSrting str={item.acf.project_summary} length={124} />
                </p>
                }
              </div>
              </Link>
              :
              <a href={item.path}   onMouseEnter={handleMove}
              onMouseMove={handleMove}
              onMouseLeave={handleLeave} target={externalLink ? '_blank' : '_self'}>

                <div className={`titles ${item.acf.summary || item.acf.project_summary ? 'with-summary' : ""}`}>
                  <h2 className="medium-title title" dangerouslySetInnerHTML={{ __html: item.title.rendered }} />
                  {item.acf && item.acf.summary &&
                  <p className="p-big desc">
                    <CutoffSrting str={item.acf.summary} length={124} />
                  </p>
                  }
                  {item.acf && item.acf.project_summary &&
                  <p className="p-big desc">
                    <CutoffSrting str={item.acf.project_summary} length={124} />
                  </p>
                  }
                </div>
                </a>
              }
        </div>
      </div>
    )


}


function HomePageCustomItem({item, bg}){
  // init ref
  const containerRef = React.useRef(null)
  const triggerRef = React.useRef(null)

  React.useEffect(() => {
    const { current: containerElement } = containerRef
    const { current: triggerElement } = triggerRef
  })



  function handleMove(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
    }
  }
  function handleLeave(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce");
    }
  }

  if(item != null){
    item.path = item.link_url;
    const externalLink = item.external_link;
    // console.log(item)
    // console.log(item.path)

    if (!externalLink) {
      return (
        <div className={`col-md-6 item ${item.label ? 'work-item' : `news-item` }`}>
          <div  className={`item-wrapper`}>
            { item.image &&

                <Link to={item.path}
                onMouseEnter={handleMove}
                onMouseMove={handleMove}
                onMouseLeave={handleLeave}
                target={externalLink ? '_blank' : '_self'}>

                  <div  className={`${item.loading_background_color ? item.loading_background_color : ""} image-wrapper`} ref={containerRef}>

                  <img className="placeholder-img" src={item.image.sizes.thumbnail} alt={item.featured_image_alt} />
                    <div className="lazy-holder">
                      <ProgressiveImage
                          className="main-image"
                          delay={500}
                          src={item.featured_image}
                          srcSetData={{
                            srcSet: `${item.image.url} 320w, ${item.image.url} 700w, ${item.image.url} 2000w`,
                            sizes: '(max-width: 2000px) 100vw, 2000px'
                          }}
                          placeholder={item.image.sizes.thumbnail}
                      >
                        {(src, loading, srcSetData, placeholder) => (
                              <img style={{ opacity: loading ? 0 : 1, transition: "0.3s ease" }}
                                src={src}
                                srcSet={srcSetData.srcSet}
                                sizes={srcSetData.sizes}
                                alt={item.featured_image_alt}
                              />
                          )}


                      </ProgressiveImage>
                    </div>

                  {item.label ? <span className={`label ${bg}`}>{item.label}</span> : `` }
                </div>
              </Link>
              }

              <Link to={item.path}
              onMouseEnter={handleMove}
              onMouseMove={handleMove}
              onMouseLeave={handleLeave}
              target={externalLink ? '_blank' : '_self'}>
                <div className={`titles ${item.summary || item.summary ? 'with-summary' : ""}`}>
                  <h2 className="medium-title title" dangerouslySetInnerHTML={{ __html: item.title }} />
                  {item.summary &&
                  <p className="p-big desc">
                    <CutoffSrting str={item.summary} length={124} />
                  </p>
                  }
                </div>
              </Link>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`col-md-6 item ${item.label ? 'work-item' : `news-item` }`}>
          <div  className={`item-wrapper`}>
            { item.image &&

                <a href={item.path}
                onMouseEnter={handleMove}
                onMouseMove={handleMove}
                onMouseLeave={handleLeave}
                target={externalLink ? '_blank' : '_self'}>

                <div  className={`${item.loading_background_color ? item.loading_background_color : ""} image-wrapper`} ref={containerRef}>

                <img className="placeholder-img" src={item.image.sizes.thumbnail} alt={item.featured_image_alt} />
                  <div className="lazy-holder">
                    <ProgressiveImage
                        className="main-image"
                        delay={500}
                        src={item.featured_image}
                        srcSetData={{
                          srcSet: `${item.image.url} 320w, ${item.image.url} 700w, ${item.image.url} 2000w`,
                          sizes: '(max-width: 2000px) 100vw, 2000px'
                        }}
                        placeholder={item.image.sizes.thumbnail}
                    >
                      {(src, loading, srcSetData, placeholder) => (
                            <img style={{ opacity: loading ? 0 : 1, transition: "0.3s ease" }}
                              src={src}
                              srcSet={srcSetData.srcSet}
                              sizes={srcSetData.sizes}
                              alt={item.featured_image_alt}
                            />
                        )}


                    </ProgressiveImage>
                  </div>

                  {item.label ? <span className={`label ${bg}`}>{item.label}</span> : `` }
                </div>
              </a>
              }

              <a href={item.path}
              onMouseEnter={handleMove}
              onMouseMove={handleMove}
              onMouseLeave={handleLeave}
              target={externalLink ? '_blank' : '_self'}>
                <div className={`titles ${item.summary || item.summary ? 'with-summary' : ""}`}>
                  <h2 className="medium-title title" dangerouslySetInnerHTML={{ __html: item.title }} />
                  {item.summary &&
                  <p className="p-big desc">
                    <CutoffSrting str={item.summary} length={124} />
                  </p>
                  }
                </div>
              </a>
          </div>
        </div>
      );
    }


  }else{
    return '';
  }
}

function HomePageItem({item, bg}){
  // init ref
  const containerRef = React.useRef(null)
  const triggerRef = React.useRef(null)
  const [homeItem, setHomeItem] = useState(null);
  if(item.post_type == 'post'){
    item.post_type = 'posts';
  }
  let getFullItemURL = '/wp-json/wp/v2/'+item.post_type+'/'+item.ID;

  React.useEffect(() => {
    if(item.post_type == 'post'){
        item.post_type = 'posts';
    }

    axios.get(getFullItemURL).then(res => {
      setHomeItem(res.data);
    });
  }, []);

  React.useEffect(() => {
    const { current: containerElement } = containerRef
    const { current: triggerElement } = triggerRef
  })



  function handleMove(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
    }
  }
  function handleLeave(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce");
    }
  }

  if(homeItem != null){
    item = homeItem;
    item.path = item.link.replace('https://cms.madeostudio.com', '');
    item.path = item.path.slice(0, -1);
    const externalLink = item.acf.external_link;
    if(externalLink){
      item.path = externalLink;
    }
    return (
      <div className={`col-md-6 item ${item.acf.project_label ? 'work-item' : `news-item` }`}>
        <div  className={`item-wrapper`}>
          { item.featured_image &&
            <Link to={item.path}
            onMouseEnter={handleMove}
            onMouseMove={handleMove}
            onMouseLeave={handleLeave}
            target={externalLink ? '_blank' : '_self'}>

              <div  className={`${item.acf.lazy_load_background ? item.acf.lazy_load_background : ""} image-wrapper`} ref={containerRef}>

              <img className="placeholder-img" src={item.featured_image_thumbnail} alt={item.featured_image_alt} />
                <div className="lazy-holder">
                  <ProgressiveImage
                      className="main-image"
                      delay={500}
                      src={item.featured_image}
                      srcSetData={{
                        srcSet: `${item.featured_image} 320w, ${item.featured_image} 700w, ${item.featured_image} 2000w`,
                        sizes: '(max-width: 2000px) 100vw, 2000px'
                      }}
                      placeholder={item.featured_image_thumbnail}
                  >
                    {(src, loading, srcSetData, placeholder) => (
                          <img style={{ opacity: loading ? 0 : 1, transition: "0.3s ease" }}
                            src={src}
                            srcSet={srcSetData.srcSet}
                            sizes={srcSetData.sizes}
                            alt={item.featured_image_alt}
                          />
                      )}


                  </ProgressiveImage>
                </div>

                {item.primary_category ? <span className={`label ${bg}`}>{item.primary_category.name}</span> : `` }
              </div>
            </Link>
            }
            {item.acf.project_label ? <div className={`client-label ${bg}`}>
              <p>{item.acf.project_label}</p></div> : `` }
              <Link to={item.path}   onMouseEnter={handleMove}
            onMouseMove={handleMove}
            onMouseLeave={handleLeave}  target={externalLink ? '_blank' : '_self'}>

              <div className={`titles ${item.acf.summary || item.acf.project_summary ? 'with-summary' : ""}`}>
                <h2 className="medium-title title" dangerouslySetInnerHTML={{ __html: item.title.rendered }} />
                {item.acf && item.acf.summary &&
                <p className="p-big desc">
                  {item.acf.summary}
                </p>
                }
                {item.acf && item.acf.project_summary &&
                <p className="p-big desc">
                  {item.acf.project_summary}
                </p>
                }
              </div>
            </Link>
        </div>
      </div>
    )

  }else{
    return '';
  }
}
