import React,{ useState, useEffect, Component } from "react"

//import Layout from "../components/layout"
import SEO from "../components/seo"

import TopSection from "../components/topsection"
import WorkNews from "../components/worknews"
import Shapes from "../components/shapes"
import { Helmet } from 'react-helmet'

import axios from 'axios'


export default function  Category(props)  {

  function isEmpty(obj) {
      for(var key in obj) {
          if(obj.hasOwnProperty(key))
              return false;
      }
      return true;
  }

  let catSlug;


    if (!isEmpty(props)) {
      catSlug = props.match.params.slug;
    } else {
      catSlug = window.location.pathname;
      catSlug = catSlug.replace('/category/', '');
    }
    const [pageData, setPageData] = useState([]);
    const [newsData, setNewsData] = useState([]);
    const [newsCatData, setNewsCatData] = useState([]);
    const [catData, setCatData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {

        axios.all([
        axios.get('/wp-json/wp/v2/pages?slug=news'),
        axios.get('/wp-json/wp/v2/categories/?slug='+catSlug),
        axios.get('/wp-json/wp/v2/categories/?hide_empty=true')
        ])
            .then(res => {
                setPageData(res[0].data[0]);
                setCatData(res[1].data[0]);
                setNewsCatData(res[2].data);
                let catId = res[1].data[0].id;
                axios.get('/wp-json/wp/v2/posts?offset=0&per_page=100&hide_password=true&categories='+catId).then(result => {
                    setNewsData(result);
                    setLoad(true);
                })

            })
            .catch(err => {
                setError(err.message);
                setLoad(true)
            })


    }, []);

    if (error) {
        return (
        <div style={{height: '100vh'}}></div>
        )

    }

    if (load) {
        let seo_title = pageData.yoast_meta.yoast_wpseo_title;
        let seo_head= pageData.yoast_head;
        let acf = pageData.acf;


        let seo_data = pageData.yoast_meta;
        let featured_image = pageData.featured_image;
        function isPreRendering() {
            return navigator.userAgent === 'ReactSnap';
          };
      
          let preRender = isPreRendering();

        return(
            <>
                <SEO data={seo_data} image={featured_image} />
                <Helmet
                    bodyAttributes={{
                        class: `page-news ${acf.background_color} fixed-header`
                    }}
                />

                {  !preRender && 
                <>
                { acf.title &&
                    <TopSection title={acf.title}
                    description={acf.description}
                    backgroundClass={acf.background_color} filter={true} activeFilter={catSlug} filterAllPath={`news`}/>
                }

                { acf.page_content && acf.page_content.map((sectionData, index) => {
                    switch(sectionData.acf_fc_layout){
                        case 'news_data':{
                            return(
                            <WorkNews catId={catData.id} data={newsData} backgroundClass="background-beige" filter={true} filterData={newsCatData} activeFilter={catSlug} filterAllPath={`news`} targetPage="news" showLabel={true} key={index}/>
                            )
                            break;
                        }
                        case 'shapes':{
                            return(
                            <Shapes data={sectionData} rows="1" key={index}/>
                            )
                            break;
                        }
                    }


                    }
                )}
                </>
                }


            </>
        )
    }else {
        return (
          <div style={{height: '100vh'}}></div>
        )
      }
}
