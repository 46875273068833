import React, { useState, useEffect, useRef } from "react"
import { Link, useHistory, useLocation } from 'react-router-dom'
import Headroom from 'react-headroom'
import { Container, Row, Col } from 'react-bootstrap'
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import  useWindowSize from "./hooks/usewindowsize";
import Menu from "./../components/Menu"
import useScroll from "./hooks/usescroll"
import axios from 'axios'
import ReactGA from 'react-ga'

import "./styles/header.scss";


const Header = () => {
  const { scrollX, scrollY, scrollDirection } = useScroll();
  const [on, toggle] = useState(false);
  const pointerEvents = on ? "all" : "none";
  const [scrollState, SetscrollState] = useState(false);
  const menuState = on ? "menu-active" : "";
  const { width, height } = useWindowSize();
  let bodyLock, menuHolder, scrollPosition, pageMainTitle, pageMainTitleRect, headerScrollReady, titleStart = 1090;
  if(width >=1200){
    titleStart = 1000;
  } else {
    titleStart = 900;
  }

  function handleMove(e) {
    if (!isMobile) {
      const cursor = document.querySelector('.cursor');
      if (e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'button' || e.target.tagName.toLowerCase() === 'input') {       cursor.classList.add("large-cirlce")
      }else{
        cursor.classList.remove("large-cirlce")
      }
    }
  }
  function handleLeave(e) {
    if (!isMobile) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce")
    }
  }
  const [primaryMenu, setprimaryMenu] = useState([]);
  const [instagram, setinstagram] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState('');
  const headerRef = useRef(null);
  const history = useHistory();
  const loadLocation = useLocation();
  const [leaving, setLeave] = useState(false);
  const unblock = history.block((location, action) => {});
  let allowRouteLeave = false;

  useEffect(() => {
      if(loadLocation.pathname !== "/"){
        document.getElementById("main-header").classList.add("no-animation");
        document.getElementById("logo").classList.remove("title-view");
        disable(0);
      } else {
        document.getElementById("main-header").classList.add("home-animation");
      }
      return history.listen((location) => {
        //ReactGA.pageview(location.pathname + location.search)

        const page = location.pathname + location.search;
        ReactGA.set({ page });
        ReactGA.pageview(page);

        if(location.pathname !== "/"){
          document.getElementById("main-header").classList.add("no-animation");
          document.getElementById("main-header").classList.remove("home-animation");
          document.getElementById("main-header").classList.remove("animated");
          document.getElementById("logo").classList.remove("title-view");
          disable(0);
        } else {
          document.getElementById("main-header").classList.add("no-animation", "transparent");
          //document.getElementById("main-header").classList.add("home-animation");
          //document.getElementById("main-header").classList.remove("no-animation");
        }

       // console.log('location listener: ', JSON.stringify(location));
        //console.log('last_scroll_position: ',sessionStorage.getItem('last_scroll_position'));
        //console.log('last_path_name: ',sessionStorage.getItem('last_path_name'));
        //console.log('last_data_offset: ',sessionStorage.getItem('last_data_offset'));

        let current_pathname = location.pathname;
        let last_pathname = sessionStorage.getItem('last_path_name');
        let last_scroll_pos = sessionStorage.getItem('last_scroll_position');
        if(last_pathname !== undefined && (current_pathname == last_pathname) ){
          //scroll to last position
          //console.log('inside scroll', last_scroll_pos);
          sessionStorage.setItem('scroll_after_load_data', last_scroll_pos);
          //window.scrollTo(0, parseInt(last_scroll_pos));
        }

      })
  },[history]);

  useEffect(() => {
    const {current: HeaderHolder} = headerRef;
    
    
      HeaderHolder.addEventListener('click', function(e){
        // console.log(e.target)
            if (e.target.tagName.toLowerCase() === 'a'){
              let currentHref = ""+e.target.href,
                  currentPath = ""+window.location.pathname,
                  currentPage,
                  time = 0;
              if((currentPath.substring(currentPath.lastIndexOf("/") + 1)).length > 1) {
                currentPage = currentPath.substring(currentPath.lastIndexOf("/") + 1);
              } else {
                currentPage = currentPath.split("/");
                currentPage = currentPage[ parseInt(currentPage.length - 2, 10)];
              }
              if(currentHref.substring(currentHref.lastIndexOf("/") + 1) !== currentPage ){
                if((""+e.target.id == "logo" && currentPath !== "/") || (currentHref.includes("about") || currentHref.includes("who-we-are") || currentHref.includes("about-us") || currentHref.includes("work") || currentHref.includes("news") || currentHref.includes("careers") || currentHref.includes("faq") || currentHref.includes("contact")) && currentPage !== "#"){
                  time = 1000;
                  setTimeout(function(){
                    toggle(false)
                  }, time);
                  return history.block((location, action) => {
                    if (!allowRouteLeave) {
                      setLeave(true);
                      allowRouteLeave = true;
                      setTimeout(function () {
                        if (action === "PUSH") {
                          history.push(location.pathname + location.search);
                        } else if (action === "REPLACE") {
                          History.replace(location.pathname + location.search);
                        }
                        allowRouteLeave = false;
                        unblock();
                        return leaving;
                      }, 1400);
                      return false;
                    }
                  });
                }
              } else {
                toggle(false);
              }
            } return;  
      });

    axios.all([
      axios.get('/wp-json/menus/v1/menus/2'),
      axios.get('/wp-json/acf/v2/options/')
    ])
        .then(res => {
            setprimaryMenu(res[0].data.items);
            setinstagram(res[1].data.acf.instagram);
            setLoad(true);
        })
        .catch(err => {
            setError(err.message);
            setLoad(true)
        })
  }, []);

  function enable(scrollPosition) {
    bodyLock = document.getElementsByTagName('body')[0];
    scrollPosition = scrollPosition;
    bodyLock.style.overflow = 'hidden';
    bodyLock.style.position = 'fixed';
    bodyLock.style.top = `-${scrollPosition}px`;
    bodyLock.style.width = '100%';
    setTimeout(function(){
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', vh+"px");
    }, 500);
  };
  function disable(scrollPosition) {
    bodyLock = document.getElementsByTagName('body')[0];
    bodyLock.style.overflow = '';
    bodyLock.style.position = '';
    bodyLock.style.top = '';
    bodyLock.style.width = '';
    bodyLock.style.paddingRight = '';
    window.scrollTo(0, scrollPosition);
    setTimeout(function(){
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', vh+"px");
    }, 500);
  };

  useEffect(() => {
    bodyLock = document.getElementsByTagName('body')[0];
    scrollPosition = window.pageYOffset;
    menuHolder = document.getElementById('menu');
    pageMainTitle = document.getElementById('page-main-title');
    if(on == true){
      menuHolder.scrollTop = 0;
      document.getElementById('logo').classList.remove("title-view");
      if(scrollState == true){
        if (!isMobile) {
          disableBodyScroll(bodyLock, {reserveScrollBarGap: true});
        } else {
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', vh+"px");
          if(height >= 665){
            disableBodyScroll(bodyLock, {reserveScrollBarGap: true});
          } else {
            enable(scrollPosition);
          }
        }
      }
    } else {
      if(scrollState == true){
        if (!isMobile) {
          enableBodyScroll(bodyLock);
        } else {
          if(height >= 665){
            enableBodyScroll(bodyLock);
          } else {
            disable(scrollPosition);
          }
        }
        SetscrollState(false);
      }
    }
    if(pageMainTitle !== null && !document.getElementById("main-header").classList.contains('menu-active')){
      pageMainTitleRect = pageMainTitle.getBoundingClientRect();
      document.getElementById('logo').classList.add('hover-ready');
      if(((pageMainTitleRect.top + pageMainTitle.offsetHeight) + 90) < scrollY && !document.getElementById('logo').classList.contains('title-view')){
        document.getElementById('logo').classList.add("title-view");
      } else if(((pageMainTitleRect.top + pageMainTitle.offsetHeight) + 90) >= scrollY) {
        document.getElementById('logo').classList.remove("title-view");
      }
    } else if(pageMainTitle == null) {
      document.getElementById('logo').classList.remove('hover-ready');
    }
    if( scrollY > 90 && headerScrollReady !== true){
      headerScrollReady = true;
    }
    if( scrollY < titleStart ){
      if(!document.body.classList.contains('fixed-header') && !document.getElementsByClassName('headroom')[0].classList.contains('headroom--pinned')){
        bodyLock.classList.add('fixed-header');
      }
    }else{
      if(document.body.classList.contains('fixed-header') && headerScrollReady == true){
        bodyLock.classList.remove('fixed-header');
      }
    }

    if(on){
      document.getElementById("menu").style.opacity = "1";
    }
  });
 
    return (
      <header id="main-header" className={`main-header ${menuState}`} ref={headerRef}
      onMouseEnter={handleMove}
      onMouseMove={handleMove}
      onMouseLeave={handleLeave}
      >
        
        <Headroom>
          <Menu pointerEvents={pointerEvents} />
          <div className="header-content">
            <Container>
              <div className="inner-content">
                <Row className="align-items-center">
                  <Col xs>
                    <Link id="logo" to="/">
                      <span id="page-title" className="medium-title">Contact us</span>
                    </Link>
                  </Col>
                  <Col lg="auto" className="col-navigation d-none d-lg-block">
                    {primaryMenu &&
                      <nav className="main-nav">
                        <ul>
                          { primaryMenu.map(item => {
                            if (item.type == 'post_type') {
                              return (
                                <li key={item.ID}>
                                  <Link to={`/${item.slug}`} key={item.ID} >{ item.title }</Link>
                                </li>
                              )
                            } else {
                              return (
                                <li key={item.ID}>
                                  <Link to={ item.url } key={item.ID}>{ item.title }</Link>
                                </li>
                              )
                            }
                          })}
                        </ul>
                      </nav>
                    }
                  </Col>
                  { instagram != '' &&
                    <Col lg="auto" className="col-instagram d-none d-lg-block">
                      <a href={instagram} className="instagram-btn" target="_blank" aria-label="instagram"></a>
                    </Col>
                  }
                  <Col xs="auto" className="col-menu">
                    <button className="navigation-trigger" onClick={() => {toggle(!on); SetscrollState(true)}} aria-label="navigation trigger"></button>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </Headroom>
        
      </header>
    );
};

export default Header
