import React,{ useState, useEffect } from "react"

//import Layout from "../components/layout"
import SEO from "../components/seo"

import TopSection from "../components/static/topsection"
import WorkNews from "../components/static/worknews"
import { Helmet } from 'react-helmet'



export default function  NewsPage() {


        return(
            <>
                <SEO title="News with filter" />

                    <TopSection title="News Page with Filter Example" backgroundClass="background-yellow" filter={true}/>
                
                
                    <WorkNews data="" backgroundClass="background-yellow" targetPage="news" showLabel={false} filter={true} filterData=""/>
                          
                

            </>
        )
    
}