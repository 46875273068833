import React,{ useState, useEffect } from "react"
import useDelayRouteExit from "delay-react-route-exit";

//import Layout from "../components/layout"
import SEO from "../components/seo"

import TopSection from "../components/topsection"
import WorkNews from "../components/worknews"
import BigList from "../components/biglist"
import RelatedNews from "../components/relatednews"
import Shapes from "../components/shapes"
import { Helmet } from 'react-helmet'

import axios from 'axios'

export default function  Work(props) {

    const catSlug = props.match.params.slug;
    const [pageData, setPageData] = useState([]);
    const [catData, setCatData] = useState([]);
    const [pageColor, setPageColor] = useState([]);
    const [workData, setWorkData] = useState([]);
    //const [clientExclude, setClientExclude] = useState('');
    const [workCatData, setWorkCatData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');


    useEffect(() => {

        axios.all([
        axios.get('/wp-json/wp/v2/pages?slug=work'),
        axios.get('/wp-json/wp/v2/project-category/?slug='+catSlug),
        axios.get('/wp-json/wp/v2/project-category?hide_empty=true'),
        //axios.get('/wp-json/wp/v2/client'),
        ])
            .then(res => {
                setPageColor(res[0].data[0].acf.background_color);
                setPageData(res[0].data[0]);
                setCatData(res[1].data[0]);
                setWorkCatData(res[2].data);
                /*let clients_ids = [];
                for(var ind in res[3].data){
                    clients_ids.push(res[3].data[ind].id);
                    setClientExclude('&client_exclude='+clients_ids.join(','));
                }*/
                let catId = res[1].data[0].id;
                axios.get('/wp-json/wp/v2/projects?hide_password=true&offset=0&per_page=8&orderby=menu_order&order=asc&set_as_client_page=no&project-category='+catId).then(result => {
                    setWorkData(result);
                    setLoad(true);
                })

            })
            .catch(err => {
                setError(err.message);
                setLoad(true)
            })


    }, []);

    if (error) {
        return (
        <div style={{height: '100vh'}}></div>
        )

    }

    if(load){
        let seo_title = pageData.yoast_meta.yoast_wpseo_title;
        let seo_head= pageData.yoast_head;
        let acf = pageData.acf;

        let seo_data = pageData.yoast_meta;
        let featured_image = pageData.featured_image;

        function isPreRendering() {
            return navigator.userAgent === 'ReactSnap';
          };
      
          let preRender = isPreRendering();

        return(
            <>
                <SEO data={seo_data} image={featured_image} />
                <Helmet
                    bodyAttributes={{
                        class: `page-work ${acf.background_color} fixed-header`
                    }}
                />

                {  !preRender && 
                <>

                { acf.title &&
                    <TopSection title={acf.title}
                    description={acf.description}
                    backgroundClass={acf.background_color} filter={true} activeFilter={catSlug} filterAllPath={`work`}/>
                }

                { acf.page_content && acf.page_content.map((sectionData, index) => {
                    switch(sectionData.acf_fc_layout){
                        case 'work':{
                            return(
                            <WorkNews catId={catData.id} client_exclude={``} data={workData} filter={true} filterData={workCatData} activeFilter={catSlug} filterAllPath={`work`} backgroundClass={pageColor} targetPage="work" showLabel={true} key={index}/>
                            )
                            break;
                        }
                        case 'big_list':{
                            return(
                                <BigList data={sectionData} backgroundClass="background-saumon" key={index}/>
                            )
                            break;
                        }
                        case 'related_news':{
                            return(
                            <RelatedNews data={sectionData} backgroundClass="background-yellow"  key={index}/>
                            )
                            break;
                        }
                        case 'shapes':{
                            return(
                            <Shapes data={sectionData} rows="1" key={index}/>
                            )
                            break;
                        }
                    }


                    }
                )}

                </>
                }


            </>
        )
    }else {
        return (
          <div style={{height: '100vh'}}></div>
        )
      }
}
