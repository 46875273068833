import React,{ useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useLocation } from 'react-router-dom'
import Transition from "../components/transition"
import {decode} from 'html-entities';

function SEO({data, image, seoHead}) {
  const [seoData, setseoData] = useState('');

  //let description = data.yoast_wpseo_metadesc;

  let client_seo;
  let location = useLocation();
  let path_link = location.pathname;
  let addLastChar = '';
  let domain = 'https://madeostudio.com';

  if (path_link) {
   path_link = JSON.stringify(path_link);
   path_link = path_link.replace(/"/g, '');
   let lastChar = path_link.charAt(path_link.length-1);

   if (lastChar != '/') {
      path_link  += '/';
   }


    domain = domain+=path_link;


  }

  // if (!image && !location.pathname.includes('/client/')) {
  //     image = data.yoast_wpseo_facebook_image
  //     if (!image && data.yoast_wpseo_twitter_image != '') {
  //       image = data.yoast_wpseo_twitter_image
  //     }
  //     if(!image && data.yoast_wpseo_social_defaults && data.yoast_wpseo_social_defaults.og_default_image != ''){
  //       image = data.yoast_wpseo_social_defaults.og_default_image;
  //     }
  // }

  // useEffect(() => {
    //   if (location.pathname.includes('/client/')) {
    //     let client_seo = data.taxonomy;
    //     setseoData(client_seo);
    //   }
    // }, []);


// console.log(domain);
if(data.yoast_wpseo_title){
  return (
    <>
      <Transition />
      <Helmet>
      <title>{decode(data.yoast_wpseo_title, {level: 'html5'})}</title>  
        <meta name="twitter:creator" content="@MadeoStudio" />
        <meta name="author" content="Madeo Studio" />
        <link rel="canonical" href={domain} />
      </Helmet>
    </>
    );
}else{
  return (
    <>
      <Transition />
      <Helmet>
      {data.map((item, i) => {

              if(item.name == 'og:title' || item.property == 'og:title'){
                return(
                  <title>{decode(item.content, {level: 'html5'})}</title>
                )
              }   
              
              if(image && item.name == 'og:image'){
                <meta property="og:image" content={image} />
              }
          })
        }
        {data.map((item, i) => {
              if(item.name){

                if(image && item.name == 'og:image'){
                  <meta property="og:image" content={image} />
                }

                if(item.name == 'og:url'){
                  return (
                    <meta property="og:url" content={domain} />
                  ) 
                } else {
                  if(item.name == 'robots') {
                    
                    return (
                      <meta name={item.name} content="index, follow"/>
                    ) 

                  } else {
                    return (
                      <meta name={item.name} content={item.content }/>
                    ) 
                  }
                }
              }

              if(item.property){
                if(item.property == 'og:url'){
                  <meta property="og:url" content={domain} />
                } else {
                  return (
                    <meta property={item.property} content={item.content}/>
                  ) 
                }
              }
            
                    
          })

        }

        <meta property="og:url" content={domain} />
        <meta name="twitter:creator" content="@MadeoStudio" />
        <meta name="author" content="Madeo Studio" />
        <link rel="canonical" href={domain} />
      </Helmet>
    </>
    );
}
  
}


export default SEO