import React, {useState} from "react"
import md5 from 'md5'

export default function PasswordProtectedForm({pageData, setValidPassword}) {
    const [contentPassword, setContentPassword] = useState('');
    function handleSubmit(event) {
        event.preventDefault();
        let userPassword = md5(contentPassword);
        if(pageData.post_password == userPassword){
            setValidPassword();
        }else{
            console.log('wrong password');
        }
        return true;
    }

    function handlePwdChange(event){
        setContentPassword(event.target.value);
    }
    
    return (
        <section className="passwordprotectedform">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-6 col-md-8">
                        <h3 className="medium-title">Protect Content</h3>
                        <div class="form-holder">
                            <form className="protected-post-form" onSubmit={handleSubmit}>
                                <input name="post_password" id="pwbox-15988" placeholder="Password" type="password" size="20" onChange={handlePwdChange} className="form-control" required="" aria-required="true" />
                                <button type="submit" name="Submit" className="btn">Log in</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
      </section>
    )

}