import React, { useState } from "react"
import { Link } from 'react-router-dom'

import "./../styles/accordion.scss";
import AccordionItem from './accordionitem';


const items = [
  { index: 0, title: "Design", 
    desc: "Aenean sed elit suscipit, luctus sapien eget, cursus elit. Fusce vel tellus ligula. Praesent pretium vulputate urna a venenatis. Duis placerat felis lorem, at convallis lectus semper quis.",
    joblist: [
      { url: "/", jobTitle: 'Digital Designer', jobDesc: 'Full-Time'},
      { url: "/", jobTitle: 'UX Designer', jobDesc: 'Part-Time'},
    ]
  },
  { index: 1, title: "Strategy", 
    desc: "Cursus elit. Fusce vel tellus ligula. Praesent pretium vulputate urna a venenatis. Duis placerat felis lorem, at convallis lectus semper quis.",
    joblist: [
      { url: "/", jobTitle: 'Job Title 1', jobDesc: 'Full-Time'},
    ]
  },
  { index: 2, title: "Technology", 
    desc: "Luctus sapien eget, cursus elit. Aenean sed elit suscipit, Paesent pretium vulputate urna a venenatis. Duis placerat felis lorem, at convallis lectus semper quis.",
    joblist: [
      { url: "/", jobTitle: 'Job Title 1', jobDesc: 'Full-Time'},
      { url: "/", jobTitle: 'Technology Job 2', jobDesc: 'Part-Time'},
    ]
  },
  { index: 3, title: "Marketing", 
    desc: "Aenean sed elit suscipit, luctus sapien eget, cursus elit. Fusce vel tellus ligula. Praesent pretium vulputate urna a venenatis. Duis placerat felis lorem, at convallis lectus semper quis.",
    joblist: [
      { url: "/", jobTitle: 'Marketing Job 1', jobDesc: 'Full-Time'},
    ]
  },
  { index: 4, title: "Operations", 
    desc: "Aenean sed elit suscipit, luctus sapien eget, cursus elit. Fusce vel tellus ligula. Praesent pretium vulputate urna a venenatis. Duis placerat felis lorem, at convallis lectus semper quis.",
    joblist: [
      { url: "/", jobTitle: 'Operations Jobr', jobDesc: 'Full-Time'},
    ]
  },
  

]

export default function Accordion(data, backgroundClass) {

  backgroundClass = 'background-orange';
  //let items = data.data;
  const [activeIndex, setActiveIndex] = useState(-1);

  function handleMove(e) {
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
  }
  function handleLeave(e) {
    const cursor = document.querySelector('.cursor')
    cursor.classList.remove("large-cirlce")
  }
  return (
    <section className={`accordion-section ${backgroundClass ? backgroundClass : ""}`} id="accordion__">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="title small-title">Job Openings</h3>
            </div>
            <div className="col-12">
                {items.map((item, index) => (
                    <div className={`accordion-item ${activeIndex === index ? 'active': ''}` } key={index}>
                        <button className={`x-large-title accordion-toggler ${backgroundClass} ${activeIndex === index ? 'active': ''}` }
                        onClick={event => {
                                setActiveIndex(
                                activeIndex === index ? -1 : index
                                )
                                //console.log(activeIndex)
                              }}
                        onMouseEnter={handleMove}
                        onMouseMove={handleMove}
                        onMouseLeave={handleLeave}
                        >
                            {item.title}<sup>{item.joblist.length}</sup>
                            <span className="plus-icon" />
                        </button>
                        <AccordionItem isOpened={activeIndex === index}>
                            <p className="accordion-desc">
                              {item.desc}
                            </p>
                            <ul className="job-list">
                              {item.joblist.map((job, i) => (
                                <li key={i}>
                                  <Link to={job.url} 
                                        onMouseEnter={handleMove} 
                                        onMouseMove={handleMove} 
                                        onMouseLeave={handleLeave}
                                  >
                                    <div className="row">
                                      <h4 className="col-xl-4 col-md-5 col-sm-7 col-12 job-title medium-title">
                                          {job.jobTitle} 
                                      </h4>
                                      <p className="col-xl-8 col-md-7 col-sm-5 col-12">{job.jobDesc}</p>
                                    </div>
                                  </Link>
                                </li>
                            ))}
                            </ul>
                        </AccordionItem>
                    </div>

                ))
              }
            </div>
          </div>
        </div>
    </section>
  );
};

//export default Accordion
