import React, { useState, useEffect } from "react"
import SEO from "../components/seo"
import { Link } from 'react-router-dom'
import axios from 'axios';
import { Helmet } from 'react-helmet'
import Shapes from "../components/shapes"
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

export default function NotFoundPage() {

  function handleMove(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor');
      // console.log(e.target)
      if (e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'button' || e.target.tagName.toLowerCase() === 'input') {       cursor.classList.add("large-cirlce")
      }else{
        cursor.classList.remove("large-cirlce")
      }
    }
  }
  function handleLeave(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce")
    }
  }

  const [message, setmessage] = useState([]);
  const [title, setTitle] = useState([]);
  const [linkText, setlinkText] = useState([]);
  const [url, seturl] = useState([]);
  const [shapes, setShapes] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    axios.all([
      axios.get('/wp-json/acf/v2/options/')
    ])
        .then(res => {
            // console.log(res)
            setmessage(res[0].data.acf.message);
            setTitle(res[0].data.acf.page_title_404);
            setlinkText(res[0].data.acf.link_text);
            seturl(res[0].data.acf.link_url);
            setShapes(res[0].data.acf.shapes_two_rows);
            setLoad(true);
        })
        .catch(err => {
            setError(err.message);
            setLoad(true)
            //console.log(err.message);
        })


}, []);


  let message_text;
  let data = [];
  data['yoast_wpseo_title'] = '404: Not found';

  if (load) {
    message_text = message.replace('<p></p>', '');
    message_text = message_text.replace('<p>', '');
    message_text = message_text.replace('</p>', '');
    return(
     <>
     	<Helmet
          bodyAttributes={{
              class: `page-404 background-beige fixed-header`
          }}

      />
       <SEO data={data} image={'featured_image'} />
       <section className="section-404"  onMouseEnter={handleMove} onMouseMove={handleMove} onMouseLeave={handleLeave}>
         <div className="container">
           <div className="row justify-content-center align-items-center">
             <div className="col-12">
             { title &&
               <h1 className="x-large-title title">{title}</h1>
             }
                <p className="p-big desc" dangerouslySetInnerHTML={{ __html: message_text }} />
                <div className="button-wrap">
                  <Link className="button medium-btn blue-btn" to={url}>{linkText}</Link>
                </div>
           </div>
         </div>
       </div>
       </section>
       <Shapes data={shapes} />
     </>
    )
  } else {
    return (
      <div style={{height: '100vh'}}></div>
    )
  }

}
