import React from "react"

import "./../styles/singleintro.scss";

 
    
  
export default function SingleIntro({ clientName, title, introText, tags, backgroundClass }) {

  function handleMove(e) {
      const cursor = document.querySelector('.cursor');
      /*console.log(e.target)*/
      if (e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'button' || e.target.tagName.toLowerCase() === 'input') {       cursor.classList.add("large-cirlce")
      }else{
        cursor.classList.remove("large-cirlce")
      }
  }
  function handleLeave(e) {
    const cursor = document.querySelector('.cursor')
    cursor.classList.remove("large-cirlce")
  }
  

  return(
      <section className={`singleintro-section ${backgroundClass ? backgroundClass : ""} `}
               onMouseEnter={handleMove} 
               onMouseMove={handleMove} 
               onMouseLeave={handleLeave}
      >
        <div className="container">
          <div className="row">
            <div className={`col-xl-10 col-lg-11 col-md-11`}>
                {clientName ? 
                  <p className="p-big client-name">
                    {clientName}
                  </p>
                  :
                  ``
                }
                
                <h1 className="large-title title" dangerouslySetInnerHTML={{ __html: title }}  />
                {introText ? 
                  <h2 className="medium-title intro-text">
                    {introText}
                  </h2>
                  :
                  ``
                }
                {tags ? 
                    <ul className="tags-holder">
                      {tags.map((item, i) =>
                        <li key={i}><a className={`button small-btn`} href="#">{item.text}</a></li>
                      )}
                    </ul>
                  :
                  ''
                }
            </div>
          </div>
        </div>
      </section>
  )
  

}


