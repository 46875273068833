import React, { useState } from "react"
import { Link } from 'react-router-dom'

import "./../styles/biglist.scss";
const List = [
  { index: 0, title: "Brands", icon: "circle"  },
  { index: 1, title: "Organisations", icon: "hexa"  },
  { index: 2, title: "Institutions", icon: "rect"  },
  { index: 3, title: "XLarge Title", icon: "triangle"  },
]

function BigList({ data, backgroundClass }) {



  const [activeIndex, setActiveIndex] = useState(-1);

  function handleMove(e) {
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
  }
  function handleLeave(e) {
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce");
  }

 


  return(
      <section className={`biglist-section ${backgroundClass ? backgroundClass : ""}`}>
        <div className="container">
          <div className="row ">
            <div className="col-md-12 main-titles">
                <p className="p-big"> Our Work </p>
                <h3 className="big-title title">
                  Headline introducing 3 categories of clients we work with.
                </h3>

            </div>

            <div className={`col-12 list ${ activeIndex === -1 ? '': 'hovering'}`}>
                {List.map((item, index) =>(
                    <div
                      className={`item ${ activeIndex === index ? 'hover': ''}`}
                      key={index}
                      onMouseEnter={event => {setActiveIndex(activeIndex === index ? -1 : index)}}
                      onMouseLeave={event => {setActiveIndex(activeIndex === index ? -1 : index)}}
                    >
                      <Link to="#"
                            onMouseEnter={handleMove} 
                            onMouseMove={handleMove}
                            onMouseLeave={handleLeave}
                      >
                          <div className="icon-wrap">
                            <span className={`icon ${item.icon}`}></span>
                          </div>
                          <h4 className="x-large-title item-title">
                            {item.title}
                          </h4>
                      </Link>
                    </div>
                )
                )}


            </div>
          </div>
        </div>
      </section>
  )


}

export default BigList
