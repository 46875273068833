import React from "react"

import "./../styles/numberslist.scss";

function NumbersList({ data, backgroundClass }) {
  return(
      <section className={`numberslist-section ${backgroundClass ? backgroundClass : ""}`}>
        <div className="container">
          <div className="row list-row">
            <div className="col-md-4 col-sm-5 item">
              <h3 className="x-large-title number"> 
                  3,858 
              </h3>
              <p className="p-big">
                  Lorem ipsum dolor sit amet, consectetur adipisicing.
              </p>
            </div>

            <div className="col-md-4 col-sm-5 item">
              <h3 className="x-large-title number"> 
                  1/2
              </h3>
              <p className="p-big">
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.
              </p>
            </div>

            <div className="col-md-4 col-sm-5 item">
              <h3 className="x-large-title number"> 
                  129 
              </h3>
              <p className="p-big">
                  Duis aute irure dolor in reprehenderit in voluptate velit.
              </p>
            </div>

            <div className="col-md-4 col-sm-5 item">
              <h3 className="x-large-title number"> 
                  1.1 
              </h3>
              <p className="p-big">
                  Lorem ipsum dolor sit amet, consectetur adipisicing.
              </p>
            </div>

            <div className="col-md-4 col-sm-5 item">
              <h3 className="x-large-title number"> 
                  14 
              </h3>
              <p className="p-big">
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.
              </p>
            </div>

            <div className="col-md-4 col-sm-5 item">
              <h3 className="x-large-title number"> 
                  52K 
              </h3>
              <p className="p-big">
                  Duis aute irure dolor in reprehenderit in voluptate velit.
              </p>
            </div>

          </div>
        </div>
      </section>
  )
  

}


/*WorkNews.defaultProps = {
  data: [],
}

WorkNews.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  backgroundClass: PropTypes.string

}*/


export default NumbersList
