import React from "react"
import { Link } from 'react-router-dom'
import "./styles/contactinfo.scss";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

function ContactInfo({ data, backgroundClass }) {

  // let address = data.contact_address;
  // let spacer = `<br />`;
  // let email = `<a href={mailto:${data.contact_email}}>${data.contact_email}</a>`;
  // let contact_details = address+spacer+email;
  let contact_info = data.contact_information;

  function handleMove(e) {
    if (!isMobile){
      const cursor = document.querySelector('.cursor');
      //console.log(e.target);
      if (e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'button' || e.target.tagName.toLowerCase() === 'input') {       cursor.classList.add("large-cirlce")
      }else{
        cursor.classList.remove("large-cirlce")
      }
    }
  }
  function handleLeave(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce")
    }
  }
  return(
      <section className={`contactinfo-section ${backgroundClass ? backgroundClass : ""}`}
               onMouseEnter={handleMove}
               onMouseMove={handleMove}
               onMouseLeave={handleLeave}
      >
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-lg-6 col-md-8 col-12">
                <h3 className="big-title" dangerouslySetInnerHTML={{ __html: contact_info }}>
                </h3>
                <h4 className="medium-title">
                    { data.contact_medium_title &&
                      data.contact_medium_title
                    } <br />
                  { data.contact_link_text && data.contact_url &&
                    <Link to={data.contact_url}> {data.contact_link_text} </Link>
                  }
                </h4>
            </div>
          </div>
        </div>
      </section>
  )


}

export default ContactInfo
