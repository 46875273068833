import React from "react"
import { Link } from 'react-router-dom'
import background from "./../images/work1.png"
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import 'intersection-observer'
import ProgressiveImage from 'react-progressive-graceful-image';

import "./styles/sidedcta.scss";

function SidedCTA({ data, backgroundClass }) {

  if (data && backgroundClass) {

  function handleMove(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor');
      cursor.classList.add("large-cirlce")
    }
  }
  function handleLeave(e) {
    if(!isMobile){
      const cursor = document.querySelector('.cursor')
      cursor.classList.remove("large-cirlce");
    }
  }

    return(
        <section className={`sidedcta-section ${backgroundClass ? backgroundClass : ""}`}>
          <div className="container">
            <div className="row">

              {data.map((item, index) =>
                <div key={index} className="col-md-6 item">
                {item.external_link &&
                  <a href={item.button_url}
                        onMouseEnter={handleMove}
                        onMouseMove={handleMove}
                        target = '_blank'
                        rel='noopener'
                        onMouseLeave={handleLeave}
                      >
                      <div className="image-wrapper">
                        <div className={`${item.lazy_load_background ? item.lazy_load_background : ""} image-item`}>
                            <div className="lazy-holder">
                              <ProgressiveImage
                                  className="main-image"
                                  delay={500}
                                  src={item.image.sizes.large}
                                  srcSetData={{
                                    srcSet: `${item.image.sizes.large} 320w, ${item.image.sizes.large} 700w, ${item.image.sizes.large} 2000w`,
                                    sizes: '(max-width: 2000px) 100vw, 2000px'
                                  }}
                                  placeholder={item.featured_image_thumbnail}
                              >
                                {(src, loading, srcSetData, placeholder) => (
                                      <img style={{ opacity: loading ? 0 : 1, transition: "0.3s ease" }}
                                        src={src}
                                        srcSet={srcSetData.srcSet}
                                        sizes={srcSetData.sizes}
                                        alt="an image"
                                      />
                                  )}


                              </ProgressiveImage>
                            </div>
                        </div>

                        <div className="button-wrapper">
                            <button className="button big-btn white-btn">{item.button_text}</button>
                        </div>
                      </div>

                    </a>
                }
                {!item.external_link &&
                  <Link to={item.button_url}
                        onMouseEnter={handleMove}
                        onMouseMove={handleMove}
                        onMouseLeave={handleLeave}
                      >
                      <div className="image-wrapper">
                        <div className={`${item.lazy_load_background ? item.lazy_load_background : ""} image-item`}>
                            <div className="lazy-holder">
                              <ProgressiveImage
                                  className="main-image"
                                  delay={500}
                                  src={item.image.sizes.large}
                                  srcSetData={{
                                    srcSet: `${item.image.sizes.large} 320w, ${item.image.sizes.large} 700w, ${item.image.sizes.large} 2000w`,
                                    sizes: '(max-width: 2000px) 100vw, 2000px'
                                  }}
                                  placeholder={item.featured_image_thumbnail}
                              >
                                {(src, loading, srcSetData, placeholder) => (
                                      <img style={{ opacity: loading ? 0 : 1, transition: "0.3s ease" }}
                                        src={src}
                                        srcSet={srcSetData.srcSet}
                                        sizes={srcSetData.sizes}
                                        alt="an image"
                                      />
                                  )}


                              </ProgressiveImage>
                            </div>
                        </div>

                        <div className="button-wrapper">
                            <button className="button big-btn white-btn">{item.button_text}</button>
                        </div>
                      </div>

                    </Link>
                }
                </div>
              )}

            </div>
          </div>
        </section>
    )
  }
}

export default SidedCTA
