import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import axios from 'axios';

export default class PortfolioPage extends Component {
  state = {
    portfolio: {},
    isLoaded: false
  }

  componentDidMount() {
    axios.get(`wp-json/wp/v2/portfolio?slug=${this.props.match.params.slug}`)
    .then(res => this.setState({
      portfolio: res.data[0],
      isLoaded: true
    }))
    .catch(err => console.log(err));


  }
  render() {
    const { portfolio, imgUrl, isLoaded } = this.state;
    if (isLoaded) {
      return (
        <Fragment>
          <Link to='/work'>Go Back</Link>
          <hr/>
          <h1>{portfolio.title.rendered}</h1>
          <h4>Challenge title: { portfolio.acf.challenge_title }</h4>
          <p dangerouslySetInnerHTML={{ __html: portfolio.acf.challenge_description }}></p>
        </Fragment>
      )
    }
    return <h1>Loading...</h1>;
  }
}
