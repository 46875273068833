import React,{ useState, useEffect } from "react"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
//import Layout from "../components/layout"
import SEO from "../components/seo"
import TopSection from "../components/topsection"
import WorkNews from "../components/worknews"
import BigList from "../components/biglist"
import RelatedNews from "../components/relatednews"
import Shapes from "../components/shapes"
import { Helmet } from 'react-helmet'

import axios from 'axios'
import md5 from 'md5'
import NoMatchPage from '../pages/404'

export default function  Work(props) {
    //console.log(props);
    const path = props.match.path;
    let api_url = '/wp-json/wp/v2/pages/';
    const [axiosArr, setAxiosArr] = useState([]);
    const [newToken, setNewToken] = useState(false);

    const [pageData, setPageData] = useState([]);
    const [workData, setWorkData] = useState([]);
    const [clientExclude, setClientExclude] = useState('');
    const [workCatData, setWorkCatData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');
    let clients_ids = [];


  useEffect(() => {
    if(path.indexOf('_preview') > -1){

        let password = prompt('Password');
        const pageID = path.substr(path.lastIndexOf('/') + 1);
        api_url += pageID+'/revisions/?per_page=1&offset=0';
        if(password){
            axios.all([axios.post('/wp-json/madeo/v1/token',{sec_key:md5(password)})])
            .then(res => {
                let token_res = res[0].data;
                if(token_res.token != undefined){
                setAxiosArr([
                    axios.get(api_url,{headers: {authorization: 'Bearer '+token_res.token}}),
                    axios.get('/wp-json/wp/v2/client'),
                    axios.get('/wp-json/wp/v2/project-category?hide_empty=true')
                ]);
                setNewToken(true);
                }else{
                setNewToken(false);
                setLoad(true);
                setError('404');
                }
                
            })
            .catch(err => {
                setError(err.message);
                setLoad(false)
            })
        }else{
            setNewToken(false);
            setLoad(true);
            setError('404');
        }



    }else{
        api_url += '?slug='+path.substring(1);
      setAxiosArr([
        axios.get(api_url),
        axios.get('/wp-json/wp/v2/client'),
        axios.get('/wp-json/wp/v2/project-category?hide_empty=true')
      ]);
      setNewToken(true);
    }

    }, [path]);

    function isPreRendering() {
        return navigator.userAgent === 'ReactSnap';
    };
    
    let preRender = isPreRendering();
    
    useEffect(() => {
        if(newToken){
            axios.all(axiosArr)
            .then(res => {
                setPageData(res[0].data[0]);
                setWorkCatData(res[2].data);
                for(var ind in res[1].data){
                    clients_ids.push(res[1].data[ind].id);
                    setClientExclude('&client_exclude='+clients_ids.join(','));
                }
                let data_url = '/wp-json/wp/v2/projects?hide_password=true&offset=0&per_page=8&orderby=menu_order&order=asc&client_exclude='+clients_ids.join(',');
                if(preRender){
                    data_url = '/wp-json/wp/v2/projects?hide_password=true&offset=0&per_page=100&orderby=menu_order&order=asc'
                }
                axios.get(data_url).then(result => {
                    setWorkData(result);
                    setLoad(true);
                })
            })
            .catch(err => {
                setError(err.message);
                setLoad(true)
            })
        }

    }, [newToken, axiosArr]);

    if (error) {
        return (
            <Route component={NoMatchPage} />
        )

    }

    if(load){
      let seo_data = pageData.yoast_meta;
      let featured_image = pageData.featured_image;

        let seo_title = pageData.yoast_meta.yoast_wpseo_title;
        let seo_head= pageData.yoast_head;
        let acf = pageData.acf;

        return(
            <>
                <SEO data={seo_data} image={featured_image} />
                <Helmet
                    bodyAttributes={{
                        class: `page-work ${acf.background_color} fixed-header`
                    }}
                />

                
                        { acf.title && !preRender &&
                            <TopSection title={acf.title}
                            description={acf.description}
                            backgroundClass={acf.background_color} filter={true} filterAllPath={`work`}/>
                        }
                 

                        { acf.page_content && acf.page_content.map((sectionData, index) => {
                            switch(sectionData.acf_fc_layout){
                                case 'work':{
                                    return(

                                    <WorkNews data={workData} client_exclude={clientExclude} backgroundClass={acf.background_color} targetPage="work" filter={true} filterData={workCatData} filterAllPath={`work/`} showLabel={false} key={index} />
                                    )
                                    break;
                                }
                                case 'big_list':{
                                    if(!preRender){
                                        return(
                                            <BigList data={sectionData} backgroundClass="background-saumon" key={index}/>
                                        )
                                    }
                                    break;
                                }
                                case 'related_news':{
                                    if(!preRender){
                                        return(
                                        <RelatedNews data={sectionData} backgroundClass="background-yellow"  key={index}/>
                                        )
                                    }
                                    break;
                                }
                                case 'shapes':{
                                    if(!preRender){
                                        return(
                                        <Shapes data={sectionData} rows="1" key={index}/>
                                        )
                                    }
                                    break;
                                }
                            }


                            }
                        )}
                    

            </>
        )
    }else {
        return (
          <div style={{height: '100vh'}}></div>
        )
      }
}
