import React from "react"

import "./styles/numberslist.scss";

function NumbersList({ data, backgroundClass }) {
  if(data.numbers != null){
  return(
      <section className={`numberslist-section ${backgroundClass}`}>
        <div className="container">
          <div className="row list-row">
          {data.numbers.map((item, index) => {
                if(item.description && item.title){
                  return(
                  <div className="col-lg-4 col-md-6 col-sm-5 item" key={index}>
                    <h3 className="x-large-title number"> 
                        {item.title}
                    </h3>
                    <p className="p-big" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                  </div>
                  )    
                }
              
              }
          )}

          </div>
        </div>
      </section>
  )
  }else{
    return ("")
  }


}


/*WorkNews.defaultProps = {
  data: [],
}

WorkNews.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  backgroundClass: PropTypes.string

}*/


export default NumbersList
