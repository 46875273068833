import React from "react"

import "./../styles/topsection.scss";





function TopSection({ title, backgroundClass, description, filter }) {

  const isDescription = description;
  //const isFilter = filter;
  let descriptionContent, gridCol;
  if(isDescription){
    descriptionContent = <h3 className="medium-title" dangerouslySetInnerHTML={{ __html: description }}  />;
    gridCol = "col-md-9";
  } else {
    gridCol = "col-md-11";
  }
  
  
  function handleMove(e) {
      const cursor = document.querySelector('.cursor');
      /*console.log(e.target)*/
      if (e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'button' || e.target.tagName.toLowerCase() === 'input') {       cursor.classList.add("large-cirlce")
      }else{
        cursor.classList.remove("large-cirlce")
      }
  }
  function handleLeave(e) {
    const cursor = document.querySelector('.cursor')
    cursor.classList.remove("large-cirlce")
  }

  return(
      <section className={`top-section ${backgroundClass ? backgroundClass : ""} ${filter? "with-filter" : "" }`} 
               onMouseEnter={handleMove} 
               onMouseMove={handleMove} 
               onMouseLeave={handleLeave}
      >
        <div className="container">
          <div className="row">
            <div className={`${gridCol}`}>
                <h1 className="x-large-title" dangerouslySetInnerHTML={{ __html: title }}  />
                {descriptionContent}
            </div>
          </div>
        </div>
      </section>
  )
  

}


export default TopSection
